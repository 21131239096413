import * as yup from "yup";
const DOCUMENT_TYPES = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
  "application/msword", // DOC
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
  "application/vnd.ms-excel", // XLS
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
  "application/vnd.ms-powerpoint", // PPT
  "application/pdf",
  "image/jpeg",
  "image/png",
];

export const clientDocumentAddValidations = yup.object({
  file: yup
    .mixed()
    .test("is-correct-file", "VALIDATION_FIELD_FILE_BIG", checkIfFilesAreTooBig)
    .test(
      "is-big-file",
      "VALIDATION_FIELD_FILE_WRONG_TYPE",
      checkIfFilesAreCorrectType
    ),
  fileName: yup
    .string()
    .required("A name is required for the document")
    .max(100, "File name must not exceed 100 characters"),
  comment: yup.string().max(3000, "Comment must not exceed 3000 characters"),
});

function checkIfFilesAreTooBig(file?: File): boolean {
  if (file == null) return true;
  let valid = true;
  const size = file.size / 1024 / 1024;
  if (size > 10) {
    valid = false;
  }
  return valid;
}

function checkIfFilesAreCorrectType(file?: File): boolean {
  if (file == null) return true;
  let valid = true;
  if (!DOCUMENT_TYPES.includes(file.type)) {
    valid = false;
  }
  return valid;
}
