import * as React from "react";

/* --- Project Imports --- */
import StyledMenu from "./StyledMenu";

/* --- MUI Imports --- */
import { Button, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "react-router-dom";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

interface InvoiceActionMenuProps {
  invoiceId: number;
  onGeneratePDF: Function;
  onCollectPayment: Function;
  showDetails: boolean;
  showGenerate: boolean;
  showCollectPayment: boolean;
  onViewPaymentHistory: Function;
}

export default function InvoiceActionMenu({
  invoiceId,
  showDetails,
  showGenerate,
  showCollectPayment,
  onGeneratePDF,
  onCollectPayment,
  onViewPaymentHistory,
}: InvoiceActionMenuProps) {
  const [newAnchorEl, setNewAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [newMenuOpen, setNewMenuOpen] = React.useState(false);
  const handleNewMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNewAnchorEl(event.currentTarget);
    setNewMenuOpen(true);
  };

  /* --- Handlers --- */
  const handleNewMenuClose = () => {
    setNewAnchorEl(null);
    setNewMenuOpen(false);
  };

  const handleGeneratePDF = () => {
    handleNewMenuClose();
    onGeneratePDF();
  };

  const handleCollectPayment = () => {
    handleNewMenuClose();
    onCollectPayment();
  };

  const handleViewPaymentHistory = () => {
    handleNewMenuClose();
    onViewPaymentHistory();
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleNewMenuOpen}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<AddCircleIcon />}
      >
        Actions
      </Button>
      <StyledMenu
        anchorEl={newAnchorEl}
        open={newMenuOpen}
        onClose={handleNewMenuClose}
      >
        {showDetails && (
          <MenuItem disableRipple>
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={`/billing/invoice/${invoiceId}`}
            >
              <ZoomInIcon />
              View invoice details
            </Link>
          </MenuItem>
        )}

        {showGenerate && (
          <MenuItem
            onClick={() => {
              handleGeneratePDF();
            }}
            disableRipple
          >
            <InsertDriveFileIcon />
            Generate PDF
          </MenuItem>
        )}

        {showCollectPayment && (
          <MenuItem
            onClick={() => {
              setNewAnchorEl(null);
              setNewMenuOpen(false);
              handleCollectPayment();
            }}
            disableRipple
          >
            <ShoppingCartIcon />
            Collect Payment
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setNewAnchorEl(null);
            setNewMenuOpen(false);
            handleViewPaymentHistory();
          }}
          disableRipple
        >
          <HourglassTopIcon />
          View Payment History
        </MenuItem>
      </StyledMenu>
    </>
  );
}
