import * as React from "react";
import Header from "./Header";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { useQuery } from "react-query";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { selectUserEmail } from "../state/slices/auth";
import {
  PortalAccessListType,
  getAccessByContactId,
  portalAccessKeys,
} from "../apis/internalDb/access/clientPortalAccess";
import { defaultNullUndefined } from "../shared/globalFunctions";
import DataRetrievalError from "../shared/ReusableComponents/DataRetrievalError";
import { ClientInterface, setClientList } from "../state/slices/access";
import SubmissionError from "../shared/ReusableComponents/SubmissionError";

export default function MainShell() {
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(selectUserEmail);
  /* --- Handlers --- */
  const {
    isLoading,
    isError,
    data: portalAccessData,
  } = useQuery<PortalAccessListType[], Error>(
    portalAccessKeys.detail(userEmail),
    () => getAccessByContactId(userEmail)
  );

  React.useEffect(() => {
    let clientList: ClientInterface[] = defaultNullUndefined(
      portalAccessData?.map((pad) => {
        return {
          clientId: pad.client_id,
          mapClientId: pad.map_org_to_client_id,
          orgName: pad.org_name,
          orgId: pad.org_profile_id,
          firstName: pad.first_name,
          lastName: pad.last_name,
          stripeAccountId: pad.stripe_account_id,
          permissions: {
            can_access_portal: pad.permissions.can_access_portal,
            can_manage_documents: pad.permissions.can_manage_documents,
            can_view_clinical_notes: pad.permissions.can_view_clinical_notes,
            can_view_billing: pad.permissions.can_view_billing,
          },
        } as ClientInterface;
      }),
      []
    );
    dispatch(setClientList(clientList));
  }, [portalAccessData, dispatch]);

  if (isLoading) return <></>;
  else if (isError) {
    return <DataRetrievalError />;
  } else if (userEmail === "") {
    return <SubmissionError />;
  }
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Header />
      </Box>
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: { xs: 1, sm: 1 },
        }}
      >
        <Box sx={{ width: "100%", padding: "0.5rem" }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
