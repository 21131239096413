import React from "react";
import { useQuery } from "react-query";

// --- MUI Imports ---
import {
  Box,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

// --- Project  Imports ---
import theme from "../../themes";
import {
  EventClientDetailsType,
  EventDetailType,
  eventKey,
  getEventNoteId,
} from "../../apis/internalDb/calendar/event";

import dayjs from "dayjs";
import EventSummaryHeader from "../eventUtils/EventSummaryHeader";
import EventSummaryClientServices from "../eventUtils/EventSummaryClientServices";
import DataRetrievalError from "../../shared/ReusableComponents/DataRetrievalError";

import EventDisplayExistingNotes from "./EventDisplayExistingNotes";
import EventSummaryStaffList from "./EventSummaryStaffList";

interface NoteDialogProps {
  clientId: number;
  eventId: number;
  onCloseNote: Function;
}
export default function NoteDialog({
  clientId,
  eventId,
  onCloseNote,
}: NoteDialogProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);

  /* --- Handlers --- */
  const toggleExpandCollapse = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleCloseNote = () => {
    onCloseNote();
  };

  const width = window.innerWidth;
  const halfWidth = (width * 0.4).toString() + "px";
  const fullWidth = (width * 0.9).toString() + "px";

  /* --- React Query --- */
  // get note event data
  const {
    isLoading: isEventLoading,
    isError: isEventError,
    error: eventError,
    data: eventResponse,
  } = useQuery<EventDetailType, Error>(eventKey.event(clientId, eventId), () =>
    getEventNoteId(clientId, eventId)
  );
  if (isEventLoading || eventResponse === undefined) {
    return <LinearProgress />;
  } else if (isEventError) {
    return (
      <>
        <br />
        <DataRetrievalError additionalMessage={eventError.message} />
      </>
    );
  } else {
    const billingProvider = eventResponse.event_staff.filter(
      (es) => es.is_billing_provider
    );
    const billingProviderId =
      billingProvider.length > 0 ? billingProvider[0].user_id : null;
    const startDatetime = dayjs(eventResponse.start_datetime);
    const clinicalNoteId =
      eventResponse?.event_service === null ||
      eventResponse?.event_service === undefined ||
      eventResponse.event_service.length === 0
        ? null
        : eventResponse.event_service[0].clinical_note_id;
    const duration = eventResponse.duration_mins;
    const endDatetime = startDatetime.add(duration, "minutes");
    const client: EventClientDetailsType = eventResponse.event_client[0];
    const staffPickerObject = eventResponse.event_staff.map((staff) => {
      let userDetails = staff.app_user.user_details[0];
      return {
        user_id: staff.user_id,
        fullname: `${userDetails.first_name} ${userDetails.last_name}`,
      };
    });
    return (
      <Drawer
        anchor="right"
        open={true}
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
      >
        <Box
          margin={2}
          marginTop={1}
          width={isFullScreen ? fullWidth : halfWidth}
        >
          <Grid container direction="row" paddingLeft={2}>
            <Grid container direction="column" item xs={12} sm={6}>
              <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h3" paddingTop={1}>
                  {`${eventResponse.reference_event_event_typeToreference.label} - ${client.map_org_to_client.client.first_name} ${client.map_org_to_client.client.last_name}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              padding={2}
              paddingBottom={0}
              container
              direction="column"
              item
              xs={12}
              sm={6}
            >
              <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Stack
                  direction="row"
                  spacing={0.5}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Tooltip
                    title={
                      isFullScreen ? "Collapse screen" : "Expand full screen"
                    }
                  >
                    <div>
                      <IconButton
                        onClick={toggleExpandCollapse}
                        color="primary"
                      >
                        {isFullScreen ? (
                          <CloseFullscreenIcon />
                        ) : (
                          <OpenInFullIcon />
                        )}
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title={"Close"}>
                    <IconButton onClick={handleCloseNote}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Stack direction="column" spacing={2} marginBottom={2}>
            <Box paddingLeft={2}>
              <EventSummaryHeader
                event={eventResponse}
                eventEndDateTime={endDatetime}
                locationName={eventResponse.service_location?.name}
                roomName={eventResponse?.service_location_room?.name}
              />
            </Box>
            <Box paddingLeft={2}>
              <EventSummaryStaffList
                staff={eventResponse.event_staff}
                billingProviderId={billingProviderId}
              />
            </Box>
            <Divider />
            <Box paddingLeft={2}>
              <EventSummaryClientServices
                staff={staffPickerObject}
                clients={[client]}
                services={eventResponse.event_service}
                hideClientName
                eventType={eventResponse.event_type}
              />
            </Box>
            <Divider />
            <DialogContent sx={{ margin: 0, padding: 0 }}>
              {clinicalNoteId !== null ? (
                <EventDisplayExistingNotes
                  clientId={clientId}
                  existingNoteIds={[clinicalNoteId]}
                  selectedClientId={clientId}
                />
              ) : (
                <Typography>No Notes Published</Typography>
              )}
            </DialogContent>
          </Stack>
        </Box>
      </Drawer>
    );
  }
}
