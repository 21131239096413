/* eslint-disable no-empty-pattern */
import * as React from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";

/* --- MUI Imports --- */
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { LinearProgress, Typography } from "@mui/material";
import { USDollar } from "../../../shared/resources/referenceConstants";
import {
  PaymentLineItem,
  PaymentsDataset,
  billingKeys,
  getPayments,
} from "../../../apis/internalDb/billing/billing";
import {
  defaultNullUndefined,
  parseFloatSafe,
} from "../../../shared/globalFunctions";

/* --- Project Imports --- */
import EmptyDataTableMessage from "../../../shared/ReusableComponents/EmptyDataTableMessage";
import DataRetrievalError from "../../../shared/ReusableComponents/DataRetrievalError";
import ShowPaymentDetailsDialog from "./ShowPaymentDetailsDialog";
export interface InvoiceListType {
  service_id: number;
  service_date: string;
  service: string;
  units: number;
  location: string;
  provider: string;
  amount_due: number;
  rate: number;
  writeOff: number;
  modifier1: string | null;
  modifier2: string | null;
  modifier3: string | null;
  modifier4: string | null;
}

interface PaymentDataTableProps {
  clientIds: number[];
  typeIds: number[];
  isHideClientName?: boolean;
  selectedClientId: number;
}

export default function PaymentDataTable({
  clientIds,
  typeIds,
  isHideClientName,
  selectedClientId,
}: PaymentDataTableProps) {
  const [paymentId, setPaymentId] = React.useState<number>(0);
  const [showDetails, setShowDetails] = React.useState<boolean>(false);

  const PAGE_SIZE = [15, 50, 100];
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });
  const [sortField, setSortField] = React.useState<string | undefined>(
    undefined
  );
  const [sortDirection, setSortDirection] = React.useState<string | undefined>(
    undefined
  );

  /* --- Handlers --- */
  const handleCloseView = () => {
    setShowDetails(false);
    setPaymentId(0);
  };

  /* --- React Query --- */
  const {
    isLoading: isTransactionDataLoading,
    isError: isTransactionDataError,
    data: transactionDataSet,
  } = useQuery<PaymentsDataset, Error>(
    billingKeys.transactionTable(
      { sortField, sortDirection, ...paginationModel },
      clientIds,
      typeIds
    ),
    () =>
      getPayments(
        { sortField, sortDirection, ...paginationModel },
        clientIds,
        typeIds,
        selectedClientId
      )
  );

  const [rowCountState, setRowCountState] = React.useState<number>(
    defaultNullUndefined(transactionDataSet?.totalCount, 0)
  );

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      let temp = defaultNullUndefined(sortModel[0], {
        field: undefined,
        sort: undefined,
      });
      setSortField(temp.field);
      setSortDirection(temp.sort);
      let tempPageSize = paginationModel.pageSize;
      setPaginationModel({
        page: 0,
        pageSize: tempPageSize,
      });
    },
    [paginationModel.pageSize]
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      transactionDataSet?.totalCount !== undefined
        ? transactionDataSet?.totalCount
        : prevRowCountState
    );
  }, [transactionDataSet?.totalCount, setRowCountState]);

  const displayData: PaymentLineItem[] = defaultNullUndefined(
    transactionDataSet?.data,
    []
  );

  const columns: GridColDef[] = [
    {
      field: "payment_date",
      headerName: "Payment Date",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return dayjs(params.value).format("M-D-YYYY");
      },
    },
    {
      field: "client_fullname",
      headerName: "Client Name",
      minWidth: 180,
      flex: 2,
      renderCell: (params) => {
        return (
          <Typography
            variant="h5"
            sx={{ fontWeight: 500 }}
            color="secondary.main"
          >
            {params.value}
          </Typography>
        );
      },
    },

    {
      field: "amount_paid",
      headerName: "Amount Paid",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>{USDollar.format(parseFloatSafe(params.value, 0))}</>
      ),
    },
    {
      field: "amount_applied",
      headerName: "Amount Applied",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return USDollar.format(parseFloatSafe(params.row.amount_applied, 0));
      },
    },
    {
      field: "amount_remaining_to_apply",
      headerName: "Amount Remaining To Apply",
      minWidth: 150,
      flex: 3,
      valueGetter: (params) => {
        return USDollar.format(
          parseFloatSafe(params.row.amount_paid, 0) -
            parseFloatSafe(params.row.amount_applied, 0) -
            parseFloatSafe(params.row.amount_refunded, 0)
        );
      },
    },
    {
      field: "amount_refunded",
      headerName: "Amount Refunded",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <>{USDollar.format(parseFloatSafe(params.value, 0))}</>
      ),
    },
    {
      field: "payment_method_label",
      headerName: "Payment Type",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params.row.payment_method_label;
      },
    },
    {
      field: "note",
      headerName: "Note",
      minWidth: 180,
      flex: 2,
    },
  ];

  const noRowsOverlayComponent = () => {
    return <EmptyDataTableMessage message="No Payments" />;
  };

  if (isTransactionDataLoading) {
    return <LinearProgress />;
  } else if (isTransactionDataError || transactionDataSet === undefined) {
    return <DataRetrievalError />;
  } else {
    return (
      <>
        {isTransactionDataError && <DataRetrievalError />}
        <DataGrid
          autoHeight
          slots={{ noRowsOverlay: noRowsOverlayComponent }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer":
              {
                pl: 1,
              },
            fontSize: 14,
          }}
          rows={displayData}
          columns={columns}
          pageSizeOptions={PAGE_SIZE}
          getRowId={(row) => row.payment_id}
          loading={isTransactionDataLoading}
          paginationModel={paginationModel}
          isRowSelectable={() => false}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          rowCount={rowCountState}
          columnVisibilityModel={{
            client_fullname: !defaultNullUndefined(isHideClientName, false),
          }}
        />

        {showDetails && (
          <ShowPaymentDetailsDialog
            paymentId={paymentId}
            onClose={handleCloseView}
            invoiceId={null}
            selectedClientId={selectedClientId}
          />
        )}
      </>
    );
  }
}
