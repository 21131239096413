import React from "react";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import ShowPaymentDetailsParent from "./ShowPaymentDetailsParent";
import ModalHeader from "../../../shared/ReusableComponents/ModalHeader";

interface ShowPaymentDetailsDialogProps {
  paymentId: number | null;
  invoiceId: number | null;
  onClose: Function;
  selectedClientId: number;
}

export default function ShowPaymentDetailsDialog({
  paymentId,
  invoiceId,
  onClose,
  selectedClientId,
}: ShowPaymentDetailsDialogProps) {
  const handleCancel = () => {
    onClose();
  };

  const renderModalHeader = () => {
    if (paymentId === null && invoiceId !== null) {
      return (
        <ModalHeader
          headerText={`Invoice ${invoiceId}`}
          onClose={handleCancel}
        />
      );
    }
    if (invoiceId === null && paymentId !== null) {
      return (
        <ModalHeader
          headerText={`Payment ${paymentId}`}
          onClose={handleCancel}
        />
      );
    }
    return null; // Explicitly return null if no condition is met
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxHeight: "60%",
            minWidth: "50%",
            maxWidth: "55%",
          },
        },
      }}
    >
      {renderModalHeader()}
      <DialogContent sx={{ paddingTop: 1, height: "100%" }}>
        <ShowPaymentDetailsParent
          invoiceId={invoiceId}
          paymentId={paymentId}
          selectedClientId={selectedClientId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          autoFocus
          onClick={() => {
            handleCancel();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
