/* eslint-disable no-empty-pattern */
import React from "react";
import { Link } from "react-router-dom";

/* --- MUI Imports --- */
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

/* --- Project Imports --- */
import PageTitle from "../../shared/ReusableComponents/PageTitle";
import { useAppSelector } from "../../state/hooks";
import { selectSelectedClient } from "../../state/slices/access";

interface ManageParentProps {}

export default function ManageParent({}: ManageParentProps) {
  const client = useAppSelector(selectSelectedClient);

  if (client === undefined || client === null) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <PageTitle title="Manage My Info" />

        <Grid spacing={1} container>
          <Grid item container xs={1}></Grid>
          <Grid item container xs={8}>
            <Grid spacing={3} container columns={{ xs: 4, md: 8, lg: 12 }}>
              <Grid item xs={4}>
                <Card sx={{ minHeight: "10rem" }} variant="outlined">
                  <CardContent>
                    <Stack direction="column" spacing={1}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <SettingsIcon />
                        <Typography variant="h5">Personal Info</Typography>
                      </Stack>

                      <Link
                        to={`communicationPreferences/${client.clientId}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="body1" color="secondary.main">
                          Communication Preferences
                        </Typography>
                      </Link>
                      {/* <Link
                      to="funding/externalFundingSources"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="body1" color="secondary.main">
                        External Funding Sources
                      </Typography>
                    </Link>
                    <Link
                      to="funding/privatePay"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="body1" color="secondary.main">
                        Private Pay Settings
                      </Typography>
                    </Link> */}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={1} sm={0}></Grid>
        </Grid>
      </Box>
    );
  }
}
