import React from "react";
import { RefDataInterface } from "../resources/referenceConstants";
import SelectMenuItems from "./SelectMenuItems";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { defaultNullUndefined } from "../globalFunctions";

interface SelectionProps {
  name: string;
  label: string;
  value: string | number;
  handleChange: Function;
  error: boolean;
  errorMessages?: string;
  disabled?: boolean;
  multiple?: boolean;
  useRef: boolean;
  formattedMenuOptions?: JSX.Element[] | null;
  refMenuOptions?: RefDataInterface[] | null;
  isSmall?: boolean;
  background?: string;
  defaultValueString?: string;
}

export default function Selection({
  name,
  label,
  value,
  handleChange,
  error,
  multiple,
  errorMessages,
  disabled,
  useRef,
  formattedMenuOptions,
  refMenuOptions,
  isSmall,
  background,
  defaultValueString,
}: SelectionProps) {
  const isDisabled = disabled == null ? false : disabled;
  const backgroundColor = defaultNullUndefined(background, "");

  return (
    <FormControl fullWidth size={isSmall ? "small" : "medium"}>
      {(useRef && refMenuOptions == null) ||
      (useRef === false && formattedMenuOptions == null) ? (
        <InputLabel id={`${name}-label`}>Error</InputLabel>
      ) : (
        <>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            variant={isDisabled ? "filled" : "outlined"}
            disabled={isDisabled}
            multiple={multiple}
            name={name}
            size={isSmall ? "small" : "medium"}
            id={name}
            label={label}
            value={value}
            onChange={(event: SelectChangeEvent<string | number>) => {
              handleChange(event);
            }}
            error={error}
            sx={{
              background: backgroundColor,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {useRef && refMenuOptions != null
              ? SelectMenuItems(refMenuOptions, defaultValueString)
              : formattedMenuOptions}
          </Select>
          {error && errorMessages != null ? (
            <FormHelperText error>{errorMessages}</FormHelperText>
          ) : (
            <></>
          )}
        </>
      )}
    </FormControl>
  );
}
