/* eslint-disable array-callback-return */
import * as emailValidator from "email-validator";
import TitleIcon from "@mui/icons-material/Title";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import SegmentIcon from "@mui/icons-material/Segment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import TransgenderIcon from "@mui/icons-material/Transgender";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { FormFieldResponseType } from "../apis/internalDb/forms/forms";
import { defaultNullUndefined, parseIntSafe } from "./globalFunctions";
import { RegExConstants } from "./globalConstants";
import dayjs, { Dayjs } from "dayjs";
import {
  AddressValues,
  ClientNameValues,
  CreditCardInfoValues,
} from "../tabs/documents/viewDocument/DisplayIntakeField";

export interface ResponseStateType {
  field_value: string;
  selected: boolean[];
}

export interface CustomFormFieldDetails {
  custom_form_field_id: number;
  form_field_type: string;
  field_label: string;
  is_required: boolean;
  max_char: number | null;
  custom_form_field_options: CustomFormFieldOptions[] | null;
  is_active: boolean;
}

export interface CustomFormFieldOptions {
  custom_form_field_options_id: number;
  label: string;
  sort_order: number;
  ref_id: number;
  ref_type: string;
  is_active: boolean;
}

export interface FieldTypesObject {
  id: string;
  name: string;
  icon: JSX.Element;
  isEditable: boolean;
  displayIsRequired: boolean;
  displayLabel: boolean;
  displayMaxChar: boolean;
  canManageOptions: boolean;
  defaultMaxChar?: number;
}

export interface FieldOptions {
  custom_form_field_options_id?: number;
  label: string;
  sort_order: number;
  ref_id?: number;
  ref_type?: string;
  selected?: boolean;
}

export interface FieldConfig {
  id: string;
  fieldType: FieldTypesObject;
  custom_form_field_id?: number;
  form_field_type: string;
  field_label: string;
  is_required: boolean;
  max_char: number | null;
  custom_form_field_options: FieldOptions[];
}

export const fieldTypes: FieldTypesObject[] = [
  {
    id: "titleField",
    name: "Title Field",
    icon: <TitleIcon />,
    isEditable: true,
    displayIsRequired: false,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "textField",
    name: "Text Input",
    icon: <TextFieldsIcon />,
    isEditable: true,
    displayIsRequired: true,
    displayLabel: true,
    displayMaxChar: true,
    canManageOptions: false,
    defaultMaxChar: 500,
  },
  {
    id: "paragraph",
    name: "Paragraph",
    icon: <SegmentIcon />,
    isEditable: true,
    displayIsRequired: false,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "checkBox",
    name: "Check Box",
    icon: <CheckBoxIcon />,
    isEditable: true,
    displayIsRequired: true,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: true,
  },
  {
    id: "dropdown",
    name: "Dropdown",
    icon: <ArrowDropDownCircleIcon />,
    isEditable: true,
    displayIsRequired: true,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: true,
  },
  {
    id: "date",
    name: "Date Field",
    icon: <CalendarMonthIcon />,
    isEditable: true,
    displayIsRequired: true,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "divider",
    name: "Divider",
    icon: <HorizontalRuleIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "signature",
    name: "Signature",
    icon: <BorderColorIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
];

export const clientDataFields: FieldTypesObject[] = [
  {
    id: "name",
    name: "Name Field",
    icon: <PermIdentityIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "email",
    name: "Email",
    icon: <AlternateEmailIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "phone",
    name: "Phone #",
    icon: <PhoneIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "legalGender",
    name: "Legal Gender",
    icon: <TransgenderIcon />,
    isEditable: true,
    displayIsRequired: true,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: true,
  },
  {
    id: "race",
    name: "Ethnicity",
    icon: <AccessibilityIcon />,
    isEditable: true,
    displayIsRequired: true,
    displayLabel: true,
    displayMaxChar: false,
    canManageOptions: true,
  },
  {
    id: "birthdate",
    name: "Birth Date",
    icon: <EventIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "address",
    name: "Address",
    icon: <HomeIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "billingInfo",
    name: "Billing Information",
    icon: <HomeIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "billingAddress",
    name: "Billing Address",
    icon: <HomeIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
  {
    id: "creditCardInfo",
    name: "Credit Card Info",
    icon: <CreditCardIcon />,
    isEditable: false,
    displayIsRequired: false,
    displayLabel: false,
    displayMaxChar: false,
    canManageOptions: false,
  },
];

const LEGAL_GENDER_ID = "legalGender";
const RACE_ETHNICITY_ID = "race";
const DROPDOWN_ID = "dropdown";
const CHECK_BOX_ID = "checkBox";

export const setNewFieldConfig = (
  fieldType: FieldTypesObject,
  length: number
) => {
  let fieldOptions: FieldOptions[] = [];
  if (fieldType.id === LEGAL_GENDER_ID || fieldType.id === RACE_ETHNICITY_ID) {
    //Set up with the current ref data
  } else if (fieldType.id === DROPDOWN_ID || fieldType.id === CHECK_BOX_ID) {
    fieldOptions = [
      {
        label: "",
        sort_order: 1,
      },
    ];
  }
  return {
    id: (length + 1).toString(),
    fieldType: fieldType,
    form_field_type: fieldType.id,
    field_label: "",
    is_required: false,
    max_char: fieldType.defaultMaxChar,
    custom_form_field_options: fieldOptions,
  } as FieldConfig;
};

export const setFieldConfigsToEdit = (
  formFieldTypes: CustomFormFieldDetails[]
) => {
  let fieldConfigs: FieldConfig[] = [];
  let fieldTypeList: FieldTypesObject[] = [...fieldTypes, ...clientDataFields];
  if (formFieldTypes.length === 0) {
    return fieldConfigs;
  }

  formFieldTypes.map((field, index) => {
    let fieldType = fieldTypeList.filter(
      (type) => type.id === field.form_field_type
    )[0];
    let options: FieldOptions[] = [];
    if (field.custom_form_field_options != null) {
      field.custom_form_field_options?.map((option, index) => {
        options.push({
          custom_form_field_options_id: option.custom_form_field_options_id,
          label: option.label,
          sort_order: index,
        });
        return;
      });
    }
    fieldConfigs.push({
      id: `${fieldType.id}${index}`,
      fieldType: fieldType,
      custom_form_field_id: field.custom_form_field_id,
      form_field_type: field.form_field_type,
      field_label: field.field_label,
      is_required: field.is_required,
      max_char: field.max_char,
      custom_form_field_options: options,
    });
    return;
  });
  return fieldConfigs;
};

export interface CustomFormFieldDetails {
  custom_form_field_id: number;
  form_field_type: string;
  field_label: string;
  is_required: boolean;
  max_char: number | null;
  custom_form_field_options: CustomFormFieldOptions[] | null;
  is_active: boolean;
}

export interface CustomFormFieldOptions {
  custom_form_field_options_id: number;
  label: string;
  sort_order: number;
  ref_id: number;
  ref_type: string;
  is_active: boolean;
}

export const setFieldConfigsForm = (
  formNoteFieldTypes: FormFieldResponseType[]
) => {
  let fieldConfigs: FieldConfig[] = [];
  let fieldTypeList: FieldTypesObject[] = [...fieldTypes, ...clientDataFields];
  if (formNoteFieldTypes.length === 0) {
    return fieldConfigs;
  }

  formNoteFieldTypes.map((field, index) => {
    let fieldType = fieldTypeList.filter(
      (type) => type.id === field.form_field_type
    )[0];
    let options: FieldOptions[] = [];
    if (field.custom_form_field_options_response != null) {
      field.custom_form_field_options_response?.map((option, index) => {
        options.push({
          custom_form_field_options_id:
            option.custom_form_field_options_response_id,
          label: option.label,
          sort_order: index,
        });
      });
    }
    fieldConfigs.push({
      id: `${fieldType.id}${index}`,
      fieldType: fieldType,
      custom_form_field_id: field.custom_form_field_response_id,
      form_field_type: field.form_field_type,
      field_label: field.field_label,
      is_required: field.is_required,
      max_char: field.max_char,
      custom_form_field_options: options,
    });
    return;
  });
  return fieldConfigs;
};

const validateAddress = (
  street1: string,
  street2: string,
  city: string,
  state: string,
  zipCode: string
) => {
  let errorMessages: string[] = [];
  // validate street1
  if (street1.length === 0) {
    errorMessages.push("Street1 is a required field");
  } else if (street1.length > 100) {
    errorMessages.push("Street1 must be less than 50 characters");
  } else {
    errorMessages.push("");
  }

  // validate street2
  if (street2.length > 100) {
    errorMessages.push("Street2 must be less than 50 characters");
  } else {
    errorMessages.push("");
  }

  // validate city
  if (city.length === 0) {
    errorMessages.push("City is a required field");
  } else if (city.length > 100) {
    errorMessages.push("City must be less than 50 characters");
  } else {
    errorMessages.push("");
  }

  // validate state
  if (state.length === 0) {
    errorMessages.push("State is a required field");
  } else {
    errorMessages.push("");
  }

  // validate zipCode
  if (zipCode.length === 0) {
    errorMessages.push("Zip Code is a required field");
  } else if (zipCode.length > 100) {
    errorMessages.push("Zip Code must be less than 50 characters");
  } else {
    errorMessages.push("");
  }

  return errorMessages;
};

export const isFieldValidForSubmit = (
  fieldConfig: FieldConfig,
  fieldResponse: string
) => {
  const today = dayjs();
  let defaultReturn: string[] = [];

  switch (fieldConfig.fieldType.id) {
    case fieldTypes[0].id:
      // Title
      let titleErrorMessages: string[] = [];
      return titleErrorMessages;
    case fieldTypes[1].id:
      // Text Response
      let textErrorMessage: string[] = [];
      if (fieldConfig.is_required && fieldResponse.length === 0) {
        textErrorMessage.push(
          `Please provide ${fieldConfig.field_label}, it is a required field`
        );
      } else if (
        fieldResponse.length > defaultNullUndefined(fieldConfig.max_char, 50)
      ) {
        textErrorMessage.push(
          `${
            fieldConfig.field_label
          } has exceeded the maximum character count of ${defaultNullUndefined(
            fieldConfig.max_char,
            50
          )}`
        );
      }
      return textErrorMessage;
    case fieldTypes[2].id:
      // Paragraph
      let paragraphErrorMessage: string[] = [];
      if (fieldConfig.is_required && fieldResponse.length === 0) {
        paragraphErrorMessage.push(
          `Please provide ${fieldConfig.field_label}, it is a required field`
        );
      } else if (
        fieldResponse.length > defaultNullUndefined(fieldConfig.max_char, 50)
      ) {
        paragraphErrorMessage.push(
          `${
            fieldConfig.field_label
          } has exceeded the maximum character count of ${defaultNullUndefined(
            fieldConfig.max_char,
            500
          )}`
        );
      }
      return paragraphErrorMessage;
    case fieldTypes[3].id:
      // Check box
      let checkBoxErrors: string[] = [];
      return checkBoxErrors;
    case fieldTypes[4].id:
      // Dropdown
      let dropDownErrors: string[] = [];
      return dropDownErrors;
    case fieldTypes[5].id:
      // Date Field
      let dateErrorMessage: string[] = [];
      const dateSelected = dayjs(fieldResponse);
      if (dateSelected > today) {
        dateErrorMessage.push("Date selected cannot be in the future");
      }
      return dateErrorMessage;
    case fieldTypes[6].id:
      // Divider
      let dividerErrors: string[] = [];
      return dividerErrors;
    case fieldTypes[7].id:
      // Signature
      let signatureErrorMessage: string[] = [];
      if (fieldResponse.length === 0) {
        signatureErrorMessage.push("Signature is a required field");
      }
      return signatureErrorMessage;
    case clientDataFields[0].id:
      // Client Name
      let clientNameErrorMessages: string[] = [];
      let clientNameJsonObj: ClientNameValues;
      try {
        clientNameJsonObj = JSON.parse(defaultNullUndefined(fieldResponse, ""));
      } catch (err) {
        clientNameJsonObj = {
          firstName: "",
          middleName: "",
          lastName: "",
        };
      }

      let firstName = defaultNullUndefined(clientNameJsonObj?.firstName, "");
      let middleName = defaultNullUndefined(clientNameJsonObj?.middleName, "");
      let lastName = defaultNullUndefined(clientNameJsonObj?.lastName, "");

      // Validate firstName
      if (firstName.length === 0) {
        clientNameErrorMessages.push("First name cannot be blank");
      } else if (firstName.length > 50) {
        clientNameErrorMessages.push(
          "First name must be less than 50 characters"
        );
      } else {
        clientNameErrorMessages.push("");
      }

      // Validate middleName
      if (middleName.length > 50) {
        clientNameErrorMessages.push(
          "Middle name must be less than 50 characters"
        );
      } else {
        clientNameErrorMessages.push("");
      }

      // Validate lastName
      if (lastName.length === 0) {
        clientNameErrorMessages.push("Last name cannot be blank");
      } else if (lastName.length > 100) {
        clientNameErrorMessages.push(
          "Last name must be less than 100 characters"
        );
      } else {
        clientNameErrorMessages.push("");
      }

      return clientNameErrorMessages;
    case clientDataFields[1].id:
      // Client Email
      let clientEmailErrorMessage: string[] = [];

      if (fieldResponse.length === 0) {
        clientEmailErrorMessage.push(`Please provide an email`);
      } else if (
        !emailValidator.validate(fieldResponse) &&
        fieldResponse.length > 0
      ) {
        clientEmailErrorMessage.push(
          `${fieldResponse} is not a valid email format`
        );
      }
      return clientEmailErrorMessage;
    case clientDataFields[2].id:
      // Client Phone
      let clientPhoneErrorMessage: string[] = [];

      if (fieldResponse.length === 0) {
        clientPhoneErrorMessage.push(`Please provide a phone number`);
      } else if (
        !RegExConstants.VALID_PHONE_REGEX.test(fieldResponse) &&
        fieldResponse.length > 0
      ) {
        clientPhoneErrorMessage.push(
          `${fieldResponse} is not a valid phone number format`
        );
      }
      return clientPhoneErrorMessage;
    case clientDataFields[3].id:
      // Legal Gender
      // Dropdown
      let legalGenderErrors: string[] = [];
      if (fieldResponse.length === 0) {
        legalGenderErrors.push("Please select a gender");
      }
      return legalGenderErrors;

    case clientDataFields[4].id:
      // Race / Ethnicity
      let raceErrors: string[] = [];
      if (fieldResponse.length === 0) {
        raceErrors.push("Please select a race");
      }
      return raceErrors;
    case clientDataFields[5].id:
      // Birth Date
      let birthDateErrorMessage: string[] = [];
      const birthDateSelected = dayjs(fieldResponse);
      if (birthDateSelected > today) {
        birthDateErrorMessage.push("Date selected cannot be in the future");
      }
      return birthDateErrorMessage;
    case clientDataFields[6].id:
      // Client Address
      let clientAddressErrorMessages = [];
      let clientAddressJsonObj: AddressValues;
      try {
        clientAddressJsonObj = JSON.parse(
          defaultNullUndefined(fieldResponse, "")
        );
      } catch (err) {
        clientAddressJsonObj = {
          street1: "",
          street2: "",
          city: "",
          state: "",
          zipCode: "",
        };
      }

      let street1 = defaultNullUndefined(clientAddressJsonObj?.street1, "");
      let street2 = defaultNullUndefined(clientAddressJsonObj?.street2, "");
      let city = defaultNullUndefined(clientAddressJsonObj?.city, "");
      let state = defaultNullUndefined(clientAddressJsonObj?.state, "");
      let zipCode = defaultNullUndefined(clientAddressJsonObj?.zipCode, "");

      clientAddressErrorMessages = validateAddress(
        street1,
        street2,
        city,
        state,
        zipCode
      );

      return clientAddressErrorMessages;

    case clientDataFields[7].id:
      let unknownErrors: string[] = [];
      return unknownErrors;
    case clientDataFields[8].id:
      // Billing Address
      let billingAddressErrorMessages = [];
      let billingAddressJsonObj: AddressValues;
      try {
        billingAddressJsonObj = JSON.parse(
          defaultNullUndefined(fieldResponse, "")
        );
      } catch (err) {
        billingAddressJsonObj = {
          street1: "",
          street2: "",
          city: "",
          state: "",
          zipCode: "",
        };
      }

      let billingStreet1 = defaultNullUndefined(
        billingAddressJsonObj?.street1,
        ""
      );
      let billingStreet2 = defaultNullUndefined(
        billingAddressJsonObj?.street2,
        ""
      );
      let billingCity = defaultNullUndefined(billingAddressJsonObj?.city, "");
      let billingState = defaultNullUndefined(billingAddressJsonObj?.state, "");
      let billingZipCode = defaultNullUndefined(
        billingAddressJsonObj?.zipCode,
        ""
      );

      billingAddressErrorMessages = validateAddress(
        billingStreet1,
        billingStreet2,
        billingCity,
        billingState,
        billingZipCode
      );

      return billingAddressErrorMessages;

    case clientDataFields[9].id:
      // Credit card info
      let creditCardErrorMessages = [];
      let creditCardJsonObj: CreditCardInfoValues;
      try {
        creditCardJsonObj = JSON.parse(defaultNullUndefined(fieldResponse, ""));
      } catch (err) {
        creditCardJsonObj = {
          cardNumber: 0,
          expirationDate: "",
          csv: 0,
        };
      }

      let cardNumber = defaultNullUndefined(creditCardJsonObj?.cardNumber, "");
      let expirationDate = defaultNullUndefined(
        creditCardJsonObj?.expirationDate,
        ""
      );
      let csv = defaultNullUndefined(creditCardJsonObj?.csv, "");

      if (cardNumber.length === 0) {
        creditCardErrorMessages.push("Credit card is required");
      } else {
        creditCardErrorMessages.push("");
      }

      if (expirationDate.length === 0) {
        creditCardErrorMessages.push("Expiration date is required");
      } else {
        creditCardErrorMessages.push("");
      }

      if (csv.length === 0) {
        creditCardErrorMessages.push("CSV is required");
      } else {
        creditCardErrorMessages.push("");
      }

      return creditCardErrorMessages;

    default:
      return defaultReturn;
  }
};

export const setClientDataToWrite = (
  fieldResponses: FormFieldResponseType[],
  values: ResponseStateType[],
  isWrite: boolean[]
) => {
  let firstName: string | undefined = undefined;
  let middleName: string | undefined = undefined;
  let lastName: string | undefined = undefined;
  let email: string | undefined = undefined;
  let phone: string | undefined = undefined;
  let race: number | undefined = undefined;
  let gender: number | undefined = undefined;
  let birthdate: Dayjs | undefined = undefined;
  let street1: string | undefined = undefined;
  let street2: string | undefined = undefined;
  let city: string | undefined = undefined;
  let state: number | undefined = undefined;
  let zipCode: string | undefined = undefined;
  fieldResponses.map((r, index) => {
    if (!isWrite[index]) return;
    switch (r.form_field_type) {
      case clientDataFields[0].id:
        let parsedName = JSON.parse(
          defaultNullUndefined(values[index].field_value, "")
        );
        firstName = parsedName?.firstName;
        middleName = parsedName?.middleName;
        lastName = parsedName?.lastName;
        return;
      case clientDataFields[1].id:
        email = values[index].field_value;
        return;
      case clientDataFields[2].id:
        phone = values[index].field_value;
        return;
      case clientDataFields[3].id:
        gender = parseIntSafe(values[index].field_value, 0);
        return;
      case clientDataFields[4].id:
        race = parseIntSafe(values[index].field_value, 0);
        return;
      case clientDataFields[5].id:
        birthdate = dayjs(values[index].field_value);
        return;
      case clientDataFields[6].id:
        let parsedAddress = JSON.parse(
          defaultNullUndefined(values[index].field_value, "")
        );
        street1 = parsedAddress?.street1;
        street2 = parsedAddress?.street2;
        city = parsedAddress?.city;
        state = parseIntSafe(parsedAddress?.state, undefined);
        zipCode = parsedAddress?.zipCode;
        return;
      // billingInfo
      // billingAddress;
      // creditcard
      default:
        return;
    }
  });
  return {
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    email: email,
    phone: phone,
    birthdate: birthdate,
    street1: street1,
    street2: street2,
    city: city,
    state: state,
    zipCode: zipCode,
    race: race,
    gender: gender,
  };
};
