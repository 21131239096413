import { PagingInfoProps } from "../../../shared/globalConstants";
import internalConnection from "../internalConnection";

export interface FormResponseType {
  custom_form_response_id: number;
  form_name: string;
  custom_form_field_response: FormFieldResponseType[];
  is_draft: boolean;
  status_id: number | null;
}

export interface FormFieldResponseType {
  custom_form_field_response_id: number;
  custom_form_response_id: number;
  field_label: string;
  field_response: string;
  signature: string | null;
  form_field_type: string;
  is_required: boolean;
  max_char: number | null;
  sort_order: number;
  custom_form_field_options_response: FormFieldOptionsResponseType[];
}

export interface FormFieldOptionsResponseType {
  custom_form_field_options_response_id: number;
  custom_form_field_id: number;
  label: string;
  sort_order: number;
  selected: boolean;
}

export const getFormResponseById = async (
  responseId: number,
  selectedClientId: number
) => {
  const response = await internalConnection.get(
    `customForm/${selectedClientId}/getResponseById`,
    {
      params: {
        id: responseId,
      },
    }
  );
  return response.data.results as FormResponseType;
};

export interface FormResponseDataset {
  data: FormResponseType[];
  totalCount: number;
}

export const getFormResponseByClientId = async (
  pagingInfo: PagingInfoProps,
  responseStatusType: number[] | null,
  mapOrgToClientId: number
) => {
  const response = await internalConnection.get(
    `customForm/${mapOrgToClientId}/getFormResponseByClientId`,
    {
      params: {
        startIndex: pagingInfo.page * pagingInfo.pageSize,
        batchSize: pagingInfo.pageSize,
        sortField: pagingInfo.sortField,
        sortDirection: pagingInfo.sortDirection,
        mapOrgToClientId: mapOrgToClientId,
        responseStatusType: responseStatusType,
      },
    }
  );
  return response.data.results as FormResponseDataset;
};

export interface CustomFormDetailType {
  custom_form_id: number;
  is_published: boolean;
  is_intake: boolean;
  name: string;
  custom_form_field: CustomFormFieldDetails[];

  is_active: boolean;
}

export interface CustomFormFieldOptions {
  custom_form_field_options_id: number;
  label: string;
  sort_order: number;
  ref_id: number;
  ref_type: string;
  is_active: boolean;
}

export interface CustomFormFieldDetails {
  custom_form_field_id: number;
  form_field_type: string;
  field_label: string;
  is_required: boolean;
  max_char: number | null;
  custom_form_field_options: CustomFormFieldOptions[] | null;
  is_active: boolean;
}

export interface UpdateFieldResponseObject {
  id: number;
  field_response: string;
  field_options_response?: UpdateFormFieldOptionsResponseType[];
  isSignature: boolean;
}

export interface UpdateFormFieldOptionsResponseType {
  option: boolean;
  id: number;
}

export interface UpdateFormResponsePayload {
  responseId: number;
  responses: UpdateFieldResponseObject[];
  statusId: number;
  selectedClientId: number;
  userId: string;
}

export const updateFormResponse = async (
  payload: UpdateFormResponsePayload
) => {
  return await internalConnection.put(
    `customForm/${payload.selectedClientId}/updateResponses`,
    payload
  );
};

export const clientPortalFormKeys = {
  all: ["clientPortalForms"] as const,
  lists: () => [...clientPortalFormKeys.all, "list"] as const,
  list: (id: number) => [...clientPortalFormKeys.lists(), { id }] as const,
  details: () => [...clientPortalFormKeys.all, "detail"] as const,
  detail: (id: number) => [...clientPortalFormKeys.details(), id] as const,
  formResponses: () => [...clientPortalFormKeys.all, "formResponse"] as const,
  responseTables: () =>
    [...clientPortalFormKeys.formResponses(), "responseTable"] as const,
  responseTable: (
    pagingInfo: PagingInfoProps,
    clientId: number,
    types: number[]
  ) =>
    [
      ...clientPortalFormKeys.responseTables(),
      pagingInfo,
      clientId,
      types,
    ] as const,
};
