import * as React from "react";

/* --- Project Imports --- */

/* --- MUI Imports --- */
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

interface ModalHeaderProps {
  headerText: string;
  onClose: Function;
}

export default function ModalHeader({ headerText, onClose }: ModalHeaderProps) {
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography sx={{ padding: "0.5rem" }} variant="h2">
          {headerText}
        </Typography>
        <div style={{ marginLeft: "auto" }}>
          <Tooltip title="Close">
            <div>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </Stack>
      <Divider />
    </>
  );
}
