
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

export default function RichTextDisplayField({
  value
}: {
  value: string
}) {
  const reactQuillRef: React.RefObject<ReactQuill> = React.createRef();
  return (
    <div
      style={{
        marginLeft: "-15px",
        marginRight: "-15px",
        marginTop: "-12px",
        marginBottom: "-12px"
      }}
    >
      <ReactQuill
        ref={reactQuillRef}
        preserveWhitespace
        readOnly={true}
        value={value}
        theme={"bubble"}
      />
    </div>
  );
}