import * as React from "react";

/* --- MUI Imports --- */
/* --- Project Imports --- */
import SuccessMessage from "../../shared/ReusableComponents/SuccessMessage";

interface InvoicePDFProps {
  url: string;
  mapOrgToClientId: number;

  showDocument: boolean;
  showSuccessMessage: boolean;
  onAlertClose: Function;
}

export default function InvoicePDF({
  url,
  mapOrgToClientId,
  showDocument,
  showSuccessMessage,
  onAlertClose,
}: InvoicePDFProps) {
  /* --- Handlers --- */

  const handleAlertClose = () => {
    onAlertClose();
  };

  /* --- React Query --- */
  if (url === "") {
    return <></>;
  }
  return (
    <>
      {showSuccessMessage && (
        <SuccessMessage
          open={showSuccessMessage}
          onClose={handleAlertClose}
          alertMessage={"Email sent!"}
        />
      )}

      {showDocument && (
        <iframe
          title={`Invoice-${mapOrgToClientId}`}
          width={1000}
          height={600}
          src={url}
        />
      )}
    </>
  );
}
