/* eslint-disable no-empty-pattern */
import React from "react";

/* --- MUI Imports --- */
import { Box, Tab, Tabs, Typography } from "@mui/material";

/* --- Project Imports --- */
import DocumentsList from "./documentList/DocumentsList";
import { useAppSelector } from "../../state/hooks";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import { selectSelectedClient } from "../../state/slices/access";
import ClientDocumentParent from "./uploadDocuments/ClientDocumentParent";
import TabPanel from "../../shared/ReusableComponents/TabPanel";
import GeneratedPDFParent from "./pdfGeneration/GeneratedPDFParent";

interface DocumentsParentProps {
  formResponseStatusIds: number[];
  showHeader: boolean;
}

export default function DocumentsParent({
  formResponseStatusIds,
  showHeader,
}: DocumentsParentProps) {
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const client = useAppSelector(selectSelectedClient);
  const clientId = defaultNullUndefined(client?.clientId, 0);
  const [documentId, setDocumentId] = React.useState<number>(0);
  const [isViewDocument, setIsViewDocument] = React.useState<boolean>(false);
  /* Document Handlers */

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleViewDocument = (id: number) => {
    setDocumentId(id);
    setIsViewDocument(true);
  };

  const handleCloseViewDocument = () => {
    setDocumentId(0);
    setIsViewDocument(false);
  };
  return (
    <Box>
      {showHeader && <Typography variant="h3">Documents</Typography>}

      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Documents From Provider" />
        <Tab label="My Uploaded Documents" />
      </Tabs>

      <TabPanel value={tabIndex} index={0} padding={0}>
        <DocumentsList
          clientId={clientId}
          formResponseStatusIds={formResponseStatusIds}
          onViewDocument={handleViewDocument}
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={1} padding={0}>
        <ClientDocumentParent />
      </TabPanel>
      {isViewDocument && (
        <GeneratedPDFParent
          documentId={documentId}
          clientId={clientId}
          onCancel={handleCloseViewDocument}
          showDialog={true}
        />
      )}
    </Box>
  );
}
