import * as React from "react";

/* --- MUI Imports --- */
/* --- Project Imports --- */
interface GeneratedPDFProps {
  url: string;
}

export default function GeneratedPDF({ url }: GeneratedPDFProps) {
  /* --- Handlers --- */

  /* --- React Query --- */
  if (url === "") {
    return <></>;
  }
  return (
    <>
      <iframe title={`Client Document`} width={1000} height={600} src={url} />
    </>
  );
}
