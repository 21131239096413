import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React from "react";

interface SubmissionErrorProps {
  additionalMessage?: string;
  showDefaultMessage?: boolean;
}

export default function SubmissionError({
  additionalMessage,
  showDefaultMessage,
}: SubmissionErrorProps) {
  const defaultMessage: string =
    "There was an error submitting the data, please try again later.";
  return (
    <Box>
      <Alert severity="error">
        <AlertTitle>Submission Error</AlertTitle>
        {showDefaultMessage && defaultMessage}
        {additionalMessage && (
          <Typography variant="body2">{additionalMessage}</Typography>
        )}
      </Alert>
    </Box>
  );
}
