import { Stack, Typography } from "@mui/material";
import React from "react";
import { defaultNullUndefined } from "../globalFunctions";

interface PageTitleProps {
  icon?: JSX.Element;
  title: string;
  marginBelow?: number;
}

export default function PageTitle({
  icon,
  title,
  marginBelow,
}: PageTitleProps) {
  const mb = defaultNullUndefined(marginBelow, 2);
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", alignItems: "center", mb: mb }}
      >
        {icon}
        <Typography variant="h3">{title}</Typography>
      </Stack>
    </>
  );
}
