import React from "react";

import { Box, Stack, Typography } from "@mui/material";

export interface CustomNoteFormFieldResponseObject {
  form_field_type: string | null;
  field_label: string;
  is_required: boolean;
  max_char: number | null;
  field_response: string | null;
  custom_notes_form_field_response_id: number;
}

interface EventDisplayNoteResponseItemProps {
  responseItem: CustomNoteFormFieldResponseObject;
}

export default function EventDisplayNoteResponseItem({
  responseItem,
}: EventDisplayNoteResponseItemProps) {
  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">{responseItem.field_label}</Typography>
        <Typography variant="body1">{responseItem.field_response}</Typography>
      </Stack>
    </Box>
  );
}
