import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import EventDisplayNoteResponseItem, {
  CustomNoteFormFieldResponseObject,
} from "./EventDisplayNoteResponseItem";
import { defaultNullUndefined } from "../../shared/globalFunctions";

export interface ClinicalNoteDetailType {
  clinical_note_id: number;
  custom_notes_form_response_id: number | null;
  client_id: number;
  user_id: number;
  event_client_id: number;
  signed_on: Date;
  is_locked: boolean;
  custom_notes_form_response: CustomNoteFormResponseObject;
}

export interface CustomNoteFormResponseObject {
  is_draft: boolean;
  template_name: string | null;
  custom_notes_form_field_response: CustomNoteFormFieldResponseObject[];
}

interface EventDisplayNoteResponseProps {
  clinicalNote: ClinicalNoteDetailType;
}

export default function EventDisplayNoteResponse({
  clinicalNote,
}: EventDisplayNoteResponseProps) {
  const isSigned = clinicalNote.signed_on !== null && clinicalNote.is_locked;
  const signedData = isSigned ? dayjs(clinicalNote.signed_on) : null;
  const noteStatus = isSigned
    ? `Signed and Locked: ${signedData?.format("D-MM-YY h:mma")}`
    : "Draft - Pending Signature";
  const customFormFieldResponses: CustomNoteFormFieldResponseObject[] =
    defaultNullUndefined(
      clinicalNote.custom_notes_form_response?.custom_notes_form_field_response,
      []
    );

  return (
    <Box>
      <Stack direction="column" spacing={0.5}>
        {clinicalNote.custom_notes_form_response.template_name !== null && (
          <Typography variant="h5">
            {clinicalNote.custom_notes_form_response.template_name}
          </Typography>
        )}
        <Typography variant="h6">{noteStatus}</Typography>
        {customFormFieldResponses.map(
          (cnr: CustomNoteFormFieldResponseObject, index: number) => (
            <EventDisplayNoteResponseItem
              key={`response${index}`}
              responseItem={cnr}
            />
          )
        )}
      </Stack>
    </Box>
  );
}
