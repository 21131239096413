import React from "react";
import {
  CUSTOM_FORM_STATUS_APPROVED_ID,
  CUSTOM_FORM_STATUS_DRAFT_ID,
  CUSTOM_FORM_STATUS_NEW_ID,
  CUSTOM_FORM_STATUS_SUBMIT_ID,
} from "../../../shared/resources/referenceConstants";
import { Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAsIcon from "@mui/icons-material/SaveAs";

interface DisplayDocumentStatusProps {
  statusId: number;
}

export default function DisplayDocumentStatus({
  statusId,
}: DisplayDocumentStatusProps) {
  return (
    <>
      {statusId === CUSTOM_FORM_STATUS_NEW_ID && (
        <Chip
          icon={<CancelIcon />}
          label="Not Started"
          color="error"
          variant="outlined"
        />
      )}
      {statusId === CUSTOM_FORM_STATUS_DRAFT_ID && (
        <Chip
          icon={<SaveAsIcon />}
          label="Draft"
          color="warning"
          variant="outlined"
        />
      )}
      {statusId === CUSTOM_FORM_STATUS_SUBMIT_ID && (
        <Chip
          icon={<CheckCircleIcon />}
          label="Complete"
          color="success"
          variant="outlined"
        />
      )}
      {statusId === CUSTOM_FORM_STATUS_APPROVED_ID && (
        <Chip
          icon={<CheckCircleIcon />}
          label="Complete"
          color="success"
          variant="outlined"
        />
      )}
    </>
  );
}
