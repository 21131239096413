/* --- Interfaces -- */
export interface RefDataInterface {
  ref_id: number;
  label: string;
  label_abbr: string;
  ref_type: string;
}

export interface RefDataObjectInterface {
  [refType: string]: RefDataInterface[];
}

/* --- Data Manipulation Functions --- */
export const indexRefType = (
  refData?: RefDataObjectInterface,
  refType?: string
) => {
  if (refData == null || refType == null) {
    return [] as RefDataInterface[];
  } else {
    return refData[refType] as RefDataInterface[];
  }
};

export const eventTypeToClientStatus = (eventTypeId: number) => {
  switch (eventTypeId) {
    case APPT_TYPE_GROUP_CLIENT_ID:
      return [CLIENT_STATUS_ACTIVE_ID];
    case APPT_TYPE_IND_CLIENT_ID:
      return [CLIENT_STATUS_ACTIVE_ID];
    case APPT_TYPE_INIT_CONSULT_ID:
      return [CLIENT_STATUS_PROSPECT_ID];
    default:
      return [];
  }
};

/* --- Constants --- */
export const NO_SHOW_BILLING_REF_TYPE = "NO_SHOW_BILLING_TYPE";
export const NO_SHOW_BILLING_TYPE_FLAT_RATE_ID = 1;
export const NO_SHOW_BILLING_TYPE_PERCENT_RATE_ID = 2;
export const LATE_CANCEL_BILLING_REF_TYPE = "LATE_CANCEL_BILLING_TYPE";
export const LATE_CANCEL_BILLING_TYPE_FLAT_RATE_ID = 4;
export const LATE_CANCEL_BILLING_TYPE_PERCENT_RATE_ID = 5;
export const INVOICE_TYPE_REF_TYPE = "INVOICE_TYPE";
export const CONTACT_TYPE_REF_TYPE = "CLIENT_CONTACT_TYPE";
export const CLIENT_STATUS_REF_TYPE = "CLIENT_STATUS_TYPE";
export const CLIENT_STATUS_PROSPECT_ID = 16;
export const CLIENT_STATUS_ACTIVE_ID = 17;
export const CLIENT_STATUS_ARCHIVED_ID = 18;
export const CLIENT_STATUS_PENDING_INITIAL_CONSULT_ID = 19;
export const ALL_CLIENT_ID = 0;
export const US_STATE_REF_TYPE = "US_STATE";
export const GENDER_ID_REF_TYPE = "GENDER_IDENTITY";
export const PAY_TYPE_REF_TYPE = "PAY_TYPE";
export const CLIENT_RACE_REF_TYPE = "CLIENT_RACE";
export const APPT_TYPE_REF_TYPE = "APPT_TYPE";
export const APPT_TYPE_OTHER_ID = 97;
export const APPT_TYPE_OFFICE_HOURS_ID = 91;
export const APPT_TYPE_GROUP_CLIENT_ID = 90;
export const APPT_TYPE_IND_CLIENT_ID = 89;
export const APPT_TYPE_TRAVEL_BETWEEN_ID = 92;
export const APPT_TYPE_LUNCH_ID = 93;
export const APPT_TYPE_PAID_BREAK_ID = 94;
export const APPT_TYPE_PAID_TIME_OFF_ID = 95;
export const APPT_TYPE_UNPAID_TIME_OFF_ID = 96;
export const APPT_TYPE_INIT_CONSULT_ID = 110;
export const APPT_TYPE_NON_CLIENT_LIST = [91, 92, 93, 94, 95, 96, 97];
export const OFFICE_HOURS_REF_TYPE = "OFFICE_HOURS_TYPE";
export const OFFICE_HOURS_OTHER_ID = 104;
export const SERVICE_RATE_REF_TYPE = "SERVICE_RATE_TYPE";
export const SERVICE_RATE_TIMED_CODE_ID = 111;
export const SERVICE_RATE_UNTIMED_CODE_ID = 112;
export const SERVICE_RATE_ITEM_ID = 113;
export const TIMED_CODE_REF_TYPE = "TIMED_CODE_TYPE";
export const TIMED_CODE_UNIT_ID = 114;
export const TIMED_CODE_HOUR_ID = 115;
export const TIMED_CODE_MINUTES_ID = 116;
export const LEGAL_GENDER_TYPE = "LEGAL_GENDER";
export const NOTE_REF_TYPE = "NOTE_TYPE";
export const NOTE_TYPE_INTAKE_ID = 121;
export const NOTE_TYPE_NON_CLINICAL_ID = 122;
export const NOTE_TYPE_ADMIN_ID = 123;
export const NOTE_TYPE_BILLING_ID = 127;
export const NOTE_TYPE_CONTACT_ID = 124;
export const NOTE_TYPE_CLINICAL_ID = 125;
export const NOTE_PARENT_REF_TYPE = "NOTE_PARENT_TYPE";
export const CLIENT_STATUS_WAITLIST_ID = 129;
export const NOTE_PARENT_CUSTOM_NOTE_FORM = 136;
export const INITIAL_CONSULT_FORM_ID = 1;
export const INIT_CONSULT_NEXT_STEP_TYPE = "INIT_CONSULT_NEXT_STEP";
export const INIT_CONSULT_STEP_ACCEPT = 131;
export const INIT_CONSULT_STEP_REFER = 132;
export const INIT_CONSULT_STEP_WAITLIST = 133;
export const INIT_CONSULT_STEP_AWAIT = 134;
export const INIT_CONSULT_STEP_DNMF = 135;
export const NOTES_FORM_TYPE_CLINICAL_NOTES = 137;
export const NOTES_FORM_TYPE_INITIAL_CONSULT = 130;
export const APPT_CANCEL_REASON_REF_TYPE = "APPT_CANCEL_REASON";
export const APPT_CANCEL_REASON_CLIENT = 138;
export const APPT_CLIENT_CANCEL_REASON_REF_TYPE = "APPT_CLIENT_CANCEL_REASON";
export const EVENT_STATUS_REF_TYPE = "EVENT_STATUS";
export const EVENT_STATUS_CREATED_ID = 144;
export const EVENT_STATUS_COMPLETED_ID = 145;
export const EVENT_STATUS_CANCEL_ID = 146;
export const EVENT_STATUS_CHECKED_IN_ID = 214;
export const EVENT_STATUS_CHECKED_OUT_ID = 215;
export const EVENT_STATUS_NOTE_UNLOCKED_ID = 216;
export const DOC_TYPE_REF_TYPE = "DOCUMENT_TYPE";
export const DOC_TYPE_EVAL_REPORT_ID = 147;
export const DOC_TYPE_PHYSICIAN_REPORT_ID = 148;
export const DOC_TYPE_PRIOR_AUTHORIZATION_ID = 149;
export const DOC_TYPE_INCIDENT_REPORT_ID = 150;
export const DOC_TYPE_EXTERNAL_PROVIDER_DOCUMENTATION_ID = 151;
export const DOC_TYPE_SCHOOL_DOCUMENT_ID = 152;
export const DOC_TYPE_MISC_ID = 153;
export const SERVICE_LOCATION_REF_TYPE = "SERVICE_LOCATION_TYPE";
export const AUTH_PARTY_REF_TYPE = "AUTH_PARTY_TYPE";
export const CUSTOM_FORM_STATUS_NEW_ID = 158;
export const CUSTOM_FORM_STATUS_DRAFT_ID = 159;
export const CUSTOM_FORM_STATUS_SUBMIT_ID = 160;
export const CUSTOM_FORM_STATUS_APPROVED_ID = 161;
export const TRANSACTION_REF_TYPE = "TRANSACTION_TYPE";
export const TRANSACTION_TYPE_PAYMENT_ID = 154;
export const TRANSACTION_TYPE_REFUND_ID = 155;
export const TRANSACTION_TYPE_INVOICE_ID = 156;
export const TRANSACTION_TYPE_WRITE_OFF_ID = 157;
export const PAYMENT_REF_TYPE = "PAYMENT_TYPE";
export const PAYMENT_TYPE_CASH_ID = 162;
export const PAYMENT_TYPE_CHECK_ID = 163;
export const PAYMENT_TYPE_CARD_ID = 164;
export const PAYMENT_TYPE_OTHER_ID = 166;

export const PAYMENT_TYPE_WRITE_OFF_ID = 217;
export const INSURER_TYPE_REF_TYPE = "INSURER_TYPE";
export const INSURER_TYPE_MEDICAID_ID = 168;
export const INSURER_TYPE_OTHER_ID = 173;
export const APEX_CLEARING_HOUSE = "Apex";
export const INSURANCE_TYPE_REF_TYPE = "INSURANCE_TYPE";
export const INSURANCE_TYPE_PRIMARY_ID = 174;
export const ACCIDENT_TYPE_REF_TYPE = "ACCIDENT_TYPE";
export const ACCIDENT_TYPE_NONE_ID = 177;
export const ACCIDENT_TYPE_EMPLOYMENT_ID = 178;
export const ACCIDENT_TYPE_AUTO_ID = 179;
export const ACCIDENT_TYPE_OTHER_ID = 180;
export const INSURED_RELATIONSHIP_REF_TYPE = "INSURED_RELATIONSHIP";
export const INSURED_RELATIONSHIP_SELF_ID = 181;
export const AUTH_PARTY_TYPE_REF_TYPE = "AUTH_PARTY_TYPE";
export const AUTH_PARTY_TYPE_INSUR_ID = 190;
export const AUTH_PARTY_TYPE_EXTERNAL_ID = 191;
export const AUTH_PARTY_TYPE_SELF_ID = 192;
export const REPEAT_TYPE_REF_TYPE = "REPEAT_TYPE";
export const REPEAT_TYPE_WEEKLY_ID = 204;
export const REPEAT_TYPE_BIWEEKLY_ID = 205;
export const REPEAT_TYPE_MONTHLY_ID = 206;
export const REPEAT_TYPE_YEARLY_ID = 207;
export const FLAG_REF_TYPE = "FLAG_TYPE";
export const FLAG_TYPE_INVOICE_ID = 212;
export const FLAG_TYPE_CLIENT_ID = 213;
export const INVOICE_ITEM_TYPE_REF_TYPE = "INVOICE_ITEM_TYPE";
export const INVOICE_ITEM_SERVICE_ID = 212;
export const INVOICE_ITEM_PRODUCT_ID = 213;
export const INVOICE_CREATED_SUCCESSFULLY_MESSAGE =
  "Invoice Created Successfully!";

export const PAYMENT_SUCCESSFUL_MESSAGE = "Payment Successful!";
export const INVOICE_EDITED_SUCCESSFULLY_MESSAGE = "Invoice Edited!";
export const GENERIC_SUCCESS_MESSAGE = "Success!";
export const GENERIC_DATA_SAVED_MESSAGE = "Data Saved!";
export const INVOICE_SAVED_STATE = "invoiceCreated";
export const DATA_SAVED_STATE = "savedSuccessfully";
export const PAYMENT_SUBMITTED_STATE: string = "paymentSubmitted";
export const PAYMENT_SUBMITTED_SCUCCESSFULLY_MESSAGE: string =
  "Payment Submitted Successfully";
export const INVOICE_EMAIL_TEMPLATE_ID = 3;
export const SUPERBILL_EMAIL_TEMPLATE_ID = 8;
export const CLIENT_PORTAL_ACCESS_EMAIL_TEMPLATE_ID = 1;
export const EMAIL_TYPE_REF_TYPE = "EMAIL_TEMPLATE_TYPE";
export const CLIENT_PORTAL_ACCESS_EMAIL_TEMPLATE_TYPE_ID = 221;
export const CLIENT_PORTAL_ACCESS_CODE_TEMPLATE_ID = 222;
export const CLIENT_ASSIGNED_INTAKE_DOC_TEMPLATE_ID = 224;
export const APPOINTMENT_REMINDER_TEMPLATE_ID = 225;
export const PASSWORD_RESET_TEMPLATE_ID = 226;
export const EMAIL_TEMPLATE_TYPE_OTHER_ID = 226;
