/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, LinearProgress, Typography, Tab, Tabs } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../state/hooks";
import { selectSelectedClient } from "../../state/slices/access";
import EventDisplayExistingNotes from "./EventDisplayExistingNotes";
import NotesDataTable from "./NotesDataTable";
import TabPanel from "../../shared/ReusableComponents/TabPanel";

export default function NotesParent() {
  const [showNote, setShowNote] = React.useState(false);
  const [showNoteId, setShowNoteId] = React.useState(0);
  const client = useAppSelector(selectSelectedClient);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  /* --- Handlers ---*/
  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  if (client === undefined || client === null) {
    return <LinearProgress />;
  } else {
    return (
      <>
        <Box>
          <Typography variant="h3">Notes</Typography>

          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Clinical Notes" />
          </Tabs>

          <TabPanel value={tabIndex} index={0} padding={0}>
            <NotesDataTable clientId={client.clientId} />
          </TabPanel>
        </Box>
      </>
    );
  }
}
