import React from "react";
import { Typography } from "@mui/material";

interface ActionLinkProps {
  text: string;
  action: Function;
  size?: number;
}

export default function ActionLink({ text, action, size }: ActionLinkProps) {
  return (
    <Typography
      variant="body2"
      sx={{
        "&:hover": { cursor: "pointer", color: "#143e7e" },
        textDecoration: "underline",
        color: "#1f345e",
        fontSize: size === undefined ? "0.75rem" : size,
      }}
      onClick={() => {
        action();
      }}
    >
      {text}
    </Typography>
  );
}
