/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";

/* --- MUI Imports --- */
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
/* --- Project Imports --- */
import { useAppSelector } from "../../state/hooks";

import { baseUrl } from "../../apis/internalDb/internalConnection";
import { Dayjs } from "dayjs";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import InvoicePDF from "./InvoicePDF";
import ModalHeader from "../../shared/ReusableComponents/ModalHeader";
import { selectSelectedClient } from "../../state/slices/access";

interface InvoicePDFParentProps {
  invoiceIds: number[];
  clientId: number;
  onCancel: Function;
  showDialog: boolean;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
}

function generateUrl(
  clientId: number,
  orgId: number,
  invoiceIds?: number[],
  startDate?: Dayjs | null,
  endDate?: Dayjs | null
): string {
  const rootURL = `${baseUrl}/billing/${clientId}/generateAccountStatement`;
  const params = new URLSearchParams();

  // Add invoice IDs to the params if they exist
  if (invoiceIds && invoiceIds.length > 0) {
    invoiceIds.forEach((id, index) => {
      params.append(`invoiceIds[${index}]`, id.toString());
    });
  }

  // Add other parameters
  params.append("mapOrgToClientId", clientId.toString());
  params.append("orgProfileId", orgId.toString());

  // Add startDate and endDate if they are provided
  if (startDate) {
    params.append("startDate", startDate.toString());
  }

  if (endDate) {
    params.append("endDate", endDate.toString());
  }

  // Construct the final URL
  return `${rootURL}?${params.toString()}`;
}

export default function InvoicePDFParent({
  invoiceIds,
  clientId,
  onCancel,
  startDate,
  endDate,
  showDialog,
}: InvoicePDFParentProps) {
  const client = useAppSelector(selectSelectedClient);
  const currentOrgId = defaultNullUndefined(client?.orgId, 0);

  const [showSuccessMessage, setShowSuccessMessage] =
    React.useState<boolean>(false);
  const [showDocument, setShowDocument] = React.useState<boolean>(true);

  /* --- Handlers --- */
  const handleCancel = () => {
    onCancel();
  };

  const handleAlertClose = () => {
    setShowSuccessMessage(false);
  };

  /* --- React Query --- */
  return (
    <Dialog
      open={showDialog}
      sx={{
        "& .MuiDialog-paper": { width: "75%", maxHeight: "90%", p: 0 },
      }}
      maxWidth="lg"
    >
      <DialogContent>
        <ModalHeader headerText={""} onClose={handleCancel} />

        <InvoicePDF
          url={generateUrl(
            clientId,
            currentOrgId,
            invoiceIds,
            startDate,
            endDate
          )}
          showDocument={showDocument}
          mapOrgToClientId={clientId}
          showSuccessMessage={showSuccessMessage}
          onAlertClose={handleAlertClose}
        />
      </DialogContent>

      <DialogActions>
        <Button color="error" onClick={handleCancel} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
