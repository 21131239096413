/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

/* --- MUI Imports --- */
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { IconButton, Box, Tooltip, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
/* --- Project Imports --- */
import {
  FormResponseType,
  clientPortalFormKeys,
  getFormResponseByClientId,
  FormResponseDataset,
} from "../../../apis/internalDb/forms/forms";
import { defaultNullUndefined } from "../../../shared/globalFunctions";
import DataRetrievalError from "../../../shared/ReusableComponents/DataRetrievalError";
import DisplayDocumentStatus from "./DisplayDocumentStatus";
import {
  CUSTOM_FORM_STATUS_DRAFT_ID,
  CUSTOM_FORM_STATUS_NEW_ID,
} from "../../../shared/resources/referenceConstants";
import EmptyDataTableMessage from "../../../shared/ReusableComponents/EmptyDataTableMessage";
import ActionLink from "../../../shared/ReusableComponents/ActionLink";

interface IntakeFormDataTableProps {
  clientId: number;
  formResponseStatusIds: number[];
  onViewDocument: Function;
}

export default function ClientPortalFormDataTable({
  clientId,
  formResponseStatusIds,
  onViewDocument,
}: IntakeFormDataTableProps) {
  const PAGE_SIZE = [15, 50, 100];
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });
  const [sortField, setSortField] = React.useState<string | undefined>(
    undefined
  );
  const [sortDirection, setSortDirection] = React.useState<string | undefined>(
    undefined
  );

  const handleViewDocument = (id: GridRowId) => {
    onViewDocument(Number(id));
  };

  const {
    isLoading: formsLoading,
    isError: formsError,
    data: formsData,
  } = useQuery<FormResponseDataset, Error>(
    clientPortalFormKeys.responseTable(
      { sortField, sortDirection, ...paginationModel },
      clientId,
      formResponseStatusIds
    ),
    () =>
      getFormResponseByClientId(
        { sortField, sortDirection, ...paginationModel },
        formResponseStatusIds,
        clientId
      )
  );

  const defaultList: FormResponseType[] = [];
  const rows = defaultNullUndefined(formsData?.data, defaultList);

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return params.row.status_id === CUSTOM_FORM_STATUS_NEW_ID ||
          params.row.status_id === CUSTOM_FORM_STATUS_DRAFT_ID ? (
          <Link to={`/documents/form/${params.id}`}>
            <DisplayDocumentStatus statusId={params.row.status_id} />
          </Link>
        ) : (
          <DisplayDocumentStatus statusId={params.row.status_id} />
        );
      },
      sortable: false,
      hideable: false,
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "name",
      headerName: "Form Name",
      sortable: false,
      hideable: false,
      maxWidth: 600,
      flex: 1,
      renderCell: (params) => {
        return params.row.status_id === CUSTOM_FORM_STATUS_NEW_ID ||
          params.row.status_id === CUSTOM_FORM_STATUS_DRAFT_ID ? (
          <Link to={`/documents/form/${params.id}`}>
            {params.row?.form_name}
          </Link>
        ) : (
          <>{params.row?.form_name}</>
        );
      },
    },
    {
      field: " ",
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <>
          {params.row.status_id === CUSTOM_FORM_STATUS_NEW_ID ||
          params.row.status_id === CUSTOM_FORM_STATUS_DRAFT_ID ? (
            <Tooltip title="Edit">
              <Link to={`/documents/form/${params.id}`}>
                <IconButton>
                  <EditIcon color="action" />
                </IconButton>
              </Link>
            </Tooltip>
          ) : (
            <Tooltip title="View">
              <IconButton
                onClick={() => {
                  handleViewDocument(params.row.custom_form_response_id);
                }}
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
      flex: 1,
      maxWidth: 100,
    },
  ];

  if (formsLoading) {
    return <LinearProgress />;
  } else if (formsError || formsData === undefined) {
    return <DataRetrievalError />;
  } else {
    return (
      <Box sx={{ width: "100%", pt: 2 }}>
        {formsData.totalCount === 0 ? (
          <EmptyDataTableMessage message={"No Documents Yet"} />
        ) : (
          <DataGrid
            sx={{ fontSize: "medium" }}
            rows={rows}
            columns={columns}
            pageSizeOptions={[20]}
            getRowId={(row) => row.custom_form_response_id}
            checkboxSelection={false}
            isRowSelectable={() => false}
          />
        )}
      </Box>
    );
  }
}
