import React from "react";

/* --- MUI Imports --- */
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { IconButton, Box, Tooltip, Stack } from "@mui/material";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import PersonIcon from "@mui/icons-material/Person";
/* --- Project Imports --- */
import { defaultNullUndefined } from "../../shared/globalFunctions";
import EmptyDataTableMessage from "../../shared/ReusableComponents/EmptyDataTableMessage";
import NoteDialog from "../notes/NoteDialog";
import dayjs from "dayjs";
import {
  EventHistory,
  EventHistoryDataset,
} from "../../apis/internalDb/calendar/event";
import { APPT_TYPE_INIT_CONSULT_ID } from "../../shared/resources/referenceConstants";

interface AppointmentDataTableProps {
  clientId: number;
  onSortChange: Function;
  onPageChange: Function;
  appointmentsData: EventHistoryDataset | undefined;
  sortModel: GridSortModel;
  paginationModel: GridPaginationModel;
  pageSizes: number[];
  showColumns?: {
    start_datetime?: boolean;
    start_time?: boolean;
    client_full_name?: boolean;
    service_location_id?: boolean;
    event_service_provider?: boolean;
    event_service_names?: boolean;
    event_status_label?: boolean;
    cancel_comment?: boolean;
  };
}
export default function AppointmentDataTable({
  clientId,
  onSortChange,
  onPageChange,
  appointmentsData,
  sortModel,
  paginationModel,
  pageSizes,
  showColumns,
}: // clientId,
// formResponseStatusIds,
AppointmentDataTableProps) {
  const [showNoteDialog, setShowNoteDialog] = React.useState(false);
  const [eventId, setEventId] = React.useState<number>(-1);

  /* --- React Query --- */

  /* --- Handlers --- */
  const handleViewNote = (eventId: number) => {
    setEventId(eventId);
    setShowNoteDialog(true);
  };

  const handleCloseDialog = () => {
    setEventId(-1);
    setShowNoteDialog(false);
  };
  const displayData: EventHistory[] = defaultNullUndefined(
    appointmentsData?.data,
    []
  );

  const columns: GridColDef[] = [
    {
      field: "start_datetime",
      headerName: "Date",
      sortable: false,
      hideable: false,
      maxWidth: 300,
      flex: 1,
      valueGetter: (params) => {
        return dayjs(params.row?.start_datetime).format("MMMM D, YYYY h:mm A");
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      sortable: false,
      hideable: false,
      maxWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return defaultNullUndefined(params.row?.duration, 0) + " mins";
      },
    },
    {
      field: "event_service_provider",
      headerName: "Provider(s)",
      sortable: false,
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Stack direction="column">
            {params.row.event_staff.map((s: any, index: number) => (
              <div key={`provider${index}`}>
                {s.is_billing_provider && (
                  <>
                    <Tooltip title="Billing Provider">
                      <PersonIcon fontSize="small" color="secondary" />
                    </Tooltip>{" "}
                  </>
                )}
                {s.user_first_name + " " + s.user_last_name}
              </div>
            ))}
          </Stack>
        );
      },
    },
    {
      field: "event_service_names",
      headerName: "Service Type(s)",
      sortable: false,
      minWidth: 300,
      flex: 2,
      renderCell: (params) => {
        if (params.row.event_type_id === APPT_TYPE_INIT_CONSULT_ID) {
          return <>{params.row.event_type_label}</>;
        } else {
          return (
            <Stack direction="column">
              {params.row.event_service.map((s: any, index: number) => (
                <div key={`service${index}`}>{s.code + ": " + s.name}</div>
              ))}
            </Stack>
          );
        }
      },
    },
    {
      field: "event_status_label",
      headerName: "Status",
      sortable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.is_rescheduled && (
              <>
                <Tooltip title="Rescheduled">
                  <EventRepeatIcon fontSize="small" color="secondary" />
                </Tooltip>
                {"  "}
              </>
            )}
            {params.row.event_status_label}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <Tooltip title="View Notes and Details">
          <div>
            <IconButton
              onClick={() => {
                handleViewNote(params.row.event_id);
              }}
            >
              <NoteAltIcon />
            </IconButton>
          </div>
        </Tooltip>
      ),
      flex: 1,
      maxWidth: 200,
    },
  ];

  const noRowsOverlayComponent = () => {
    return <EmptyDataTableMessage message="No Appointments" />;
  };

  return (
    <>
      <Box>
        <Box sx={{ width: "100%", pt: 2 }}>
          <DataGrid
            autoHeight
            getRowHeight={() => "auto"}
            slots={{
              noRowsOverlay: noRowsOverlayComponent,
              toolbar: GridToolbar,
            }}
            sx={{
              fontSize: 14,
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "8px",
              },
            }}
            rows={displayData}
            columns={columns}
            pageSizeOptions={pageSizes}
            getRowId={(row) => row.event_id}
            isRowSelectable={() => false}
            paginationModel={paginationModel}
            disableColumnSelector
            paginationMode="server"
            onPaginationModelChange={(model: GridPaginationModel) => {
              onPageChange(model);
            }}
            sortModel={sortModel}
            sortingMode="server"
            onSortModelChange={(model: GridSortModel) => {
              onSortChange(model);
            }}
            disableColumnFilter
            disableDensitySelector
            disableVirtualization
            rowCount={defaultNullUndefined(appointmentsData?.totalCount, 0)}
          />
        </Box>
      </Box>
      {showNoteDialog && (
        <NoteDialog
          clientId={clientId}
          eventId={eventId}
          onCloseNote={handleCloseDialog}
        />
      )}
    </>
  );
}
