import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import { RootState } from "../store";

export interface ClientInterface {
  clientId: number;
  mapClientId: number;
  orgName: string;
  orgId: number;
  stripeAccountId: string;
  firstName: string;
  lastName: string;

  permissions: {
    can_access_portal: boolean;
    can_manage_documents: boolean;
    can_view_clinical_notes: boolean;
    can_view_billing: boolean;
  };
}

export interface AuthState {
  clientList: ClientInterface[];
  selectedClient: ClientInterface | null;
}

const initialState: AuthState = {
  clientList: [],
  selectedClient: null,
};

const accessSlice = createSlice({
  name: "access",
  initialState: initialState,
  reducers: {
    setClientList: (state, action: PayloadAction<ClientInterface[]>) => {
      state.clientList = defaultNullUndefined(action.payload, []);
      state.selectedClient = defaultNullUndefined(action.payload[0], null);
    },
    setSelectedClient: (state, action: PayloadAction<ClientInterface>) => {
      state.selectedClient = action.payload;
    },
  },
});

// Action creators
export const { setClientList, setSelectedClient } = accessSlice.actions;
export const selectClientList = (state: RootState) => state.access.clientList;
export const selectSelectedClient = (state: RootState) =>
  state.access.selectedClient;

export const selectPermissions = (state: RootState) =>
  state.access.selectedClient?.permissions;

export default accessSlice.reducer;
