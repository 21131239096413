import { defaultNullUndefined } from "../../shared/globalFunctions";
import internalConnection from "./internalConnection";

export interface PutDocumentPayload {
  documentId: number;
  mapClientId: number;
  userId: string;
  docRefType: number;
  displayName: string;
  comment: string;
}

export const putDocumentToClient = async (payload: PutDocumentPayload) => {
  return await internalConnection.put(
    `/documents/${payload.mapClientId}/putDocument`,
    payload
  );
};

export interface DeactivateDocumentPayload {
  documentId: number;
  selectedClientId: number;
}

export const deactivateDocument = async (
  payload: DeactivateDocumentPayload
) => {
  return await internalConnection.put(
    `/documents/${payload.selectedClientId}/deactivateDocument`,
    {
      documentId: payload.documentId,
    }
  );
};

export interface ClientDocumentDetailsType {
  document_id: number;
  client_id: number;
  document_path: string;
  display_name: string;
  comment: string;
  doc_type_ref_id: number;
  created_by: string;
  created_on: Date;
  updated_by: string;
  updated_on: Date;
  is_active: boolean;
}

export const getDocumentById = async (
  documentId: number,
  selectedClientId: number
) => {
  const response = await internalConnection.get(
    `document/${selectedClientId}/getDocumentById`,
    {
      params: {
        documentId: documentId,
      },
    }
  );
  return response.data.results as ClientDocumentDetailsType;
};

export interface ClientDocumentListType {
  document_id: number;
  client_id: number;
  display_name: string;
  comment: string;
  doc_type_ref_id: number;
  created_by: string;
  created_on: Date;
  reference: {
    label: string;
  };
}

export const getDocumentsByClientId = async (
  clientId: number,
  startIndex?: number,
  batchSize?: number
) => {
  if (clientId === 0) {
    return [] as ClientDocumentListType[];
  }
  const response = await internalConnection.get(
    `/documents/${clientId}/getDocumentsByClientId`,
    {
      params: {
        mapClientId: clientId,
        startIndex: defaultNullUndefined(startIndex, 0),
        batchSize: defaultNullUndefined(batchSize, 10),
      },
    }
  );
  return response.data.results as ClientDocumentListType[];
};
