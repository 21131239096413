/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import { useQuery } from "react-query";

/* --- MUI Imports --- */
import { Box, LinearProgress } from "@mui/material";
import {
  PaymentsDataset,
  billingKeys,
  getPayments,
} from "../../../apis/internalDb/billing/billing";
import SuccessMessage from "../../../shared/ReusableComponents/SuccessMessage";
import DataRetrievalError from "../../../shared/ReusableComponents/DataRetrievalError";
import PaymentDataTable from "../paymentHistory/PaymentDataTable";

/* --- Project Imports --- */

interface PaymentHistoryProps {
  mapOrgToClientId: number;
  selectedClientId: number;
}

export default function PaymentHistoryParent({
  mapOrgToClientId,
  selectedClientId,
}: PaymentHistoryProps) {
  const PAGE_SIZE = [15, 50, 100];
  const [wasRefundSuccessful, setWasRefundSuccessful] =
    React.useState<boolean>(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });
  const [sortField, setSortField] = React.useState<string | undefined>(
    undefined
  );
  const [sortDirection, setSortDirection] = React.useState<string | undefined>(
    undefined
  );

  /* --- RefData --- */

  /* --- Handlers --- */
  const handleSuccessfulRefund = () => {
    setWasRefundSuccessful(true);
  };

  const handleSuccessfulRefundAlertClose = () => {
    setWasRefundSuccessful(false);
  };

  /* --- React Query --- */
  const {
    isLoading,
    data: transactionDetail,
    isError,
  } = useQuery<PaymentsDataset, Error>(
    billingKeys.transactionsByClient(mapOrgToClientId),
    () =>
      getPayments(
        { sortField, sortDirection, ...paginationModel },
        [mapOrgToClientId],
        null,
        selectedClientId
      )
  );

  if (isLoading) {
    return <LinearProgress />;
  } else if (transactionDetail === undefined || isError) {
    return <DataRetrievalError />;
  } else {
    return (
      <Box sx={{ paddingTop: 2 }}>
        {wasRefundSuccessful && (
          <SuccessMessage
            open={wasRefundSuccessful}
            onClose={handleSuccessfulRefundAlertClose}
            alertMessage={"Refund Successful!"}
          />
        )}
        <PaymentDataTable
          clientIds={[mapOrgToClientId]}
          typeIds={[]}
          selectedClientId={selectedClientId}
        />
      </Box>
    );
  }
}
