import { Dayjs } from "dayjs";
import internalConnection from "../internalConnection";

export interface LandingPageClinicalNotes {
  event_id: number;
  clinical_note_id: number;
  user_id: number;
  custom_notes_form_response_id: number;
  is_locked: boolean;
  start_datetime: Dayjs;
  action: string;
  client_id: number;
  event_client_id: number;
  fullname: string;
  event_type: number;
  checked_in: boolean;
  check_in_time: Dayjs;
  check_out_time: Dayjs;
  checked_out: boolean;
  service_location_id: number;
  room_id: number;
  signed_on: Dayjs;
  provider_fullname: string;
}

export const getLandingPageNotesByUserId = async (selectedClientId: number) => {
  const response = await internalConnection.get(
    `/clinicalNote/${selectedClientId}/getByClientId`,
    {
      params: { clientId: selectedClientId },
    }
  );
  return response.data.results as LandingPageClinicalNotes[];
};
