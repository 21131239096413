/* eslint-disable array-callback-return */

import {
  DateFilterProps,
  PagingInfoProps,
} from "../../../shared/globalConstants";
import internalConnection from "../internalConnection";

/* Utilities */
export interface EventClientDetailsType {
  map_org_to_client_id: number;
  map_org_to_client: {
    client: {
      first_name: string;
      last_name: string;
    };
  };
  event_client_id: number;
  is_cancelled: boolean;
  is_complete: boolean;
  checked_in: boolean;
  checked_in_location: string;
  check_in_time: Date;
  checked_out: boolean;
  checked_out_location: string;
  check_out_time: Date;
  minute_duration: number;
}

export interface EventStaffDetailsType {
  event_staff_id: number;
  is_billing_provider: boolean;
  user_id: number;
  app_user: {
    user_details: {
      first_name: string;
      last_name: string;
    }[];
  };
}

export interface EventServiceDetailsType {
  event_service_id: number;
  map_org_to_client_id: number;
  user_id: number;
  client_par_services_id: number;
  service_type_id: number;
  units: number;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  is_note_complete: boolean;
  clinical_note_id: number;
  service_location_ref_id: number;
  app_user: {
    user_details: {
      first_name: string;
      last_name: string;
    }[];
  };
  client_par_services: {
    client_case_id: number;
    end_date: Date;
    units_authorized: number;
    units_used: number;
  };
  service_type: {
    rate_type_id: number;
    timed_code_type_id: number;
    code: string;
    name: string;
  };
}

export interface EventDetailType {
  event_id: number;
  composite_id: string;
  hour_id: number;
  start_datetime: Date;
  duration_mins: number;
  event_type: number;
  event_other: string;
  office_hours_type: number;
  office_hours_other: string;
  service_location_id: number;
  room_id: number;
  event_repeat_schedule_id: number;
  reference_event_event_typeToreference: {
    label: string;
  };
  reference_event_office_hours_typeToreference: {
    label: string;
  };
  service_location: {
    name: string;
    service_location_ref_id: number;
  };
  service_location_room: {
    name: string;
    abbr_name: string;
  };
  event_client: EventClientDetailsType[];
  event_service: EventServiceDetailsType[];
  event_staff: EventStaffDetailsType[];
}

export const getEventNoteId = async (clientId: number, eventId: number) => {
  const response = await internalConnection.get("events/getEventNoteId", {
    params: {
      clientId: clientId,
      eventId: eventId,
    },
  });
  return response.data.results as EventDetailType;
};

export interface EventWarningType {
  event_staff_id: number;
  event_id: number;
  org_profile_id: number;
  composite_id: string | null;
  hour_id: Number | null;
  start_datetime: Date;
  duration_mins: number | null;
  event_type_label: string | null | undefined;
  event_type_id: number | null;
  event_other: string | null;
  map_org_to_client_id: number | null | undefined;
  event_client_id: number | undefined;
  checked_in: boolean | null | undefined;
  check_in_time: Date | null | undefined;
  checked_in_location: string | null | undefined;
  checked_out: boolean | null | undefined;
  check_out_time: Date | null | undefined;
  checked_out_location: string | null | undefined;
  client_fullname: string;
  user_id: number | null | undefined;
  fullname: string;
  calendar_hex_color: string | null | undefined;
  font_hex_color: string | null | undefined;
  is_active: boolean | null | undefined;
  clinical_note_id: number | null | undefined;
  is_clinical_note_locked: boolean | null | undefined;
  pending_intake: boolean;
  check_in_error: boolean;
  note_started_error: boolean;
  note_locked_error: boolean;
  authorization_error: boolean;
  event_repeat_schedule_id: number | null;
  is_cancelled: boolean;
  service_location_id: number | null | undefined;
  service_type_id: number | null | undefined;
  cancel_comment: string;
  service_name: string | null;
  status_ref_id: number;
  client_cancel_reason_ref_id: number | null;
  client_initials: string;
}

export const getEventWarningsByEventId = async (id: number) => {
  const response = await internalConnection.get("events/getWarningsById", {
    params: {
      id: id,
    },
  });
  return response.data.results as EventWarningType;
};

export interface EventsObject {
  id: string;
  eventTypeId: number;
  timeSlotId: string;
  hourId: number;
  title: string;
  eventColor: string;
  fontColor: string;
  duration: number;
  staff: string;
  clinical_note_id: number | null;
  is_clinical_note_locked: boolean;
  pending_intake: boolean;
  userId: number;
  priority: number;
  numberOfEventsInTimeSlot: number;
  shape: EventShape;
  percentOfRemainingSpaceToUse: number;
  previouslyUsedSpace: number;
  eventsAfterThisOne: number;
  numOfConcurrentCollisions: number;
  check_in_error: boolean;
  note_started_error: boolean;
  note_locked_error: boolean;
  authorization_error: boolean;
  client_fullname: string;
  event_repeat_schedule_id: number;
  is_cancelled: boolean;
  map_org_to_client_id: number;
  client_initials: string;
}

export interface EventTreeObject {
  [id: number]: EventsObject[];
}

export interface EventShapeDimension {
  x: number;
  y: number;
  z: number;
}

export interface EventShape {
  eventIdx: number;
  collisionIndexes: Map<number, EventShapeDimension>;
  collisionValues: Map<EventShapeDimension, number>;
  collisionEvents: Map<number, EventShape>;
  size: EventShapeDimension;
  start: EventShapeDimension;
  end: EventShapeDimension;
}

export interface EventHistoryDataset {
  data: EventHistory[];
  totalCount: number;
}

export interface EventHistory {
  event_client_id: number;
  event_id: number;
  client_full_name: string;
  start_datetime: Date;
  service_location_ref_id: number;
  cancel_reason_ref_id: number;
  cancel_comment: string;
  event_type_id: number;
  event_type_label: string;
  status_ref_id: number;
  event_status_label: string;
  is_rescheduled: boolean;
  is_placeholder: boolean;
  duration: number;
  event_staff: {
    user_first_name: string;
    user_last_name: string;
    is_billing_provider: boolean;
  }[];
  event_service: {
    map_org_to_client_id: true;
    code: string;
    name: string;
    clinical_note_id: number | null;
  }[];
}

export const getEventHistory = async (
  clientId: number,
  dateFilterModel: DateFilterProps,
  pagingInfo: PagingInfoProps
) => {
  const response = await internalConnection.get("events/getEventHistory", {
    params: {
      clientId: clientId,
      startDate: dateFilterModel.startDate?.format(),
      endDate: dateFilterModel.endDate?.format(),
      startIndex: pagingInfo.page * pagingInfo.pageSize,
      batchSize: pagingInfo.pageSize,
      sortField: pagingInfo.sortField,
      sortDirection: pagingInfo.sortDirection,
    },
  });
  return response.data.results as EventHistoryDataset;
};

export const eventKey = {
  all: ["events"] as const,
  events: () => [...eventKey.all, "details"] as const,
  event: (clientId: number, eventId: number) =>
    [...eventKey.events(), clientId, eventId] as const,
  eventRepeatSchedule: (eventRepeatScheduleId: number) =>
    [...eventKey.all, "eventReschedule", eventRepeatScheduleId] as const,
  eventView: (
    selectedStaffFilter: number[],
    startDatetime: Date,
    endDatetime: Date,
    orgProfileId: number
  ) => [
    ...eventKey.all,
    selectedStaffFilter,
    startDatetime,
    endDatetime,
    orgProfileId,
  ],
  eventsByClient: (mapOrgToClientId: number) => [
    ...eventKey.all,
    mapOrgToClientId,
  ],
  eventDay: (
    selectedStaffFilter: number[],
    startDatetime: Date,
    endDatetime: Date,
    orgProfileId: number
  ) => [
    ...eventKey.all,
    selectedStaffFilter,
    startDatetime,
    endDatetime,
    orgProfileId,
  ],
  eventWarnings: (eventId: number) =>
    [...eventKey.events(), eventId, "warnings"] as const,
  eventHistoryTable: (
    clientId: number,
    dateFilterModel: DateFilterProps,
    caseId: number | undefined,
    statuses: number[] | undefined,
    pagingInfo: PagingInfoProps,
    staffIds?: number[]
  ) =>
    [
      ...eventKey.events(),
      "history",
      clientId,
      dateFilterModel,
      caseId,
      statuses,
      pagingInfo,
      staffIds,
    ] as const,
};
