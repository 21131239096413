import { Box, Divider, Typography } from "@mui/material";
import React from "react";

interface ErrorMessageProps {
  errorTitle?: string;
  errorMessage?: string;
}

export default function ErrorMessage({
  errorTitle,
  errorMessage,
}: ErrorMessageProps) {
  return (
    <Box>
      <Typography variant="h3" color="error">
        {errorTitle == null ? "Error" : errorTitle}
      </Typography>
      <Divider />
      <Typography variant="body1">
        {errorMessage == null ? "There has been an error" : errorMessage}
      </Typography>
    </Box>
  );
}
