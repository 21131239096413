import * as React from "react";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";

/* --- Project Imports --- */
import {
  CommunicationPreferencesPut,
  updateCommunicationPreferences,
} from "../../apis/internalDb/personalInfo/personalInfoRoutes";
import { clientKeys } from "../../apis/internalDb/keyFactory";

/* --- MUI Imports --- */
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Stack,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";

/* --- Interfaces --- */
export interface ManageCommunicationPreferencesFormProps {
  communicationPreferences: CommunicationPreferencesFormValues;
  clientId: number;
}

export interface CommunicationPreferencesFormValues {
  contactViaEmail: boolean;
  contactViaPhone: boolean;
  contactViaText: boolean;
}

export default function ManageCommunicationPreferencesForm({
  communicationPreferences,
  clientId,
}: ManageCommunicationPreferencesFormProps) {
  const [fieldEditable, setFieldEditable] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [isServerError, setIsServerError] = React.useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = React.useState<
    string | undefined
  >("");

  /* --- Handlers ---*/
  const toggleEdit = () => {
    setFieldEditable(!fieldEditable);
  };

  const handleCancel = () => {
    formik.resetForm();
    toggleEdit();
  };

  const updateCommunicationPreferencesMutation = useMutation(
    updateCommunicationPreferences,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: clientKeys.all });
        handleSuccess();
      },
      onError: (error: any) => {
        setOpen(true);
        setIsServerError(true);
        setServerErrorMessage(error.response.data.results);
      },
    }
  );

  const formik = useFormik({
    initialValues: communicationPreferences,
    validationSchema: false,
    onSubmit: async (values) => {
      const communicationPreferencesPayload: CommunicationPreferencesPut = {
        contactViaEmail: values.contactViaEmail,
        contactViaPhone: values.contactViaPhone,
        contactViaText: values.contactViaText,
        clientId: clientId,
        userId: "",
      };
      updateCommunicationPreferencesMutation.mutate(
        communicationPreferencesPayload
      );
    },
  });

  const handleSuccess = () => {
    setOpen(true);
    toggleEdit();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={isServerError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {`${isServerError ? serverErrorMessage : "Data Saved!"} `}
          </Alert>
        </Snackbar>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box
          id="clientInfo"
          sx={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
            paddingLeft: "3rem",
            paddingRight: "3rem",
          }}
        >
          <Grid
            container
            direction="row"
            columns={{ xs: 8, sm: 12 }}
            rowSpacing={1}
          >
            <Grid xs={8} item>
              <Grid
                container
                direction="row"
                spacing={2}
                columns={{ xs: 3, sm: 6, md: 9 }}
              >
                <Grid item>
                  <Typography variant="h5">
                    Electronic Communication Consent Form
                  </Typography>
                </Grid>
                <Grid item></Grid>
                <Grid item>
                  <Typography variant="body1" display="inline-block">
                    I consent that the Company can provide their services and
                    communicate with me via mobile phone, messages, e-mail and
                    any kind of online communications, provided that these
                    comminications comply with privacy regulations.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    display="inline-block"
                    sx={{ fontWeight: "bold" }}
                  >
                    Appointment Reminders, Reschedules and Cancellations
                  </Typography>
                </Grid>

                <Grid item></Grid>
                <Grid item>
                  <Typography variant="body1" display="inline-block">
                    I understand that Company can reach me any time to remind me
                    of my appointments or let me know in case of any change
                    about my appointments. And I also understand that the
                    Company can employ and use a third-party automated system to
                    reach out me for the purpose of "confirm", "reschedule" or
                    "cancel".
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    display="inline-block"
                    sx={{ fontWeight: "bold" }}
                  >
                    Telemedicine Appointments
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" display="inline-block">
                    For telemedicine, I understand the appointments will be held
                    via electronic environments.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    display="inline-block"
                    sx={{ fontWeight: "bold" }}
                  >
                    Contact Information Change
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" display="inline-block">
                    I accept that I am responsible of notifying the Company when
                    my contact informations change.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    display="inline-block"
                    sx={{ fontWeight: "bold" }}
                  >
                    Consent Cancellations
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" display="inline-block">
                    I know that I can revoke this consent at any time by
                    contacting the Company.
                  </Typography>
                </Grid>
                <Grid container item>
                  <Grid xs={3} item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="contactViaEmail"
                          id="contactViaEmail"
                          disabled={!fieldEditable}
                          checked={formik.values.contactViaEmail}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Contact via email?"
                    />
                  </Grid>
                  <Grid xs={3} item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="contactViaPhone"
                          id="contactViaPhone"
                          disabled={!fieldEditable}
                          checked={formik.values.contactViaPhone}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Contact via phone?"
                    />
                  </Grid>
                  <Grid xs={3} item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="contactViaText"
                          id="contactViaText"
                          disabled={!fieldEditable}
                          checked={formik.values.contactViaText}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Contact via text?"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Stack direction="row" spacing={2} justifyContent={"flex-start"}>
                {fieldEditable && (
                  <Button
                    color="error"
                    autoFocus
                    onClick={() => {
                      formik.resetForm();
                      handleCancel();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {!fieldEditable && (
                  <Button
                    style={{
                      backgroundColor: "#FFC300",
                    }}
                    autoFocus
                    disabled={fieldEditable}
                    onClick={() => {
                      toggleEdit();
                    }}
                  >
                    Edit
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting || !fieldEditable}
                  type="submit"
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </form>
    </div>
  );
}
