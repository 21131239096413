import React from "react";

import { LinearProgress } from "@mui/material";
import { useQuery } from "react-query";
import { defaultNullUndefined } from "../../../shared/globalFunctions";
import {
  PaymentHistoryResponseType,
  billingKeys,
  getPaymentHistory,
} from "../../../apis/internalDb/billing/billing";
import {
  refDataKeys,
  getByRefType,
} from "../../../apis/internalDb/reference/reference/reference";
import DataRetrievalError from "../../../shared/ReusableComponents/DataRetrievalError";
import {
  PAYMENT_REF_TYPE,
  RefDataObjectInterface,
  RefDataInterface,
  indexRefType,
} from "../../../shared/resources/referenceConstants";
import ShowPaymentDetails from "./ShowPaymentDetails";

interface ShowPaymentDetailsParentProps {
  paymentId: number | null;
  invoiceId: number | null;
  selectedClientId: number;
  hideIfNoPayments?: boolean;
  hideIfNoWriteoffs?: boolean;
}

export default function ShowPaymentDetailsParent({
  paymentId,
  invoiceId,
  hideIfNoPayments,
  selectedClientId,
}: ShowPaymentDetailsParentProps) {
  const isHideIfNoPayments = defaultNullUndefined(hideIfNoPayments, false);
  /* --- React Query --- */
  const refDataTypes: string[] = [PAYMENT_REF_TYPE];
  const {
    isLoading: isPaymentInfoLoading,
    data: paymentInfo,
    isError,
  } = useQuery<PaymentHistoryResponseType, Error>(
    billingKeys.paymentHistoryData(paymentId, invoiceId),
    () => getPaymentHistory(paymentId, invoiceId, selectedClientId)
  );

  const {
    isLoading: isRefDataLoading,
    error: isRefDataError,
    data: referenceData,
  } = useQuery<RefDataObjectInterface, Error>(
    refDataKeys.list(refDataTypes),
    () => getByRefType(refDataTypes)
  );
  const paymentMethodRefData: RefDataInterface[] = indexRefType(
    referenceData,
    refDataTypes[0]
  );

  /* --- Handlers --- */

  /* --- Formik --- */

  if (isPaymentInfoLoading || isRefDataLoading) {
    <LinearProgress />;
  } else if (
    isHideIfNoPayments &&
    (paymentInfo === undefined || paymentInfo.totalCount === 0)
  ) {
    return <></>;
  } else if (paymentInfo === undefined || isError || isRefDataError) {
    return <DataRetrievalError />;
  } else {
    return (
      <ShowPaymentDetails
        paymentInfo={paymentInfo}
        paymentMethodRefData={paymentMethodRefData}
        historyByInvoice={paymentId === null ? true : false}
      />
    );
  }
  return <></>;
}
