import React from "react";
import dayjs, { Dayjs } from "dayjs";

/* --- MUI Imports --- */
import { Typography } from "@mui/material";

/* --- Project Imports --- */
import { EventDetailType } from "../../apis/internalDb/calendar/event";

interface EventSummaryHeaderProps {
  event: EventDetailType;

  locationName?: string;
  roomName?: string;
  eventEndDateTime: Dayjs;
}

export default function EventSummaryHeader({
  event,
  locationName,
  roomName,
  eventEndDateTime,
}: EventSummaryHeaderProps) {
  /* --- React Query --- */

  return (
    <>
      <Typography variant="h6">
        {dayjs(event.start_datetime).format("dddd, MMMM D h:mma")}
        {" - "}
        {eventEndDateTime.format("h:mma")}
      </Typography>
      {event.service_location_id !== null &&
        event.service_location_id !== undefined && (
          <Typography variant="body1">
            <strong>Location: </strong>
            {locationName}
            {event.room_id == null ? "" : " - " + roomName}
          </Typography>
        )}
    </>
  );
}
