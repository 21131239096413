import internalConnection from "../internalConnection";

export interface PortalAccessListType {
  client_id: number;
  map_org_to_client_id: number;
  org_profile_id: number;
  org_name: string;
  stripe_account_id: string;
  first_name: string;
  last_name: string;
  permissions: {
    can_access_portal: boolean;
    can_manage_documents: boolean;
    can_view_clinical_notes: boolean;
    can_view_billing: boolean;
  };
}

export const getAccessByContactId = async (email: string) => {
  const response = await internalConnection.get(
    "portalAccess/getAccessByEmail",
    {
      params: {
        email: email,
      },
    }
  );
  return response.data.results as PortalAccessListType[];
};

export const portalAccessKeys = {
  all: ["portalAccess"] as const,
  lists: () => [...portalAccessKeys.all, "list"] as const,
  list: (userEmail: string) =>
    [...portalAccessKeys.lists(), userEmail] as const,
  details: () => [...portalAccessKeys.all, "detail"] as const,
  detail: (userEmail: string) =>
    [...portalAccessKeys.details(), userEmail] as const,
};
