/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SignaturePad from "react-signature-canvas";

interface SignaturePadComponentProps {
  existingSignature?: string;
  onChange: (dataURL: string, index: number) => void;
  index: number;
  sigCanvas: any;
}

const SignaturePadComponent: React.FC<SignaturePadComponentProps> = ({
  existingSignature,
  onChange,
  index,
  sigCanvas,
}) => {
  useEffect(() => {
    if (sigCanvas.current) {
      // Load saved signature if available
      if (existingSignature && sigCanvas.current) {
        const img = new Image();
        img.src = existingSignature;
        img.onload = () => {
          sigCanvas.current.fromDataURL(existingSignature);
        };
      }
    }

    return () => {};
  }, [existingSignature]);

  const handleEnd = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      onChange(dataURL, index);
    }
  };

  return (
    <>
      <SignaturePad
        penColor="black"
        backgroundColor="rgba(0,0,0,0)" // Set transparent background
        canvasProps={{ height: 100, width: 600 }}
        ref={sigCanvas}
        onEnd={() => handleEnd()}
      />
    </>
  );
};

export default SignaturePadComponent;
