import * as React from "react";

/* --- MUI Imports --- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

/* --- Interfaces --- */
interface ConfirmationDialogProps {
  title: string;
  message: string;
  onCancel: Function;
  onConfirm: Function;
  isOpen: boolean;
}

export default function ConfirmationDialog({
  title,
  message,
  onCancel,
  onConfirm,
  isOpen,
}: ConfirmationDialogProps) {
  /* Handlers */
  const handleSuccess = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog open={isOpen}>
      <Grid container>
        <Typography sx={{ padding: "0.5rem" }} variant="h3">
          {title}
        </Typography>
      </Grid>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid sx={{ textAlign: "center" }} item>
            <Typography variant="body1">{message}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSuccess();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
