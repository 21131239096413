import React from "react";
import { MenuItem } from "@mui/material";
import { RefDataInterface } from "../resources/referenceConstants";

export default function SelectMenuItems(
  refData: RefDataInterface[],
  defaultValueString?: string
) {
  const menuItemsArray = [];
  menuItemsArray.push(
    <MenuItem key={"null"} value={-1}>
      <em style={{ color: "gray" }}>
        {defaultValueString === undefined ? "None" : defaultValueString}
      </em>
    </MenuItem>
  );
  refData?.map((item, index) => {
    menuItemsArray.push(
      <MenuItem key={index} value={item.ref_id.toString()}>
        {item.label}
      </MenuItem>
    );
    return 0;
  });
  return menuItemsArray;
}
