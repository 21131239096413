import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import DocumentsParent from "../documents/DocumentsParent";
import {
  CUSTOM_FORM_STATUS_NEW_ID,
  CUSTOM_FORM_STATUS_DRAFT_ID,
} from "../../shared/resources/referenceConstants";
import AppointmentsParent from "../appointments/AppointmentsParent";
import { useAppSelector } from "../../state/hooks";
import { selectPermissions } from "../../state/slices/access";

export default function LandingPage() {
  const permissions = useAppSelector(selectPermissions);
  return (
    <Box>
      <Grid container direction="row">
        <Grid xs={1} item></Grid>
        <Grid xs={10} item>
          {permissions?.can_manage_documents && (
            <>
              <Typography variant="h3">Documents to Complete</Typography>
              <DocumentsParent
                formResponseStatusIds={[
                  CUSTOM_FORM_STATUS_NEW_ID,
                  CUSTOM_FORM_STATUS_DRAFT_ID,
                ]}
                showHeader={false}
              />
              <Divider />
            </>
          )}
          <br />
          <AppointmentsParent />
        </Grid>
        <Grid xs={1} item></Grid>
      </Grid>
    </Box>
  );
}
