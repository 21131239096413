import { Alert, Snackbar } from "@mui/material";
import React from "react";

interface ErrorMessageProps {
  open: boolean;
  onClose: Function;
  alertMessage: string;
  autoHideDuration?: number;
}

export default function ErrorMessage({
  open,
  onClose,
  alertMessage,
  autoHideDuration,
}: ErrorMessageProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration ? autoHideDuration : 3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}
