import React from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import theme from '.';

interface ThemeCustomizationProps {
  children: JSX.Element
}

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
  const themes = createTheme(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}