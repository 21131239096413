import internalConnection from "../internalConnection";

export interface PreLoginDetailsObject {
  email: string;
}

export interface LoginDetailsObject {
  email: string;
  accessCode: string;
}

export const preLoginDetails = async (preloginDetails: PreLoginDetailsObject) => {
  return await internalConnection.post("auth/prelogin", preloginDetails);
};

export const loginDetails = async (loginDetails: LoginDetailsObject) => {
  return await internalConnection.post("auth/login", loginDetails);
};

export const checkAuth = async () => {
  return await internalConnection.get("auth/check");
};

export const logout = async () => {
  return await internalConnection.get("auth/logout");
};

export const loginDetailsKeys = ["auth/login", "auth/check", "auth/prelogin"];
