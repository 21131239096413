/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
/* --- MUI Imports --- */
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IconButton,
  Box,
  Tooltip,
  LinearProgress,
  Grid,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
/* --- Project Imports --- */
import { defaultNullUndefined } from "../../shared/globalFunctions";
import EmptyDataTableMessage from "../../shared/ReusableComponents/EmptyDataTableMessage";
import DataRetrievalError from "../../shared/ReusableComponents/DataRetrievalError";
import { clientSubDataKeys } from "../../apis/internalDb/keyFactory";
import {
  getLandingPageNotesByUserId,
  LandingPageClinicalNotes,
} from "../../apis/internalDb/notes/notes";
import NoteDialog from "./NoteDialog";
import { AxiosError } from "../../apis/internalDb/billing/billing";

interface NotesDataTableProps {
  clientId: number;
}

export default function NotesDataTable({ clientId }: NotesDataTableProps) {
  const [eventId, setEventId] = React.useState<number>(-1);
  const [showNoteDialog, setShowNoteDialog] = React.useState<boolean>(false);
  const PAGE_SIZE = [15, 50, 100];
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });
  const [sortField, setSortField] = React.useState<string | undefined>(
    undefined
  );
  const [sortDirection, setSortDirection] = React.useState<string | undefined>(
    undefined
  );

  /* --- Handlers ---*/
  const handleViewNote = (eventId: number) => {
    setEventId(eventId);
    setShowNoteDialog(true);
  };

  const handleCloseDialog = () => {
    setEventId(-1);
    setShowNoteDialog(false);
  };

  /* --- React Query ---*/
  const {
    isLoading: areNotesLoading,
    isError: areNotesError,
    data: notesData,
    error: notesError,
  } = useQuery<LandingPageClinicalNotes[], AxiosError>(
    clientSubDataKeys.note(clientId),
    () => getLandingPageNotesByUserId(clientId)
  );

  const defaultList: LandingPageClinicalNotes[] = [];
  const rows = defaultNullUndefined(notesData, defaultList);

  const columns: GridColDef[] = [
    {
      field: "start_datetime",
      headerName: "Date",
      sortable: false,
      hideable: false,
      maxWidth: 300,
      flex: 1,
      valueGetter: (params) => {
        return dayjs(params.row?.start_datetime).format("MMMM D, YYYY h:mm A");
      },
    },

    {
      field: "event_type_label",
      headerName: "Event Type",
      sortable: false,
      hideable: false,
      maxWidth: 300,
      flex: 1,
    },
    {
      field: "provider_fullname",
      headerName: "Provider",
      sortable: false,
      hideable: false,
      flex: 1,
      maxWidth: 200,
    },

    {
      field: " ",
      headerName: "",
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View Note">
            <div>
              <IconButton onClick={(e) => handleViewNote(params.row.event_id)}>
                <AssignmentIcon />
              </IconButton>
            </div>
          </Tooltip>
        </>
      ),
      flex: 1,
      maxWidth: 200,
    },
  ];

  if (areNotesLoading) {
    return <LinearProgress />;
  } else if (areNotesError || notesData === undefined) {
    return (
      <DataRetrievalError
        additionalMessage={notesError?.response?.data?.message}
        showDefaultMessage={false}
      />
    );
  } else {
    return (
      <>
        {notesData.length === 0 ? (
          <EmptyDataTableMessage message={"No Notes Exist!"} />
        ) : (
          <Box>
            <Box sx={{ width: "100%", pt: 2 }}>
              <DataGrid
                sx={{ fontSize: "medium" }}
                rows={rows}
                columns={columns}
                pageSizeOptions={[10]}
                getRowId={(row) => row.event_id}
                checkboxSelection={false}
              />
            </Box>
          </Box>
        )}

        {showNoteDialog && (
          <NoteDialog
            clientId={clientId}
            eventId={eventId}
            onCloseNote={handleCloseDialog}
          />
        )}
      </>
    );
  }
}
