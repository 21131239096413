import * as React from "react";

/* --- MUI Imports --- */
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

/* --- Project Imports --- */
import { baseUrl } from "../../../apis/internalDb/internalConnection";

import GeneratedPDF from "./GeneratedPDF";
import ModalHeader from "../../../shared/ReusableComponents/ModalHeader";

interface GeneratedPDFParentProps {
  documentId: number;
  clientId: number;
  onCancel: Function;
  showDialog: boolean;
}

function generateClientDocumentUrl(
  clientId: number,
  customFormResponseId: number
): string {
  const rootURL = `${baseUrl}customForm/${clientId}/generateDocument`;
  const params = new URLSearchParams();

  // Add other parameters
  params.append("customFormResponseId", customFormResponseId.toString());

  // Construct the final URL
  return `${rootURL}?${params.toString()}`;
}

export default function GeneratedPDFParent({
  clientId,
  onCancel,
  documentId,
  showDialog,
}: GeneratedPDFParentProps) {
  /* --- Handlers --- */
  const handleCancel = () => {
    onCancel();
  };

  /* --- React Query --- */
  return (
    <Dialog
      open={showDialog}
      sx={{
        "& .MuiDialog-paper": { width: "75%", maxHeight: "90%", p: 0 },
      }}
      maxWidth="lg"
    >
      <ModalHeader headerText={""} onClose={handleCancel} />
      <DialogContent>
        <GeneratedPDF url={generateClientDocumentUrl(clientId, documentId)} />
      </DialogContent>

      <DialogActions>
        <Button color="error" onClick={handleCancel} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
