import internalConnection from "../internalConnection";
import {
  DateFilterProps,
  PagingInfoProps,
} from "../../../shared/globalConstants";
import { defaultNullUndefined } from "../../../shared/globalFunctions";

export interface InvoiceClient {
  first_name: string;
  last_name: string;
}

export interface InvoicesQueryResponse {
  invoice_id: number;
  display_id: string;
  map_org_to_client_id: number;
  invoice_date: string;
  issued_date: string;
  due_date: string;
  client: InvoiceClient;
  is_paid: boolean;
  total_amount: number;
  total_client_amount_due: number;
  total_client_amount_paid: number;
  total_insurance_amount_due: number;
  total_insurance_amount_paid: number;
  total_client_written_off: number;
  total_insurance_written_off: number;
  total_due: number;
  is_active: boolean;
  fullname: string;
  auth_type_label: string;
  auth_type_ref_id: number;
  auth_party_id: number;
  auth_party_type: number;
  auth_party_label: string;
  secondary_auth_party_id: number;
  secondary_auth_type_label: string;
  version: number;
}

// custom error type to expose server message
export interface AxiosError extends Error {
  response?: {
    data?: {
      message?: string;
    };
  };
}

export const getInvoicesByClientIdHandler = async (
  selectedClientId: number
) => {
  try {
    const response = await internalConnection.get(
      `/billing/${selectedClientId}/getInvoicesByClientId`,
      {
        params: { clientId: selectedClientId },
      }
    );
    return response.data.results as InvoicesQueryResponse[];
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw error as AxiosError;
  }
};

export interface PaymentLineItem {
  payment_id: number;
  map_org_to_client_id: number;
  client_fullname: string;
  payment_date: string;
  amount: number;
  amount_applied: number;
  amount_remaining_to_apply: number;
  amount_refunded: number;
  note: string | null;
  payment_type_ref_id: number;
  payment_type_label: string;
  transaction_type_ref_id: number;
  transaction_type_label: string;
  is_active: boolean;
  stripe_payment_intent_id: string;
  stripe_payment_method_last_four: string;
}

export interface PaymentsDataset {
  data: PaymentLineItem[];
  totalCount: number;
}

export const getPayments = async (
  pagingInfo: PagingInfoProps,
  mapClientId: number[],
  transactionType: number[] | null,
  selectedClientId: number
) => {
  const response = await internalConnection.get(
    `/billing/${selectedClientId}/getPayments`,
    {
      params: {
        startIndex: pagingInfo.page * pagingInfo.pageSize,
        batchSize: pagingInfo.pageSize,
        sortField: pagingInfo.sortField,
        sortDirection: pagingInfo.sortDirection,
        mapClientId: mapClientId,
        transactionType: transactionType,
      },
    }
  );
  return response.data.results as PaymentsDataset;
};

export interface PaymentHistoryType {
  invoice_id: number;
  payment_id: number;
  amount: number;
  created_on: string;
  created_by: string;
  payment: { is_copay: boolean };
  updated_on: string;
}

export interface CollectPaymentInvoiceDataSet {
  data: InvoicesQueryResponse[];
  totalCount: number;
  accountCredit: number;
}

export interface PaymentDetailType {
  amount: number;
  payment_date: string;
  amount_applied: number;
  amount_refunded: number;
  amount_remaining_to_apply: number;
  payment_id: number;
  version: number;
  payment_type_ref_id: number;
  map_org_to_client_id: number;
  stripe_payment_intent_id: string;
  stripe_payment_method_last_four: string;
}

export interface PaymentHistoryResponseType {
  payment: PaymentDetailType;
  paymentHistory: PaymentHistoryType[];
  totalCount: number;
}

export const getPaymentHistory = async (
  paymentId: number | null,
  invoiceId: number | null,
  selectedClientId: number
) => {
  const response = await internalConnection.get(
    `/billing/${selectedClientId}/getPaymentHistory`,
    {
      params: {
        paymentId: paymentId,
        invoiceId: invoiceId,
      },
    }
  );
  return response.data.results as PaymentHistoryResponseType;
};

export const getOutstandingInvoiceData = async (
  pagingInfo: PagingInfoProps,
  mapClientId: number,
  dateFilters: DateFilterProps,
  orgProfileId: number,
  displayId: string,
  invoiceIds: number[],
  selectedClientId: number,
  fundingSourceLabel?: number,
  authPartyNameId?: number
) => {
  const response = await internalConnection.get(
    `/billing/${selectedClientId}/getOutstandingInvoiceData`,
    {
      params: {
        startIndex: pagingInfo.page * pagingInfo.pageSize,
        batchSize: pagingInfo.pageSize,
        sortField: pagingInfo.sortField,
        sortDirection: pagingInfo.sortDirection,
        mapClientId: mapClientId,
        startDate: dateFilters.startDate,
        endDate: dateFilters.endDate,
        displayId: displayId,
        fundingSourceId: defaultNullUndefined(fundingSourceLabel, null),
        authPartyNameId: authPartyNameId,
        orgProfileId: orgProfileId,
        invoiceIds: invoiceIds,
      },
    }
  );
  return response.data.results as CollectPaymentInvoiceDataSet;
};

export const getInvoicesByIdHandler = async (
  ids: number[],
  selectedClientId: number
) => {
  const response = await internalConnection.get(
    `/billing/${selectedClientId}/getInvoicesById`,
    {
      params: { ids: ids },
    }
  );
  return response.data.results as InvoicesQueryResponse[];
};

export interface InvoiceLineQueryItem {
  amount_due: string;
  auth_party_id: number;
  auth_type_label: string;
  secondary_auth_type_label: string;
  secondary_auth_party_id: number;
  client_amount_due: string;
  client_amount_paid: string;
  is_mins: boolean;
  client_write_off: string;
  display_units: string;
  event_date: string;
  event_service_id: number | null;
  insurance_amount_paid: string;
  insurance_amount_due: string;
  insurance_write_off: string;
  client_copay_due: string;
  client_copay_paid: string;
  invoice_id: number;
  invoice_line_id: number;
  location_code: string;
  location_display: string;
  location_label: string;

  is_active: boolean;

  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  product_id: number | null;
  rate: string;
  rate_type_id: number;
  service_location_ref_id: number;
  timed_code_type_id: number;
  units: number;
  provider_fullname: string;
  map_org_to_client_id: number;
  auth_party_type: number;
  invoice_item_type_ref_id: number;
  code: string;
  service_name: string;
  product_name: string;
  user_id: number;
  client_fullname: string;
  client_par_services_id: number;
  service_type_id: number | null;
  event_id: number;
}

export const getInvoiceDetailsByInvoiceId = async (
  id: number,
  selectedClientId: number
) => {
  const response = await internalConnection.get(
    `/billing/${selectedClientId}/getInvoiceDetailsByInvoiceId`,
    {
      params: { invoice_id: id },
    }
  );
  return response.data.results as InvoiceLineQueryItem[];
};

export interface BillableServiceLineItem {
  event_service_id: number;
  auth_party_id: number;
  auth_party_type: number;
  auth_party_label: string;
  secondary_auth_party_id: string;
  secondary_auth_type_label: string;
  auth_type_label: string;
  event_id: number;
  map_org_to_client_id: number;
  user_id: number | null;
  is_note_complete: boolean | null;
  invoice_id: number | null;
  client_par_services_id: number | null;
  service_type_id: number | null;
  service_location_ref_id: number | null;
  location_label: string | null;
  location_code: string | null;
  location_display: string | null;
  units: number;
  display_units: string;
  is_mins: boolean;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  start_datetime: string;
  code: string | null;
  name: string | null;
  rate: number;
  rate_type_id: number | null;
  timed_code_type_id: number | null;
  amount_due: number;
  client_amount_due: number;
  client_amount_paid: number;
  insurance_amount_due: number;
  insurance_amount_paid: number;
  client_write_off: number;
  insurance_write_off: number;
  client_fullname: string;
  provider_fullname: string | null;
  invoice_line_id: number | null;
}

export interface InvoiceDataSet {
  data: InvoicesQueryResponse[];
  totalCount: number;
  accountCredit: number;
}

export const getInvoiceData = async (
  pagingInfo: PagingInfoProps,
  mapClientId: number[],
  dateFilters: DateFilterProps,
  orgProfileId: number,
  displayId: string,

  fundingSourceLabel?: number,
  authPartyNameId?: number,
  flagFilter?: string
) => {
  const response = await internalConnection.get(
    `/billing/${mapClientId[0]}/getInvoiceData`,
    {
      params: {
        startIndex: pagingInfo.page * pagingInfo.pageSize,
        batchSize: pagingInfo.pageSize,
        sortField: pagingInfo.sortField,
        sortDirection: pagingInfo.sortDirection,
        mapClientId: mapClientId,
        startDate: dateFilters.startDate,
        endDate: dateFilters.endDate,
        displayId: displayId,
        fundingSourceId: defaultNullUndefined(fundingSourceLabel, null),
        authPartyNameId: authPartyNameId,
        flagFilter: flagFilter,
        orgProfileId: orgProfileId,
      },
    }
  );
  return response.data.results as InvoiceDataSet;
};

export const billingKeys = {
  all: ["billing"] as const,
  lists: () => [...billingKeys.all, "list"] as const,
  list: (filters: string) => [...billingKeys.lists(), { filters }] as const,
  details: () => [...billingKeys.all, "detail"] as const,
  detail: (id: number) => [...billingKeys.details(), id] as const,
  invoices: () => [...billingKeys.all, "invoice"] as const,
  invoiceTables: () => [...billingKeys.invoices(), "invoiceTable"] as const,
  invoiceTable: (
    pagingInfo: PagingInfoProps,
    mapClientId: number[],
    dateFilters: DateFilterProps,
    displayId: string,
    fundingSourceFilter?: number,
    secondaryFundingSourceFilter?: number,
    flagFilter?: string
  ) =>
    [
      ...billingKeys.invoiceTables(),
      pagingInfo,
      mapClientId,
      dateFilters,
      displayId,
      defaultNullUndefined(fundingSourceFilter, 0),
      secondaryFundingSourceFilter,
      flagFilter,
    ] as const,
  transactions: () => [...billingKeys.all, "transaction"] as const,
  transactionsByClient: (mapClientId: number) =>
    [...billingKeys.transactions(), mapClientId] as const,
  transactionTables: () =>
    [...billingKeys.transactions(), "transactionTable"] as const,
  transactionTable: (
    pagingInfo: PagingInfoProps,
    mapClientId: number[],
    types: number[]
  ) =>
    [...billingKeys.invoiceTables(), pagingInfo, mapClientId, types] as const,
  invoice: (ids: number[]) => [...billingKeys.invoices(), ids] as const,
  invoiceSummaryData: (ids: number[]) =>
    [...billingKeys.invoices(), ids, "invoiceSummary"] as const,
  invoiceVersion: (invoiceId: number) =>
    [...billingKeys.invoices(), invoiceId, "version"] as const,
  invoiceDetails: (ids: number[]) =>
    [...billingKeys.invoices(), "details", ids] as const,
  unbilledTables: () => [...billingKeys.all, "unbilledTable"] as const,
  unbilledTable: (
    pagingInfo: PagingInfoProps,
    mapClientId: number[],
    orgProfileId?: number
  ) =>
    [
      ...billingKeys.unbilledTables(),
      pagingInfo,
      mapClientId,
      orgProfileId,
    ] as const,
  landingPageList: (clientId: number, startIndex: number, batchSize: number) =>
    [...billingKeys.lists(), clientId, startIndex, batchSize] as const,
  unappliedPayments: (pagingInfo: PagingInfoProps, mapClientId: number[]) =>
    [...billingKeys.all, pagingInfo, mapClientId, "unappliedPayments"] as const,
  accountCredit: (clientId: number) =>
    [...billingKeys.all, "credit", clientId] as const,
  outStandingInvoicesByClient: (clientId: number, invoiceIds: number[]) =>
    [...billingKeys.all, "outstanding", clientId, invoiceIds] as const,
  writeOffInvoicesByClient: (clientId: number, invoiceIds: number[]) =>
    [...billingKeys.all, "writeoff", clientId, invoiceIds] as const,
  refundData: (paymentId: number) =>
    [...billingKeys.all, "refund", paymentId] as const,
  paymentHistoryData: (paymentId: number | null, invoiceId: number | null) =>
    [...billingKeys.all, "paymentHistory", paymentId, invoiceId] as const,
  writeoffHistoryData: (invoiceId: number) =>
    [...billingKeys.all, "writeoffHistory", invoiceId] as const,
};
