export const clientKeys = {
  all: ["client"] as const,
  lists: () => [...clientKeys.all, "list"] as const,
  list: (filters: string) => [...clientKeys.lists(), { filters }] as const,
  details: () => [...clientKeys.all, "detail"] as const,
  detail: (id: number) => [...clientKeys.details(), id] as const,
  picker: (searchTerm: string, statusIds: number[], ids: number[]) => [
    ...clientKeys.all,
    "clientPicker",
    searchTerm,
    statusIds,
    ids,
  ],
};

export const clientSubDataKeys = {
  all: ["clientSubData"] as const,
  contacts: () => [...clientSubDataKeys.all, "contacts"] as const,
  contact: (id: number) => [...clientSubDataKeys.contacts(), { id }] as const,
  contactPicker: (searchTerm: string) =>
    [...clientSubDataKeys.contacts(), "picker", searchTerm] as const,
  physicians: () => [...clientSubDataKeys.all, "physician"] as const,
  physician: (id: number) => [...clientSubDataKeys.physicians(), id] as const,
  billings: () => [...clientSubDataKeys.all, "billing"] as const,
  billing: (id: number) => [...clientSubDataKeys.billings(), id] as const,
  notes: () => [...clientSubDataKeys.all, "note"] as const,
  note: (id: number) => [...clientSubDataKeys.notes(), id] as const,
  noteslist: (
    id: number,
    noteTypeIds: number[],
    noteParentId: number | undefined
  ) => [...clientSubDataKeys.notes(), id, noteTypeIds, noteParentId] as const,
  appointments: () => [...clientSubDataKeys.all, "appointment"] as const,
  appointment: (id: number) =>
    [...clientSubDataKeys.appointments(), id] as const,
};

export const clientDocumentKeys = {
  all: ["clientDocument"] as const,
  lists: () => [...clientDocumentKeys.all, "list"] as const,
  list: (clientId: number, startIndex: number, batchSize: number) =>
    [...clientDocumentKeys.lists(), clientId, startIndex, batchSize] as const,
  details: () => [...clientDocumentKeys.all, "detail"] as const,
  detail: (id: number) => [...clientDocumentKeys.details(), id] as const,
};

export const clientBillingKeys = {
  all: ["clientBilling"] as const,
  lists: () => [...clientBillingKeys.all, "list"] as const,
  list: (clientId: number, startIndex: number, batchSize: number) =>
    [...clientBillingKeys.lists(), clientId, startIndex, batchSize] as const,
};
