/* eslint-disable array-callback-return */
import React from "react";
import dayjs from "dayjs";

/* MUI Imports */
import {
  Box,
  TextField,
  Typography,
  Divider,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Button,
  LinearProgress,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Selection from "../../../shared/ReusableComponents/Selection";
import {
  clientDataFields,
  FieldConfig,
  fieldTypes,
} from "../../../shared/DynamicFormUtils";
import {
  CLIENT_RACE_REF_TYPE,
  GENDER_ID_REF_TYPE,
  indexRefType,
  RefDataInterface,
  RefDataObjectInterface,
  US_STATE_REF_TYPE,
} from "../../../shared/resources/referenceConstants";
import { useQuery } from "react-query";
import {
  getByRefType,
  refDataKeys,
} from "../../../apis/internalDb/reference/reference/reference";
import DataRetrievalError from "../../../shared/ReusableComponents/DataRetrievalError";
import {
  defaultNullUndefined,
  parseIntSafe,
} from "../../../shared/globalFunctions";
import RichTextDisplayField from "../../../shared/ReusableComponents/RichTextDisplayField";
import ActionLink from "../../../shared/ReusableComponents/ActionLink";
import SignaturePadComponent from "../../../shared/ReusableComponents/SignaturePadComponent";

/* Project Imports */

interface DisplayIntakeFieldProps {
  isDisabled: boolean;
  index: number;
  fieldConfig: FieldConfig;
  fieldValue?: string;
  isError: boolean;
  errorMessage: string | string[];
  handleChange: Function;
  birthday?: string;
}

export interface ClientNameValues {
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface AddressValues {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface BillingInfoValues {
  responsibleParty: string;
  billingPhoneNumber: string;
  billingEmail: string;
  insuranceNumber: string;
}

export interface CreditCardInfoValues {
  cardNumber: number;
  expirationDate: string;
  csv: number;
}

export default function DisplayIntakeField({
  isDisabled,
  index,
  fieldConfig,
  fieldValue,
  isError,
  errorMessage,
  handleChange,
  birthday,
}: DisplayIntakeFieldProps) {
  const sigCanvas: any = React.useRef({});
  const refDataTypes: string[] = [
    GENDER_ID_REF_TYPE,
    CLIENT_RACE_REF_TYPE,
    US_STATE_REF_TYPE,
  ];

  const {
    isLoading,
    isError: isRefError,
    data: referenceData,
  } = useQuery<RefDataObjectInterface, Error>(
    refDataKeys.list(refDataTypes),
    () => getByRefType(refDataTypes)
  );

  const clearSignaturePad = () => {
    sigCanvas.current.clear();
  };

  const genderIdRefData: RefDataInterface[] = indexRefType(
    referenceData,
    refDataTypes[0]
  );
  const raceIdRefData: RefDataInterface[] = indexRefType(
    referenceData,
    refDataTypes[1]
  );
  const stateIdRefData: RefDataInterface[] = indexRefType(
    referenceData,
    refDataTypes[2]
  );
  if (isLoading) {
    return <LinearProgress />;
  } else if (referenceData === undefined) {
    return <DataRetrievalError />;
  } else if (isRefError) {
    return <DataRetrievalError />;
  } else {
    switch (fieldConfig.fieldType.id) {
      case fieldTypes[0].id:
        // Title
        return <Typography variant="h3">{fieldConfig.field_label}</Typography>;
      case fieldTypes[1].id:
        // Text Response
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ md: 11, sm: 11 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">
                    {fieldConfig.is_required ? "* " : ""}
                    {fieldConfig.field_label}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={
                      fieldConfig?.max_char == null
                        ? 1
                        : fieldConfig.max_char >= 500
                        ? 3
                        : 1
                    }
                    variant="outlined"
                    label=""
                    disabled={isDisabled}
                    sx={{ background: "#fff" }}
                    value={fieldValue}
                    error={isError}
                    onChange={(e) => {
                      let textValue = defaultNullUndefined(e.target.value, "");
                      handleChange(textValue, index);
                    }}
                  />
                  {isError && (
                    <FormHelperText error={isError}>
                      {defaultNullUndefined(errorMessage, "error")}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        );
      case fieldTypes[2].id:
        // Paragraph
        const reactParagraphValue = (
          <RichTextDisplayField value={fieldConfig.field_label} />
        );
        return <Box sx={{ whiteSpace: "pre-wrap" }}>{reactParagraphValue}</Box>;
      case fieldTypes[3].id:
        const selectedArray = fieldConfig.custom_form_field_options.map((o) =>
          defaultNullUndefined(o.selected, false)
        );
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 11, md: 12 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">
                    {fieldConfig.is_required ? "* " : ""}
                    {fieldConfig.field_label}
                  </Typography>
                  <FormControl
                    sx={{ m: 1, ml: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      {fieldConfig.custom_form_field_options.map(
                        (option, checkIndex) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={option.selected}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean
                                ) => {
                                  let tempSelectedArray = [...selectedArray];
                                  tempSelectedArray[checkIndex] = checked;
                                  let string = "";

                                  fieldConfig.custom_form_field_options.map(
                                    (o2, i2) => {
                                      let newString = tempSelectedArray[i2]
                                        ? string.length === 0
                                          ? o2.label
                                          : `, ${o2.label}`
                                        : "";
                                      string = string.concat(newString);
                                    }
                                  );

                                  handleChange(
                                    string,
                                    index,
                                    tempSelectedArray
                                  );
                                }}
                                disabled={isDisabled}
                              />
                            }
                            label={option.label}
                          />
                        )
                      )}
                    </FormGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        );
      case fieldTypes[4].id:
        // Dropdown
        let refData: RefDataInterface[] = [];
        let ddValue: number = -1;
        fieldConfig.custom_form_field_options.map((option, index) => {
          if (option.selected === true) {
            ddValue = index;
          }
          refData.push({
            ref_id: index,
            label: option.label,
            label_abbr: "",
            ref_type: "dropdown",
          });
        });
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 11, md: 12 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">
                    {fieldConfig.field_label}
                  </Typography>
                  <Selection
                    label=""
                    name={fieldConfig.id}
                    error={false}
                    useRef={true}
                    refMenuOptions={refData}
                    background="#fff"
                    value={ddValue}
                    handleChange={(e: SelectChangeEvent<number | string>) => {
                      let newRefId: number =
                        typeof e.target.value === "string"
                          ? parseIntSafe(e.target.value, -1)
                          : e.target.value;
                      let string: string = "None";
                      let tempSelectedArray =
                        fieldConfig.custom_form_field_options.map(
                          (option, index) => {
                            if (index === newRefId) {
                              string = option.label;
                              return true;
                            } else {
                              return false;
                            }
                          }
                        );
                      handleChange(string, index, tempSelectedArray);
                    }}
                  />
                </Stack>
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage, "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case fieldTypes[5].id:
        // Date Field
        const value = dayjs(fieldValue);
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 6, md: 12 }}
              padding={2}
            >
              <Grid item sm={5}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">
                    {fieldConfig.field_label}
                  </Typography>
                  <DatePicker
                    value={value}
                    onChange={(value: any) => {
                      handleChange(value, index);
                    }}
                    disabled={isDisabled}
                  />
                </Stack>
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage, "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case fieldTypes[6].id:
        // Divider
        return <Divider />;
      case fieldTypes[7].id:
        // Signature
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 10, md: 12 }}
              padding={2}
              spacing={1}
            >
              <Grid item sm={10} md={12}>
                <Typography variant="subtitle1">Signature</Typography>
              </Grid>
              <Grid item sm={10}>
                <SignaturePadComponent
                  existingSignature={fieldValue}
                  index={index}
                  onChange={(data, index) => handleChange(data, index)}
                  sigCanvas={sigCanvas}
                />
                {!isDisabled && (
                  <ActionLink
                    text="Clear Signature"
                    action={clearSignaturePad}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[0].id:
        // Client Name
        let jsonObj: ClientNameValues;
        try {
          jsonObj = JSON.parse(defaultNullUndefined(fieldValue, ""));
        } catch (err) {
          jsonObj = {
            firstName: "",
            middleName: "",
            lastName: "",
          };
        }

        let firstName = defaultNullUndefined(jsonObj?.firstName, "");
        let middleName = defaultNullUndefined(jsonObj?.middleName, "");
        let lastName = defaultNullUndefined(jsonObj?.lastName, "");
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 10, md: 12 }}
              padding={2}
              spacing={1}
            >
              <Grid item sm={10} md={12}>
                <Typography variant="subtitle1">Client Name</Typography>
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={firstName}
                  variant="outlined"
                  label="First Name"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      firstName: textValue,
                      middleName: middleName,
                      lastName: lastName,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[0].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[0], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={2}>
                <TextField
                  fullWidth
                  value={middleName}
                  variant="outlined"
                  label="Middle Name"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      firstName: firstName,
                      middleName: textValue,
                      lastName: lastName,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[1].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[1], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={4}>
                <TextField
                  fullWidth
                  value={lastName}
                  variant="outlined"
                  label="Last Name"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      firstName: firstName,
                      middleName: middleName,
                      lastName: textValue,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[2].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[2], "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[1].id:
        // Client Email
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 11, md: 12 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">Client Email</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={fieldValue}
                    label=""
                    disabled={isDisabled}
                    sx={{ background: "#fff" }}
                    onChange={(e) => {
                      let textValue = defaultNullUndefined(e.target.value, "");
                      handleChange(textValue, index);
                    }}
                    error={isError}
                  />
                  {isError && (
                    <FormHelperText error={isError}>
                      {defaultNullUndefined(errorMessage, "error")}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[2].id:
        // Client Phone
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 11, md: 12 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">Client Phone #</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={fieldValue}
                    sx={{ background: "#fff" }}
                    label=""
                    disabled={isDisabled}
                    onChange={(e) => {
                      let textValue = defaultNullUndefined(e.target.value, "");
                      handleChange(textValue, index);
                    }}
                  />
                  {isError && (
                    <FormHelperText error={isError}>
                      {defaultNullUndefined(errorMessage, "error")}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[3].id:
        // Legal Gender
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 11, md: 12 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">Legal Gender</Typography>
                  <Selection
                    label=""
                    name={fieldConfig.id}
                    error={false}
                    useRef={true}
                    refMenuOptions={genderIdRefData}
                    background="#fff"
                    value={defaultNullUndefined(fieldValue, "")}
                    handleChange={(e: any) => {
                      handleChange(e.target.value, index);
                    }}
                    disabled={isDisabled}
                  />
                </Stack>
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage, "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[4].id:
        // Race / Ethnicity
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 11, md: 12 }}
              padding={2}
            >
              <Grid item sm={11}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">Race / Ethnicity</Typography>
                  <Selection
                    label=""
                    name={fieldConfig.id}
                    error={false}
                    useRef={true}
                    refMenuOptions={raceIdRefData}
                    background="#fff"
                    value={defaultNullUndefined(fieldValue, "")}
                    handleChange={(e: any) => {
                      handleChange(e.target.value, index);
                    }}
                    disabled={isDisabled}
                  />
                </Stack>
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage, "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[5].id:
        // Birth Date
        const value1 = dayjs(
          defaultNullUndefined(fieldValue === "" ? null : fieldValue, undefined)
        );
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 6, md: 12 }}
              padding={2}
            >
              <Grid item sm={5}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle1">Client Birthdate</Typography>
                  <DatePicker
                    value={value1}
                    onChange={(value: any) => {
                      handleChange(value, index);
                    }}
                    disabled={isDisabled}
                  />
                </Stack>
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage, "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[6].id:
        // Client Address
        let clientAddressJsonObj: AddressValues;
        try {
          clientAddressJsonObj = JSON.parse(
            defaultNullUndefined(fieldValue, "")
          );
        } catch (err) {
          clientAddressJsonObj = {
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipCode: "",
          };
        }

        let street1 = defaultNullUndefined(clientAddressJsonObj?.street1, "");
        let street2 = defaultNullUndefined(clientAddressJsonObj?.street2, "");
        let city = defaultNullUndefined(clientAddressJsonObj?.city, "");
        let state = defaultNullUndefined(clientAddressJsonObj?.state, "");
        let zipCode = defaultNullUndefined(clientAddressJsonObj?.zipCode, "");
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 12 }}
              padding={2}
              spacing={1}
            >
              <Grid item sm={12}>
                <Typography variant="subtitle1">Client Address</Typography>
              </Grid>
              <Grid item sm={5}>
                <TextField
                  fullWidth
                  value={street1}
                  variant="outlined"
                  label="Street 1"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: textValue,
                      street2: street2,
                      city: city,
                      state: state,
                      zipCode: zipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[0].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[0], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={city}
                  variant="outlined"
                  label="City"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: street1,
                      street2: street2,
                      city: textValue,
                      state: state,
                      zipCode: zipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[2].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[2], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <Selection
                  label="State"
                  name={fieldConfig.id}
                  error={false}
                  useRef={true}
                  refMenuOptions={stateIdRefData}
                  background="#fff"
                  value={defaultNullUndefined(state, "")}
                  disabled={isDisabled}
                  handleChange={(e: any) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: street1,
                      street2: street2,
                      city: city,
                      state: textValue,
                      zipCode: zipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[3], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={1}></Grid>
              <Grid item sm={5}>
                <TextField
                  fullWidth
                  value={street2}
                  variant="outlined"
                  label="Street 2"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: street1,
                      street2: textValue,
                      city: city,
                      state: state,
                      zipCode: zipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[1].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[1], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={zipCode}
                  variant="outlined"
                  label="Zip Code"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: street1,
                      street2: street2,
                      city: city,
                      state: state,
                      zipCode: textValue,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[4].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[4], "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[7].id:
        //billing info
        let billingInfoJsonObj: BillingInfoValues;
        try {
          billingInfoJsonObj = JSON.parse(defaultNullUndefined(fieldValue, ""));
        } catch (err) {
          billingInfoJsonObj = {
            responsibleParty: "",
            billingPhoneNumber: "",
            billingEmail: "",
            insuranceNumber: "",
          };
        }
        let responsibleParty = defaultNullUndefined(
          billingInfoJsonObj?.responsibleParty,
          ""
        );
        let billingPhoneNumber = defaultNullUndefined(
          billingInfoJsonObj?.billingPhoneNumber,
          ""
        );
        let billingEmail = defaultNullUndefined(
          billingInfoJsonObj?.billingEmail,
          ""
        );
        let insuranceNumber = defaultNullUndefined(
          billingInfoJsonObj?.insuranceNumber,
          ""
        );

        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 12 }}
              padding={2}
              spacing={1}
            >
              <Grid item sm={12}>
                <Typography variant="subtitle1">Billing Information</Typography>
              </Grid>
              <Grid item sm={5}>
                <TextField
                  fullWidth
                  value={responsibleParty}
                  variant="outlined"
                  label="Responsible Party"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      responsibleParty: textValue,
                      billingPhoneNumber: billingPhoneNumber,
                      billingEmail: billingEmail,
                      insuranceNumber: insuranceNumber,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={billingPhoneNumber}
                  variant="outlined"
                  label="Billing Phone Number"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      responsibleParty: responsibleParty,
                      billingPhoneNumber: textValue,
                      billingEmail: billingEmail,
                      insuranceNumber: insuranceNumber,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={billingEmail}
                  variant="outlined"
                  label="Billing Email"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      responsibleParty: responsibleParty,
                      billingPhoneNumber: billingPhoneNumber,
                      billingEmail: textValue,
                      insuranceNumber: insuranceNumber,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={insuranceNumber}
                  variant="outlined"
                  label="Insurance #"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      responsibleParty: responsibleParty,
                      billingPhoneNumber: billingPhoneNumber,
                      billingEmail: billingEmail,
                      insuranceNumber: textValue,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <Button variant="contained" component="label">
                  Upload License
                  <input hidden accept="image/*" multiple type="file" />
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[8].id:
        // Billing Address
        let billingAddressJsonObj: AddressValues;
        try {
          billingAddressJsonObj = JSON.parse(
            defaultNullUndefined(fieldValue, "")
          );
        } catch (err) {
          billingAddressJsonObj = {
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipCode: "",
          };
        }

        let billingStreet1 = defaultNullUndefined(
          billingAddressJsonObj?.street1,
          ""
        );
        let billingStreet2 = defaultNullUndefined(
          billingAddressJsonObj?.street2,
          ""
        );
        let billingCity = defaultNullUndefined(billingAddressJsonObj?.city, "");
        let billingState = defaultNullUndefined(
          billingAddressJsonObj?.state,
          ""
        );
        let billingZipCode = defaultNullUndefined(
          billingAddressJsonObj?.zipCode,
          ""
        );
        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 12 }}
              padding={2}
              spacing={1}
            >
              <Grid item sm={12}>
                <Typography variant="subtitle1">Billing Address</Typography>
              </Grid>
              <Grid item sm={5}>
                <TextField
                  fullWidth
                  value={billingStreet1}
                  variant="outlined"
                  label="Street 1"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: textValue,
                      street2: billingStreet2,
                      city: billingCity,
                      state: billingState,
                      zipCode: billingZipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[0].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[0], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={billingCity}
                  variant="outlined"
                  label="City"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: billingStreet1,
                      street2: billingStreet2,
                      city: textValue,
                      state: billingState,
                      zipCode: billingZipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[2].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[2], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <Selection
                  label="State"
                  name={fieldConfig.id}
                  error={false}
                  useRef={true}
                  refMenuOptions={stateIdRefData}
                  disabled={isDisabled}
                  background="#fff"
                  value={defaultNullUndefined(billingState, "")}
                  handleChange={(e: any) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: billingStreet1,
                      street2: billingStreet2,
                      city: billingCity,
                      state: textValue,
                      zipCode: billingZipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[3], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={1}></Grid>
              <Grid item sm={5}>
                <TextField
                  fullWidth
                  value={billingStreet2}
                  variant="outlined"
                  label="Street 2"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: billingStreet1,
                      street2: textValue,
                      city: billingCity,
                      state: billingState,
                      zipCode: billingZipCode,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[1].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[1], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={billingZipCode}
                  variant="outlined"
                  label="Zip Code"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      street1: billingStreet1,
                      street2: billingStreet2,
                      city: billingCity,
                      state: billingState,
                      zipCode: textValue,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[4].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[4], "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      case clientDataFields[9].id:
        // Credit card info
        let creditCardJsonObj: CreditCardInfoValues;
        try {
          creditCardJsonObj = JSON.parse(defaultNullUndefined(fieldValue, ""));
        } catch (err) {
          creditCardJsonObj = {
            cardNumber: 0,
            expirationDate: "",
            csv: 0,
          };
        }

        let cardNumber = defaultNullUndefined(
          creditCardJsonObj?.cardNumber,
          ""
        );
        let expirationDate = defaultNullUndefined(
          creditCardJsonObj?.expirationDate,
          ""
        );
        let csv = defaultNullUndefined(creditCardJsonObj?.csv, "");

        return (
          <Box sx={{ background: "#ECECEC" }}>
            <Grid
              container
              direction="row"
              columns={{ sm: 12 }}
              padding={2}
              spacing={1}
            >
              <Grid item sm={12}>
                <Typography variant="subtitle1">Credit Card Info</Typography>
              </Grid>
              <Grid item sm={5}>
                <TextField
                  fullWidth
                  value={cardNumber}
                  variant="outlined"
                  label="Credit Card Number"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      cardNumber: textValue,
                      expirationDate: expirationDate,
                      csv: csv,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[0].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[0], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={expirationDate}
                  variant="outlined"
                  label="Expiration Date"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      cardNumber: cardNumber,
                      expirationDate: textValue,
                      csv: csv,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[1].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[1], "error")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={3}>
                <TextField
                  fullWidth
                  value={csv}
                  variant="outlined"
                  label="CSV"
                  disabled={isDisabled}
                  sx={{ background: "#fff" }}
                  onChange={(e) => {
                    let textValue = defaultNullUndefined(e.target.value, "");
                    let tempObj = {
                      cardNumber: cardNumber,
                      expirationDate: expirationDate,
                      csv: textValue,
                    };
                    handleChange(JSON.stringify(tempObj), index);
                  }}
                  error={isError && errorMessage[2].length > 0}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {defaultNullUndefined(errorMessage[2], "error")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return <></>;
    }
  }
}
