/* --- Interfaces -- */
export interface RefDataInterface {
  ref_id: number;
  label: string;
  label_abbr: string;
  ref_type: string;
}

export interface RefDataObjectInterface {
  [refType: string]: RefDataInterface[];
}

/* --- Data Manipulation Functions --- */
export const indexRefType = (
  refData?: RefDataObjectInterface,
  refType?: string
) => {
  if (refData == null || refType == null) {
    return [] as RefDataInterface[];
  } else {
    return refData[refType] as RefDataInterface[];
  }
};

export const eventTypeToClientStatus = (eventTypeId: number) => {
  switch (eventTypeId) {
    case APPT_TYPE_GROUP_CLIENT_ID:
      return [CLIENT_STATUS_ACTIVE_ID];
    case APPT_TYPE_IND_CLIENT_ID:
      return [CLIENT_STATUS_ACTIVE_ID];
    case APPT_TYPE_INIT_CONSULT_ID:
      return [CLIENT_STATUS_PROSPECT_ID];
    default:
      return [];
  }
};

export let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  currencySign: "accounting",
});

/* --- Constants --- */
export const NO_SHOW_BILLING_REF_TYPE = "NO_SHOW_BILLING_TYPE";
export const NO_SHOW_BILLING_TYPE_FLAT_RATE_ID = 1;
export const NO_SHOW_BILLING_TYPE_PERCENT_RATE_ID = 2;
export const LATE_CANCEL_BILLING_REF_TYPE = "LATE_CANCEL_BILLING_TYPE";
export const LATE_CANCEL_BILLING_TYPE_FLAT_RATE_ID = 4;
export const LATE_CANCEL_BILLING_TYPE_PERCENT_RATE_ID = 5;
export const INVOICE_TYPE_REF_TYPE = "INVOICE_TYPE";
export const CONTACT_TYPE_REF_TYPE = "CLIENT_CONTACT_TYPE";
export const CLIENT_STATUS_REF_TYPE = "CLIENT_STATUS_TYPE";
export const CLIENT_STATUS_PROSPECT_ID = 16;
export const CLIENT_STATUS_ACTIVE_ID = 17;
export const CLIENT_STATUS_ARCHIVED_ID = 18;
export const CLIENT_STATUS_PENDING_INITIAL_CONSULT_ID = 19;
export const ALL_CLIENT_ID = 0;
export const US_STATE_REF_TYPE = "US_STATE";
export const GENDER_ID_REF_TYPE = "GENDER_IDENTITY";
export const PAY_TYPE_REF_TYPE = "PAY_TYPE";
export const CLIENT_RACE_REF_TYPE = "CLIENT_RACE";
export const APPT_TYPE_REF_TYPE = "APPT_TYPE";
export const APPT_TYPE_OTHER_ID = 97;
export const APPT_TYPE_OFFICE_HOURS_ID = 91;
export const APPT_TYPE_GROUP_CLIENT_ID = 90;
export const APPT_TYPE_IND_CLIENT_ID = 89;
export const APPT_TYPE_INIT_CONSULT_ID = 110;
export const OFFICE_HOURS_REF_TYPE = "OFFICE_HOURS_TYPE";
export const OFFICE_HOURS_OTHER_ID = 104;
export const SERVICE_RATE_REF_TYPE = "SERVICE_RATE_TYPE";
export const SERVICE_RATE_TIMED_CODE_ID = 111;
export const SERVICE_RATE_ITEM_ID = 113;
export const TIMED_CODE_REF_TYPE = "TIMED_CODE_TYPE";
export const TIMED_CODE_HOUR_ID = 115;
export const TIMED_CODE_MINUTES_ID = 116;
export const LEGAL_GENDER_TYPE = "LEGAL_GENDER";
export const NOTE_REF_TYPE = "NOTE_TYPE";
export const NOTE_TYPE_ADMIN_ID = 123;
export const NOTE_TYPE_BILLING_ID = 127;
export const NOTE_TYPE_CONTACT_ID = 124;
export const NOTE_PARENT_REF_TYPE = "NOTE_PARENT_TYPE";
export const CLIENT_STATUS_WAITLIST_ID = 129;
export const NOTE_PARENT_CUSTOM_NOTE_FORM = 136;
export const INITIAL_CONSULT_FORM_ID = 1;
export const INIT_CONSULT_NEXT_STEP_TYPE = "INIT_CONSULT_NEXT_STEP";
export const INIT_CONSULT_STEP_ACCEPT = 131;
export const INIT_CONSULT_STEP_REFER = 132;
export const INIT_CONSULT_STEP_WAITLIST = 133;
export const INIT_CONSULT_STEP_AWAIT = 134;
export const INIT_CONSULT_STEP_DNMF = 135;
export const DOC_TYPE_MISC_ID = 153;
export const DOC_TYPE_CLIENT_UPLOAD_ID = 228;
export const CUSTOM_FORM_STATUS_NEW_ID = 158;
export const CUSTOM_FORM_STATUS_DRAFT_ID = 159;
export const CUSTOM_FORM_STATUS_SUBMIT_ID = 160;
export const CUSTOM_FORM_STATUS_APPROVED_ID = 161;
export const DOC_TYPE_REF_TYPE = "DOCUMENT_TYPE";
export const PAYMENT_TYPE_CASH_ID = 162;
export const PAYMENT_TYPE_CHECK_ID = 163;
export const PAYMENT_TYPE_CARD_ID = 164;
export const PAYMENT_TYPE_OTHER_ID = 166;
export const PAYMENT_REF_TYPE = "PAYMENT_TYPE";
