import {
  Avatar,
  Box,
  Menu,
  Divider,
  Stack,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
interface MessagingParentProps {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: Function;
}

export default function MessagingParent({
  anchorEl,
  isOpen,
  onClose,
}: MessagingParentProps) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isOpen}
      onClose={handleClose}
      onClick={() => {}}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box minWidth="30rem" minHeight="30rem" padding={1}>
        <Stack direction="column" spacing={1} width={"100%"}>
          <Typography variant="h6">
            <strong>Secure Messages</strong>
          </Typography>
          <Divider />
          <MenuItem>
            <ListItemIcon>
              <Avatar />
            </ListItemIcon>
            <ListItemText>Dillon Manzanares</ListItemText>
          </MenuItem>
        </Stack>
      </Box>
    </Menu>
  );
}
