import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import accessReducer from "./slices/access";
import thunk from "redux-thunk";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    access: accessReducer,
  },
  middleware: [thunk] as const,
});

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
