import * as React from "react";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";

/* --- MUI Imports --- */
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  IconButton,
  Tooltip,
  LinearProgress,
  FormHelperText,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";

/* --- Project Imports --- */
import {
  DOC_TYPE_CLIENT_UPLOAD_ID,
  DOC_TYPE_REF_TYPE,
  RefDataObjectInterface,
} from "../../../shared/resources/referenceConstants";
import {
  getByRefType,
  refDataKeys,
} from "../../../apis/internalDb/reference/reference/reference";
import { clientDocumentKeys } from "../../../apis/internalDb/keyFactory";
import {
  AWSFilePost,
  postAwsFile,
} from "../../../apis/internalDb/awsFiles/files";
import { clientDocumentAddValidations } from "./validations/clientDocumentAddValidations";
import ErrorMessage from "../../../shared/ReusableComponents/ErrorMessage";
import ModalHeader from "../../../shared/ReusableComponents/ModalHeader";
import SubmissionError from "../../../shared/ReusableComponents/SubmissionError";

/* --- Interfaces --- */

interface ClientDocumentFormValues {
  file: File | null;
  fileName: string;
  comment: string;
}
interface ClientDocumentFormProps {
  clientId: number;
  onClose: Function;
  initialData?: ClientDocumentFormValues;
}

export default function ClientDocumentForm({
  clientId,
  onClose,
  initialData,
}: ClientDocumentFormProps) {
  const [isServerError, setIsServerError] = React.useState<boolean>(false);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = React.useState<
    string | undefined
  >("");
  const refDataTypes: string[] = [DOC_TYPE_REF_TYPE];

  /* --- React Query ---*/
  const {
    isLoading: isRefLoading,
    isError: isRefError,
    error: refError,
    data: referenceData,
  } = useQuery<RefDataObjectInterface, Error>(
    refDataKeys.list(refDataTypes),
    () => getByRefType(refDataTypes)
  );

  /* --- Handlers --- */
  const handleSuccess = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  /* --- React Query --- */
  const queryClient = useQueryClient();

  const postDocToAws = useMutation(postAwsFile, {
    onSuccess: (data) => {
      handleSuccess();
      queryClient.invalidateQueries({ queryKey: clientDocumentKeys.all });
      setIsServerError(false);
      setServerErrorMessage(undefined);
      setIsProcessing(false);
    },
    onError: (data: any) => {
      setIsProcessing(false);
      setIsServerError(true);
      setServerErrorMessage(data.response.data.results);
    },
  });

  /* --- Formik --- */
  const initialValues: ClientDocumentFormValues = {
    file: null,
    fileName: "",
    comment: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: clientDocumentAddValidations,
    onSubmit: async (values) => {
      if (values.file === null) return;
      try {
        setIsProcessing(true);
        const documentInfo: AWSFilePost = {
          file: values.file,
          clientId: clientId,
          docRefTypeId: DOC_TYPE_CLIENT_UPLOAD_ID,
          fileName: formik.values.fileName,
          comment: formik.values.comment,
        };

        postDocToAws.mutate(documentInfo);
      } catch (error) {
        console.error(error);
      }
    },
  });

  if (isRefLoading || referenceData === undefined) {
    return <LinearProgress />;
  } else if (isRefError) {
    return (
      <ErrorMessage errorTitle="Data Error" errorMessage={refError.message} />
    );
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        {formik.isSubmitting && <LinearProgress />}
        <ModalHeader headerText="New Document" onClose={handleCancel} />
        <DialogContent>
          {isProcessing && <LinearProgress />}
          {isServerError && (
            <SubmissionError
              showDefaultMessage={false}
              additionalMessage={serverErrorMessage}
            />
          )}
          <Box
            sx={{
              paddingBottom: "1rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <Grid
              container
              direction="row"
              columns={{ xs: 4, sm: 8 }}
              spacing={2}
            >
              <Grid item xs={4} sm={8}>
                <Button
                  startIcon={<FileUploadIcon />}
                  component="label"
                  variant="contained"
                  disabled={!(formik.values.file == null)}
                >
                  Upload Document
                  <input
                    type="file"
                    hidden
                    onChange={(event) => {
                      const file =
                        event.currentTarget.files === null
                          ? null
                          : event.currentTarget.files[0];
                      const fileName = file == null ? "" : file.name;
                      formik.setFieldValue("file", file);
                      formik.setFieldValue("fileName", fileName);
                    }}
                  />
                </Button>
                <FormHelperText error>{formik.errors.file}</FormHelperText>
                {!(formik.values.file == null) && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Tooltip title="Remove document">
                      <div>
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue("file", null);
                            formik.setFieldValue("fileName", "");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Typography variant="h6">
                      {formik.values.file?.name}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={4} sm={8}>
                <TextField
                  fullWidth
                  id="comment"
                  name="comment"
                  label="Comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comment && Boolean(formik.errors.comment)
                  }
                  helperText={formik.touched.comment && formik.errors.comment}
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            autoFocus
            color="error"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={formik.values.file == null || formik.isSubmitting}
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    );
  }
}
