import React from "react";

/* --- MUI Imports --- */
import { Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

/* --- Project Imports --- */
import {
  defaultNullUndefined,
  displayServiceUnitLabel,
} from "../../shared/globalFunctions";
import {
  EventClientDetailsType,
  EventServiceDetailsType,
} from "../../apis/internalDb/calendar/event";
import { APPT_TYPE_INIT_CONSULT_ID } from "../../shared/resources/referenceConstants";

interface UserPickerListType {
  user_id?: number;
  fullname: string;
}

interface EventSummaryClientServicesProps {
  staff: UserPickerListType[];
  clients: EventClientDetailsType[];
  services: EventServiceDetailsType[];
  eventType: number;
  hideClientName?: boolean;
}

export default function EventSummaryClientServices({
  staff,
  clients,
  services,
  hideClientName,
  eventType,
}: EventSummaryClientServicesProps) {
  const defaultServiceData: EventServiceDetailsType[] = [];
  const rows = defaultNullUndefined(services, defaultServiceData);

  function getRowClientName(params: GridValueGetterParams) {
    const clientId = params.row.client_id;
    const clientName = defaultNullUndefined(
      clients.filter((c) => c.map_org_to_client_id === clientId),
      [{ first_name: "", last_name: "" }]
    )[0];
    return `${clientName.client.first_name} ${clientName.client.last_name}`;
  }

  function getRowUserName(params: GridValueGetterParams): string {
    const userId = params.row.user_id;
    const userName = defaultNullUndefined(
      staff.filter((s) => s.user_id === userId),
      [{ fullname: "" }]
    )[0];
    return userName ? userName.fullname : "";
  }

  function getServiceName(params: GridValueGetterParams) {
    console.log({ message: "params", row: params.row });
    if (eventType === APPT_TYPE_INIT_CONSULT_ID) {
      return "Initial Consult";
    } else {
      const code = defaultNullUndefined(params.row.service_type.code, "");
      const name = defaultNullUndefined(params.row.service_type.name, "");
      return code + " - " + name;
    }
  }

  function getModifiers(params: GridValueGetterParams) {
    const modifier1 = defaultNullUndefined(params.row.modifier1, "  ");
    const modifier2 = defaultNullUndefined(params.row.modifier2, "  ");
    const modifier3 = defaultNullUndefined(params.row.modifier3, "  ");
    const modifier4 = defaultNullUndefined(params.row.modifier4, "  ");
    return `${modifier1}  ${modifier2}  ${modifier3}  ${modifier4}`;
  }

  function getUnitDisplayLabel(params: GridValueGetterParams) {
    const units = params.row.units;
    let rate_type_id: number = 0;
    let timed_code_type_id: number = 0;
    if (params.row.service_type !== null) {
      rate_type_id = params.row.service_type.rate_type_id;
      timed_code_type_id = params.row.service_type.timed_code_type_id;
    }

    return `${units} ${displayServiceUnitLabel(
      rate_type_id,
      timed_code_type_id,
      true
    ).toLowerCase()}`;
  }

  const columns: GridColDef[] = [
    {
      field: "client_id",
      headerName: "Client",
      valueGetter: getRowClientName,
      maxWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "service_type.name",
      headerName: "Service",
      valueGetter: getServiceName,
      maxWidth: 500,
      flex: 2,
      sortable: false,
      filterable: false,
    },
    {
      field: "units",
      headerName: "Quantity",
      valueGetter: getUnitDisplayLabel,
      maxWidth: 100,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "modifier1",
      headerName: "Modifier(s)",
      valueGetter: getModifiers,
      maxWidth: 150,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "user_id",
      headerName: "Provider",
      valueGetter: getRowUserName,
      maxWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      <Typography variant="h5">Client Services:</Typography>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        sx={{ fontSize: "medium" }}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.event_service_id}
        checkboxSelection={false}
        hideFooter={true}
        columnVisibilityModel={{
          client_id: !defaultNullUndefined(hideClientName, false),
        }}
      />
    </>
  );
}
