import React from "react";

/* --- MUI Imports --- */
import { Box, Button, Dialog, Grid, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

/* --- Project Imports --- */
import ClientDocumentDataTable from "./ClientDocumentDataTable";
import ClientDocumentAdd from "./ClientDocumentAdd";
import { defaultNullUndefined } from "../../../shared/globalFunctions";
import { useAppSelector } from "../../../state/hooks";
import { selectCurrentClient } from "../../../state/slices/auth";

export default function ClientDocumentParent() {
  const clientId = defaultNullUndefined(
    useAppSelector(selectCurrentClient)?.clientId,
    0
  );
  const [isAddNew, setIsAddNew] = React.useState(false);
  const [refreshCounter, setRefreshCounter] = React.useState<number>(0);
  const handleCreateNew = () => {
    setIsAddNew(true);
  };

  const handleClose = () => {
    setIsAddNew(false);
    setRefreshCounter(refreshCounter + 1);
  };

  return (
    <Box>
      <Grid container direction="row">
        <Grid item container direction="row">
          <Stack spacing={2} direction="row" sx={{ marginTop: 1 }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleCreateNew}
              startIcon={<AddCircleIcon />}
            >
              Add New Document
            </Button>
          </Stack>

          <ClientDocumentDataTable clientId={clientId} isArchived={false} />
          <Dialog
            sx={{
              "& .MuiDialog-paper": { width: "100%", maxHeight: "90%", p: 0 },
            }}
            maxWidth="md"
            open={isAddNew}
          >
            {isAddNew && (
              <ClientDocumentAdd
                isOpen={isAddNew}
                onClose={handleClose}
                clientId={clientId}
              />
            )}
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
}
