import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ThemeCustomization from "./themes/ThemeCustomization";
import ScrollToTop from "./shared/ScrollToTop";
import AppRoutes from "./routes";

function App() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <ThemeCustomization>
          <AppRoutes />
        </ThemeCustomization>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
