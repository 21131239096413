import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React from "react";

interface DataRetrievalErrorProps {
  additionalMessage?: string;
  showDefaultMessage?: boolean;
}

export default function DataRetrievalError({
  additionalMessage,
  showDefaultMessage = true,
}: DataRetrievalErrorProps) {
  return (
    <Box>
      <Alert severity="error">
        <AlertTitle>Data Retrieval Error</AlertTitle>
        {showDefaultMessage && (
          <Typography>
            There was an error retrieving data from the source system, please
            try again later.
          </Typography>
        )}
        {additionalMessage && (
          <Typography variant="body2">{additionalMessage}</Typography>
        )}
      </Alert>
    </Box>
  );
}
