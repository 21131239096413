/* eslint-disable no-empty-pattern */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";

/* --- MUI Imports --- */
import { Box, Breadcrumbs, Grid, LinearProgress } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";

/* --- Project Imports --- */
import {
  ClientPersonalInfoDetails,
  getClientPersonalInfo,
} from "../../apis/internalDb/personalInfo/personalInfoRoutes";
import { clientKeys } from "../../apis/internalDb/keyFactory";
import DataRetrievalError from "../../shared/ReusableComponents/DataRetrievalError";
import ManageCommunicationPreferencesForm, {
  CommunicationPreferencesFormValues,
} from "./ManageCommunicationPreferencesForm";
import {
  defaultNullUndefined,
  parseIntSafe,
} from "../../shared/globalFunctions";
import BreadcrumbChip from "../../shared/ReusableComponents/BreadcrumbChip";
import PageTitle from "../../shared/ReusableComponents/PageTitle";

interface ManageCommunicationPreferencesParentProps {}
export default function ManageCommunicationPreferencesParent({}: ManageCommunicationPreferencesParentProps) {
  const params = useParams();
  const clientId = parseIntSafe(params.clientId, 0);
  const {
    isLoading,
    isError,
    error,
    data: clientData,
  } = useQuery<ClientPersonalInfoDetails, Error>(
    clientKeys.detail(clientId),
    () => getClientPersonalInfo(clientId)
  );

  if (isLoading || clientData === undefined || clientId === 0) {
    return <LinearProgress />;
  } else if (isError) {
    return <DataRetrievalError additionalMessage={error.message} />;
  } else {
    const communicationPreferences: CommunicationPreferencesFormValues = {
      contactViaEmail: defaultNullUndefined(
        clientData.contact_via_email,
        false
      ),
      contactViaPhone: defaultNullUndefined(
        clientData.contact_via_phone,
        false
      ),
      contactViaText: defaultNullUndefined(clientData.contact_via_text, false),
    };
    return (
      <Box>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Breadcrumbs sx={{ mb: 1 }}>
              <Link to="/manage">
                <BreadcrumbChip label="Manage Account" icon={<GroupIcon />} />
              </Link>
              {/* <Link to={`/clients/${clientId}`}>
                <BreadcrumbChip
                  label={`${clientData.first_name} ${clientData.last_name}`}
                  icon={<PersonIcon />}
                />
              </Link> */}
              <BreadcrumbChip
                label={`Communication Preferences`}
                clickable={false}
              />
            </Breadcrumbs>
            <PageTitle title={"Communication Preferences"} marginBelow={0} />
          </Grid>
        </Grid>
        <ManageCommunicationPreferencesForm
          communicationPreferences={communicationPreferences}
          clientId={clientData.client_id}
        />
      </Box>
    );
  }
}
