import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { useAppSelector } from "../state/hooks";
import MainShell from "../layout/MainShell";
import ProtectedRoute, { ProtectedRouteProps } from "../login/ProtectedRoute";
import Login from "../login/Login";
import BillingParent from "../tabs/billing/BillingParent";
import LandingPage from "../tabs/home/LandingPage";
import NotesParent from "../tabs/notes/NotesParent";
import AppointmentsParent from "../tabs/appointments/AppointmentsParent";
import DocumentFormParent from "../tabs/documents/viewDocument/DocumentFormParent";
import {
  CUSTOM_FORM_STATUS_NEW_ID,
  CUSTOM_FORM_STATUS_DRAFT_ID,
  CUSTOM_FORM_STATUS_SUBMIT_ID,
  CUSTOM_FORM_STATUS_APPROVED_ID,
} from "../shared/resources/referenceConstants";
import ClientDocumentParent from "../tabs/documents/uploadDocuments/ClientDocumentParent";
import DocumentsParent from "../tabs/documents/DocumentsParent";
import ManageParent from "../tabs/manage/ManageParent";
import ManageCommunicationPreferencesParent from "../tabs/manage/ManageCommunicationPreferencesParent";
import { selectPermissions } from "../state/slices/access";
import { defaultNullUndefined } from "../shared/globalFunctions";

const InvoiceDetailsPage = lazy(
  () => import("../tabs/billing/InvoiceDetailsPage")
);

const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
  isAuthenticated: false,
  authenticationPath: "/login",
};

export default function AppRoutes() {
  let isLoggedIn = useAppSelector((store) => store.auth.isSignedIn);
  const permissions = useAppSelector(selectPermissions);
  return useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: (
        <ProtectedRoute
          isAuthenticated={isLoggedIn}
          authenticationPath={defaultProtectedRouteProps.authenticationPath}
          outlet={<MainShell />}
        />
      ),
      children: [
        {
          path: "",
          element: <LandingPage />,
        },
        {
          path: "appointments",
          element: <AppointmentsParent />,
        },
        {
          path: "notes",
          element: (
            <ProtectedRoute
              isAuthenticated={defaultNullUndefined(
                permissions?.can_view_clinical_notes,
                false
              )}
              authenticationPath={defaultProtectedRouteProps.authenticationPath}
              outlet={<NotesParent />}
            />
          ),
        },
        {
          path: "documents",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  isAuthenticated={defaultNullUndefined(
                    permissions?.can_manage_documents,
                    false
                  )}
                  authenticationPath={
                    defaultProtectedRouteProps.authenticationPath
                  }
                  outlet={
                    <DocumentsParent
                      showHeader={true}
                      formResponseStatusIds={[
                        CUSTOM_FORM_STATUS_NEW_ID,
                        CUSTOM_FORM_STATUS_DRAFT_ID,
                        CUSTOM_FORM_STATUS_SUBMIT_ID,
                        CUSTOM_FORM_STATUS_APPROVED_ID,
                      ]}
                    />
                  }
                />
              ),
            },
            {
              path: "form/:id",
              element: <DocumentFormParent />,
            },
            {
              path: "manage",
              element: <ClientDocumentParent />,
            },
          ],
        },
        {
          path: "billing",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute
                  isAuthenticated={defaultNullUndefined(
                    permissions?.can_view_billing,
                    false
                  )}
                  authenticationPath={
                    defaultProtectedRouteProps.authenticationPath
                  }
                  outlet={<BillingParent />}
                />
              ),
            },

            {
              path: "invoice/:invoiceId",
              element: (
                <ProtectedRoute
                  isAuthenticated={defaultNullUndefined(
                    permissions?.can_view_billing,
                    null
                  )}
                  authenticationPath={"/permissionError"}
                  outlet={<InvoiceDetailsPage />}
                />
              ),
            },
          ],
        },
        {
          path: "manage",
          children: [
            {
              path: "",
              element: <ManageParent />,
            },
            {
              path: "communicationPreferences/:clientId",
              element: <ManageCommunicationPreferencesParent />,
            },
          ],
        },
      ],
    },
  ]);
}
