import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { checkAuth, logout } from "../../apis/internalDb/auth/login";
import { defaultNullUndefined } from "../../shared/globalFunctions";

export interface AuthState {
  status: string | undefined;
  error: string | undefined;
  isSignedIn: boolean;
  sessionTimeout: number;
  clients: any | undefined;
  clientContacts: any | undefined;
  userEmail: string;
}

const initialState: AuthState = {
  status: "idle",
  error: "",
  isSignedIn: false,
  clients: [],
  clientContacts: [],
  userEmail: "",
  sessionTimeout: -1,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // add your non-async reducers here
    signIn: (state, action: PayloadAction<AuthState>) => {
      state.sessionTimeout = action.payload.sessionTimeout;
      state.isSignedIn = true;
      state.status = action.payload.status;
      state.clients = action.payload.clients;
      state.userEmail = action.payload.userEmail;
      state.clientContacts = action.payload.clientContacts;
    },
    signOut: (state) => {
      state.sessionTimeout = -1;
      state.isSignedIn = false;
      state.status = "idle";
      state.clients = [];
      state.clientContacts = [];
      state.userEmail = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCheckAuth.fulfilled, (state, action) => {
        state = action.payload;
      })
      .addCase(getCheckAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

/* --- API Requests --- */
export const getCheckAuth = createAsyncThunk("auth/check", async () => {
  const response = await checkAuth();
  const object: AuthState = {
    isSignedIn:
      defaultNullUndefined(response.data.results.isLoggedIn, "") !== "",
    sessionTimeout: defaultNullUndefined(
      response.data.results.sessionTimeout,
      ""
    ),
    clients: response.data.results.clients,
    clientContacts: response.data.results.clientContacts,
    userEmail: response.data.results.userEmail,
    status: "idle",
    error: undefined,
  };
  return object as AuthState;
});

export const getAuthLogout = createAsyncThunk("auth/logout", async () => {
  await logout();
  const object: AuthState = {
    isSignedIn: false,
    sessionTimeout: -1,
    clients: [],
    clientContacts: [],
    userEmail: "",
    status: "idle",
    error: undefined,
  };
  return object as AuthState;
});

// Action creators
export const { signIn, signOut } = authSlice.actions;
// export const selectClientId = (state: RootState) => state.auth.clientId;
export const selectClientData = (state: RootState) => state.auth.clients;
export const selectCurrentClient = (state: RootState) =>
  state.access.selectedClient;
export const selectContactData = (state: RootState) =>
  state.auth.clientContacts;
export const selectUserEmail = (state: RootState) => state.auth.userEmail;
export default authSlice.reducer;
