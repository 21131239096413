import * as React from "react";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { defaultNullUndefined } from "../globalFunctions";

const StyledBreadcrumb = styled(Chip)(({ theme, clickable }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor: clickable
      ? backgroundColor
      : emphasize(backgroundColor, 0.12),
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    cursor: clickable ? "pointer" : "default",
    "&:hover, &:focus": clickable
      ? {
          backgroundColor: emphasize(backgroundColor, 0.06),
        }
      : {},
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

interface BreadcrumbChipInterface {
  label: string;
  icon?: JSX.Element;
  clickable?: boolean;
}

export default function BreadcrumbChip({
  icon,
  label,
  clickable,
}: BreadcrumbChipInterface) {
  const isClickable = defaultNullUndefined(clickable, true);
  return <StyledBreadcrumb label={label} icon={icon} clickable={isClickable} />;
}
