import internalConnection from "../../apis/internalDb/internalConnection";

export interface CustomNoteFormFieldResponseObject {
  form_field_type: string | null;
  field_label: string;
  is_required: boolean;
  max_char: number | null;
  field_response: string | null;
  custom_notes_form_field_response_id: number;
}

export interface CustomNoteFormResponseObject {
  is_draft: boolean;
  template_name: string | null;
  custom_notes_form_field_response: CustomNoteFormFieldResponseObject[];
}

export interface CustomNoteFormResponseObject {
  is_draft: boolean;
  template_name: string | null;
  custom_notes_form_field_response: CustomNoteFormFieldResponseObject[];
}

export interface ClinicalNoteDetailType {
  clinical_note_id: number;
  custom_notes_form_response_id: number | null;
  client_id: number;
  user_id: number;
  event_client_id: number;
  signed_on: Date;
  is_locked: boolean;
  custom_notes_form_response: CustomNoteFormResponseObject;
}

export const getClinicalNoteByIds = async (
  clientId: number,
  clinicalNoteIds: number[],
  selectedClientId: number
) => {
  console.log({ clientId, clinicalNoteIds, selectedClientId });
  const response = await internalConnection.get(
    `clinicalNote/${selectedClientId}/getByClinicalNoteIds`,
    {
      params: {
        clientId: clientId,
        clinicalNoteIds: clinicalNoteIds,
      },
    }
  );
  return response.data.results as ClinicalNoteDetailType[];
};

export const clinicalNoteKeys = {
  all: ["clinicalNote"] as const,
  lists: () => [...clinicalNoteKeys.all, "list"] as const,
  list: (filters: string, clientId: number) =>
    [...clinicalNoteKeys.lists(), clientId, filters] as const,
  details: (clientId: number, clinicalNoteIds: number[]) =>
    [...clinicalNoteKeys.all, "detail", clientId, clinicalNoteIds] as const,
  detail: (id: number) => [...clinicalNoteKeys.all, "detail", id] as const,
};
