import internalConnection from "../internalConnection";
import { ClientContactInfo } from "../contacts/contactsRoutes";

export interface CommunicationPreferencesPut {
  contactViaEmail: boolean;
  contactViaPhone: boolean;
  contactViaText: boolean;
  clientId: number;
  userId: string;
}

export const updateCommunicationPreferences = async (
  communicationPreferences: CommunicationPreferencesPut
) => {
  return await internalConnection.put(
    "/clientSummary/putCommunicationPreferences",
    communicationPreferences
  );
};

export interface ClientPersonalInfoDetails {
  client_id: number;
  display_client_id: string;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  preferred_name: string | null;
  status_id: number;
  funding_sources: string | null;
  dob: Date | null;
  phone_number: string;
  email: string;
  legal_gender_id: number | null;
  gender_identity_id: number | null;
  pronouns: string | null;
  household_income: number | null;
  street1: string | null;
  street2: string | null;
  city: string | null;
  state_id: number | null;
  zip_code: string | null;
  pending_init_consult: boolean | null;
  pending_intake: boolean | null;
  pending_refer: boolean | null;
  pending_client_resp: boolean | null;
  can_access_portal: boolean;
  can_manage_documents: boolean;
  can_view_clinical_notes: boolean;
  can_view_billing: boolean;
  contact_via_email: boolean;
  contact_via_phone: boolean;
  contact_via_text: boolean;
  client_race: number | null;
  client_contact: ClientContactInfo[];
  is_active: boolean;
  is_employed: boolean;
  employer: string | null;
}

export const getClientPersonalInfo = async (id: number) => {
  const response = await internalConnection.get(
    "/clientSummary/getPersonalInfo",
    {
      params: { clientId: id },
    }
  );
  return response.data.results as ClientPersonalInfoDetails;
};
