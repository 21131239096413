import React from "react";
import { Box } from "@mui/material";
import ClientPortalFormDataTable from "./ClientPortalFormDataTable";

interface DocumentsListProps {
  clientId: number;
  formResponseStatusIds: number[];
  onViewDocument: Function;
}
export default function DocumentsList({
  clientId,
  formResponseStatusIds,
  onViewDocument,
}: DocumentsListProps) {
  const handleViewDocument = (id: number) => {
    onViewDocument(id);
  };
  return (
    <Box>
      <ClientPortalFormDataTable
        clientId={clientId}
        formResponseStatusIds={formResponseStatusIds}
        onViewDocument={handleViewDocument}
      />
    </Box>
  );
}
