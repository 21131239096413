import * as yup from "yup";

export function preloginValidation() {
  return yup.object({
    email: yup
      .string()
      .email()
      .required("No email provided.")
  });
}

export function loginValidation() {
  return yup.object({
    email: yup
      .string()
      .email()
      .required("No email provided."),
    accessCode: yup
    .number()
    .required("No code provided."),
  });
}