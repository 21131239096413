import * as React from "react";
/* MUI Imports */
import { Box } from "@mui/material";
import { defaultNullUndefined } from "../globalFunctions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  padding?: number;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, padding, ...other } = props;
  const defaultPadding = 3;
  const displayPadding = defaultNullUndefined(padding, defaultPadding);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: displayPadding }}>{children}</Box>}
    </div>
  );
}
