/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { IconButton, Box, LinearProgress, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { useQuery } from "react-query";
import {
  ClientDocumentListType,
  getDocumentsByClientId,
} from "../../../apis/internalDb/documentRoutes";
import { clientDocumentKeys } from "../../../apis/internalDb/keyFactory";
import { defaultNullUndefined } from "../../../shared/globalFunctions";
import { buildAwsDownloadLink } from "../../../apis/internalDb/awsFiles/files";

interface ClientDocumentDataTableProps {
  clientId: number;
  isArchived: boolean;
}

export default function ClientDocumentDataTable({
  clientId,
  isArchived,
}: ClientDocumentDataTableProps) {
  const [startIndex, setStartIndex] = React.useState(0);
  const [batchSize, setBatchSize] = React.useState(10);
  const { isLoading, data: contactData } = useQuery<
    ClientDocumentListType[],
    Error
  >(clientDocumentKeys.list(clientId, startIndex, batchSize), () =>
    getDocumentsByClientId(clientId)
  );

  const defaultContactData: ClientDocumentListType[] = [];
  const rows = defaultNullUndefined(contactData, defaultContactData);
  const columns: GridColDef[] = [
    {
      field: "display_name",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Comments",
      minWidth: 300,
      flex: 1,
    },
    {
      field: " ",
      renderCell: (params) => (
        <>
          {!isArchived && (
            <>
              <Tooltip title="Download Document">
                <a
                  href={buildAwsDownloadLink(
                    params.row.document_id.toString(),
                    clientId
                  )}
                >
                  <IconButton color="primary">
                    <DownloadIcon color="primary" />
                  </IconButton>
                </a>
              </Tooltip>
            </>
          )}
        </>
      ),
      sortable: false,
      width: 150,
    },
  ];

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Box sx={{ maxHeight: 700, width: "100%", pt: 2, overflow: "auto" }}>
          <DataGrid
            getRowHeight={() => "auto"}
            autoHeight
            isRowSelectable={() => false}
            sx={{ fontSize: "medium" }}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.document_id}
          />
        </Box>
      )}
    </>
  );
}
