/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import MessagingParent from "../tabs/messaging/MessagingParent";
import { useAppSelector, useAppDispatch } from "../state/hooks";
import {
  selectClientList,
  selectPermissions,
  selectSelectedClient,
  setSelectedClient,
} from "../state/slices/access";
import { getAuthLogout, signOut } from "../state/slices/auth";

export default function ButtonAppBar() {
  const permissions = useAppSelector(selectPermissions);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const clientList = useAppSelector(selectClientList);
  const selectedClient = useAppSelector(selectSelectedClient);

  const [isClientMenuOpen, setIsClientMenuOpen] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClientMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsClientMenuOpen(true);
  };
  const handleClientMenuClose = (index: number | null) => {
    setAnchorEl(null);
    setIsClientMenuOpen(false);
    if (index !== null) {
      dispatch(setSelectedClient(clientList[index]));
      navigate("/");
    }
  };

  const [isMessagingOpen, setIsMessagingOpen] = React.useState(false);
  const [messagingAnchorEl, setMessagingAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleMessagingClick = (event: React.MouseEvent<HTMLElement>) => {
    setMessagingAnchorEl(event.currentTarget);
    setIsMessagingOpen(true);
  };
  const handleMessagingClose = () => {
    setMessagingAnchorEl(null);
    setIsMessagingOpen(false);
  };

  const handleLogout = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    handleClientMenuClose(null);

    dispatch(getAuthLogout()).then((e: any) => {
      queryClient.invalidateQueries();
      dispatch(signOut());
      navigate("/");
    });
  };

  let orgIds: number[] = [];
  clientList.map((cl) => {
    if (!orgIds.includes(cl.orgId)) {
      orgIds.push(cl.orgId);
    }
    return true;
  });
  const displayOrgName = orgIds.length > 1;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Stack
            sx={{ flexGrow: 1, alignItems: "center" }}
            direction="row"
            spacing={2}
          >
            <Typography
              variant="h5"
              component={Link}
              to=""
              sx={{ color: "#fff", marginRight: 2, textDecoration: "none" }}
            >
              Gather &amp; Grow
            </Typography>
            <Button component={Link} to="appointments" color="inherit">
              Appointments
            </Button>
            {permissions?.can_view_clinical_notes && (
              <Button component={Link} to="notes" color="inherit">
                Notes
              </Button>
            )}
            {permissions?.can_manage_documents && (
              <Button component={Link} to="documents" color="inherit">
                Documents
              </Button>
            )}

            {permissions?.can_view_billing && (
              <Button component={Link} to="billing" color="inherit">
                Billing
              </Button>
            )}
            <Button component={Link} to="manage" color="inherit">
              Manage My Account
            </Button>
          </Stack>

          <Stack sx={{ flexGrow: 0 }} direction="row" spacing={2}>
            {/* <Tooltip title="Secure Messaging">
              <div>
                <IconButton onClick={handleMessagingClick} color="inherit">
                  <ChatBubbleIcon />
                </IconButton>
              </div>
            </Tooltip> */}
            {selectedClient !== null ? (
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                color="inherit"
                variant="outlined"
                onClick={handleClientMenuClick}
              >{`${displayOrgName ? selectedClient.orgName + " - " : ""}${
                selectedClient.firstName
              } ${selectedClient.lastName}`}</Button>
            ) : (
              <Button
                startIcon={<Logout />}
                color="inherit"
                variant="outlined"
                sx={{ marginLeft: 3 }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isClientMenuOpen}
        onClose={() => {
          handleClientMenuClose(null);
        }}
        onClick={() => {
          handleClientMenuClose(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {clientList?.map((cl, index) => (
          <MenuItem
            onClick={() => {
              handleClientMenuClose(index);
            }}
            disabled={cl.clientId === selectedClient?.clientId}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            {displayOrgName ? cl.orgName + " - " : ""}
            {cl.firstName + " " + cl.lastName}
          </MenuItem>
        ))}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <MessagingParent
        anchorEl={messagingAnchorEl}
        isOpen={isMessagingOpen}
        onClose={handleMessagingClose}
      />
    </Box>
  );
}
