/* eslint-disable array-callback-return */
import internalConnection from "../../internalConnection";
import { defaultNullUndefined } from "../../../../shared/globalFunctions";
import {
  RefDataInterface,
  RefDataObjectInterface,
} from "../../../../shared/resources/referenceConstants";

function transformRefType(refData?: RefDataInterface[], refType?: string) {
  let transformedRefType: RefDataInterface[] = [];
  if (refData == null) {
  } else {
    refData.map((item) => {
      if (item.ref_type === refType) {
        transformedRefType.push(item);
      }
      return;
    });
  }

  return transformedRefType as RefDataInterface[];
}

export const getByRefType = async (refTypes: string[]) => {
  const searchRefTypes: string = defaultNullUndefined(refTypes, [" "]);
  const response = await internalConnection.get("reference/searchRefType", {
    params: {
      refTypes: searchRefTypes,
    },
  });
  const object: RefDataObjectInterface = {};
  refTypes.map((refType) => {
    object[refType] = transformRefType(response.data.results, refType);
    return;
  });
  return object as RefDataObjectInterface;
};

export const getByLocationRefType = async () => {
  const response = await internalConnection.get(
    "reference/searchLocationRefType"
  );

  return response.data.results as RefDataInterface[];
};

export const refDataKeys = {
  all: ["refData"] as const,
  lists: () => [...refDataKeys.all, "lists"] as const,
  list: (refTypes: string[]) => [...refDataKeys.lists(), { refTypes }] as const,
};

export const locationRefDataKeys = {
  all: ["refData"] as const,
  lists: () => [...refDataKeys.all, "lists"] as const,
  list: (refTypes: string[]) => [...refDataKeys.lists(), { refTypes }] as const,
};
