import React from "react";
import { useQuery } from "react-query";

/* Project Imports */

import { Box, LinearProgress } from "@mui/material";
import EventDisplayNoteResponse, {
  ClinicalNoteDetailType,
} from "./EventDisplayNoteResponse";
import { clinicalNoteKeys, getClinicalNoteByIds } from "./clinicalNote";
import DataRetrievalError from "../../shared/ReusableComponents/DataRetrievalError";

interface EventDisplayExistingNotesProps {
  clientId: number;
  existingNoteIds: number[];
  selectedClientId: number;
}

export default function EventDisplayExistingNotes({
  clientId,
  existingNoteIds,
  selectedClientId,
}: EventDisplayExistingNotesProps) {
  const {
    isLoading,
    isError,
    data: clinicalNotes,
  } = useQuery<ClinicalNoteDetailType[], Error>(
    clinicalNoteKeys.details(clientId, existingNoteIds),
    () => getClinicalNoteByIds(clientId, existingNoteIds, selectedClientId)
  );

  if (isLoading) {
    return <LinearProgress />;
  } else if (isError || clinicalNotes === undefined) {
    return <DataRetrievalError />;
  }
  return (
    <Box
      sx={{
        background: "#ececec",
        border: "1px solid black",
        borderRadius: 5,
        padding: 2,
      }}
    >
      {clinicalNotes.map((cn) => (
        <EventDisplayNoteResponse
          key={`clinicalNote${cn.clinical_note_id}`}
          clinicalNote={cn}
        />
      ))}
    </Box>
  );
}
