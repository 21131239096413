import React from "react";
import {
  SERVICE_RATE_TIMED_CODE_ID,
  TIMED_CODE_HOUR_ID,
  TIMED_CODE_MINUTES_ID,
  SERVICE_RATE_ITEM_ID,
} from "./resources/referenceConstants";
import {
  PAYMENT_SUBMITTED_SCUCCESSFULLY_MESSAGE,
  PAYMENT_SUBMITTED_STATE,
} from "./globalConstants";
export const defaultNullUndefined = (value: any, defaultValue: any) => {
  return value == null ? defaultValue : value;
};

export const toStringSafe = (value: any) => {
  if (value == null) {
    return "";
  } else if (typeof value === "string") {
    return value;
  } else {
    return value.toString();
  }
};

export const parseIntSafe = (
  value: any,
  defaultValue: number | null | undefined
) => {
  if (value == null) {
    return defaultValue;
  } else if (typeof value === "string") {
    return parseInt(value);
  } else {
    return value;
  }
};

export const parseFloatSafe = (
  value: any,
  defaultValue: number | null | undefined
) => {
  if (value == null) {
    return defaultValue;
  } else if (typeof value === "string") {
    return parseFloat(value);
  } else {
    return value;
  }
};

export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function displayServiceUnitLabel(
  rate_type_id: number | null,
  timed_code_type_id: number | null,
  isMins?: boolean
) {
  if (rate_type_id === SERVICE_RATE_TIMED_CODE_ID) {
    return timed_code_type_id === TIMED_CODE_HOUR_ID
      ? isMins
        ? "Min(s)"
        : "Hour(s)"
      : timed_code_type_id === TIMED_CODE_MINUTES_ID
      ? "Minute(s)"
      : "Unit(s)";
  } else if (rate_type_id === SERVICE_RATE_ITEM_ID) {
    return "Item(s)";
  } else {
    return "Unit(s)";
  }
}

export function timeToUnitsCalculation(time: number) {
  if (time < 8) return 0;
  const timeGap = 7;
  const timeBlock = 15;
  const unitCalc = (timeGap + time) / timeBlock;
  return Math.floor(unitCalc);
}

export function unitsToMinTimeCalculation(units: number) {
  if (units < 1) return 0;
  const firstUnit = 8;
  const timeBlock = 15;
  const timeCalc = firstUnit + (units - 1) * timeBlock;
  return timeCalc;
}

export function parDetailsToUsedMinutes(
  units: number,
  rate_type_id: number | null,
  timed_code_type_id: number | null
) {
  if (rate_type_id === SERVICE_RATE_TIMED_CODE_ID) {
    return timed_code_type_id === TIMED_CODE_HOUR_ID
      ? 60 * units
      : timed_code_type_id === TIMED_CODE_MINUTES_ID
      ? units
      : unitsToMinTimeCalculation(units);
  } else if (rate_type_id === SERVICE_RATE_ITEM_ID) {
    return 0;
  } else {
    return 0;
  }
}
export function getDisplayId(number: number) {
  return number.toString().padStart(8, "0");
}

export function getFormattedDate(date: Date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const STRIPE_PUBLIC_KEY: string =
  "pk_test_51NYzXdDoDumwkgmONBMsCOeGTElu2kO8fwNhEAIq9AjezCdhmR1crvPguEWvqqkpHFa2fmKYoCfHJwXR6bpOn8cB00EvKaVp3P";

export function getSuccessMessage(successState: string | null) {
  switch (successState) {
    case PAYMENT_SUBMITTED_STATE:
      return PAYMENT_SUBMITTED_SCUCCESSFULLY_MESSAGE;

    default:
      return "";
  }
}
