import { Box, LinearProgress } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import AppointmentDataTable from "./AppointmentDataTable";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import DataRetrievalError from "../../shared/ReusableComponents/DataRetrievalError";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DateFilterProps, PagingInfoProps } from "../../shared/globalConstants";
import {
  EventHistoryDataset,
  eventKey,
  getEventHistory,
} from "../../apis/internalDb/calendar/event";

interface AppointmentTableParentProps {
  clientId: number;
  isPast: boolean;
}

export default function AppointmentTableParent({
  clientId,
  isPast,
}: AppointmentTableParentProps) {
  const startDate = isPast ? null : dayjs();
  const endDate = isPast ? dayjs() : null;
  const defaultDateFilter: DateFilterProps = {
    startDate: startDate,
    endDate: endDate,
  };
  const PAGE_SIZE = [15, 50, 100];
  const dateFilterModel = React.useRef<DateFilterProps>(defaultDateFilter);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE[0],
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "start_datetime",
      sort: "desc",
    },
  ]);

  /* --- React Query --- */
  const pagingInfo: PagingInfoProps = {
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    sortField:
      sortModel.length === 0
        ? undefined
        : defaultNullUndefined(sortModel[0].field, undefined),
    sortDirection:
      sortModel.length === 0
        ? undefined
        : defaultNullUndefined(sortModel[0].sort, undefined),
  };
  const {
    isLoading: areAppointmentsLoading,
    data: appointmentsData,
    isError: isDataRetreiveError,
  } = useQuery<EventHistoryDataset, Error>(
    eventKey.eventHistoryTable(
      clientId,
      dateFilterModel.current,
      undefined,
      undefined,
      pagingInfo
    ),
    () => getEventHistory(clientId, dateFilterModel.current, pagingInfo)
  );

  /* --- Handlers ---*/

  if (areAppointmentsLoading) {
    return <LinearProgress />;
  } else if (
    appointmentsData === null ||
    appointmentsData === undefined ||
    isDataRetreiveError
  ) {
    return <DataRetrievalError />;
  } else {
    return (
      <Box>
        <AppointmentDataTable
          clientId={clientId}
          onSortChange={(model: GridSortModel) => {
            setSortModel(model);
            let tempPageSize = paginationModel.pageSize;
            setPaginationModel({
              page: 0,
              pageSize: tempPageSize,
            });
          }}
          onPageChange={(model: GridPaginationModel) => {
            setPaginationModel(model);
          }}
          appointmentsData={appointmentsData}
          sortModel={sortModel}
          paginationModel={paginationModel}
          pageSizes={PAGE_SIZE}
        />
      </Box>
    );
  }
}
