import React from "react";

/* --- MUI Imports --- */
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { defaultNullUndefined } from "../../shared/globalFunctions";
import { EventStaffDetailsType } from "../../apis/internalDb/calendar/event";
/* --- Project Imports --- */

interface EventSummaryStaffListProps {
  staff: EventStaffDetailsType[];
  billingProviderId?: number | null;
}
export default function EventSummaryStaffList({
  staff,
  billingProviderId,
}: EventSummaryStaffListProps) {
  const eventBillingProviderId = defaultNullUndefined(billingProviderId, -1);

  return (
    <>
      <Typography variant="body1">
        <strong>Staff Members:</strong>
      </Typography>
      <List disablePadding>
        {staff.map((user: EventStaffDetailsType, index: number) => {
          let fullname =
            user.app_user.user_details[0].first_name +
            " " +
            user.app_user.user_details[0].last_name;
          return (
            <div key={`user${user.user_id}`}>
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar
                    alt={fullname}
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: 25, height: 25 }}
                  />
                </ListItemAvatar>
                {eventBillingProviderId === user.user_id && (
                  <Tooltip title="Billing Provider">
                    <PersonIcon color="secondary" />
                  </Tooltip>
                )}
                <ListItemText primary={` ${fullname}`} />
              </ListItem>
              {staff.length !== index + 1 && (
                <Divider variant="middle" component="li" />
              )}
            </div>
          );
        })}
      </List>
    </>
  );
}
