import * as React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

/* --- Project Imports --- */
import { useAppDispatch } from "../state/hooks";
import { getCheckAuth, signIn } from "../state/slices/auth";
import {
  PreLoginDetailsObject,
  preLoginDetails,
  loginDetailsKeys,
  LoginDetailsObject,
  loginDetails,
} from "../apis/internalDb/auth/login";
import { AuthState } from "../state/slices/auth";
import {
  loginValidation,
  preloginValidation,
} from "../shared/resources/validationSchema/login/loginValidation";

/* --- MUI Imports --- */
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AxiosError } from "axios";
import { defaultNullUndefined } from "../shared/globalFunctions";

export default function LoginForm() {
  /* Setup state */
  const [loginErrors, setLoginErrors] = React.useState<string[]>([]);
  const [errorElems, setErrorElems] = React.useState<React.JSX.Element[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  /* Setup Variables and handlers */
  const queryClient = useQueryClient();
  const preLoginDetailsMutation = useMutation(preLoginDetails, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: loginDetailsKeys });
      handlePreLoginSuccess(response.data.results);
      setLoginErrors([]);
      setIsLoading(false);
      formikLogin.setFieldValue("email", formikPreLogin.values.email);
    },
    onError: (error: AxiosError) => {
      console.log(error);
      const errorResult = error.response
        ? (error.response.data as { results: string })
        : { results: "" };
      const results: string = errorResult.results as string;
      setLoginErrors([results]);
      setIsLoading(false);
    },
  });
  const loginDetailsMutation = useMutation(loginDetails, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: loginDetailsKeys });
      handleLoginSuccess(response.data.results);
      setLoginErrors([]);
      setIsLoading(false);
    },
    onError: (error: AxiosError) => {
      console.log(error);
      const errorResult = error.response
        ? (error.response.data as { results: string })
        : { results: "" };
      const results: string = errorResult.results as string;
      setLoginErrors([results]);
      setIsLoading(false);
    },
  });
  const navigate = useNavigate();
  /* --- Redux Store --- */
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getCheckAuth()).then((e: any) => {
      if (e.payload?.isSignedIn) {
        dispatch(signIn(e.payload));
        navigate("/");
      }
    });
  }, [dispatch, navigate]);

  /* --- Formik Initialization --- */
  let preLoginInitialValues: PreLoginDetailsObject = {
    email: "",
  };
  const formikPreLogin = useFormik({
    initialValues: preLoginInitialValues,
    validationSchema: preloginValidation(),
    onSubmit: async (values: PreLoginDetailsObject) => {
      setIsLoading(true);
      const preloginDetailPayload: PreLoginDetailsObject = {
        email: values.email,
      };
      preLoginDetailsMutation.mutate(preloginDetailPayload);
    },
  });
  let loginInitialValues: LoginDetailsObject = {
    email: "",
    accessCode: "",
  };
  const formikLogin = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidation(),
    onSubmit: async (values: LoginDetailsObject) => {
      setIsLoading(true);
      const loginDetailPayload: LoginDetailsObject = {
        email: values.email,
        accessCode: values.accessCode,
      };
      loginDetailsMutation.mutate(loginDetailPayload);
    },
  });

  /* --- Handlers --- */
  const handlePreLoginSuccess = (responseObj: any) => {
    console.log(responseObj);
    setCurrentStep(2);
  };
  const handleLoginSuccess = (responseObj: any) => {
    formikPreLogin.resetForm();
    const authState: AuthState = {
      error: undefined,
      status: "idle",
      sessionTimeout: parseInt(responseObj.sessionTimeout),
      isSignedIn: true,
      clients: responseObj.clients,
      clientContacts: responseObj.clientContacts,
      userEmail: defaultNullUndefined(responseObj.email, ""),
    };
    dispatch(signIn(authState));
    navigate("/");
  };

  React.useEffect(() => {
    setErrorElems(
      loginErrors.map((value, index) => {
        return (
          <Box
            sx={{ color: "red", paddingTop: index === 0 ? "1em" : "initial" }}
          >
            {value}
          </Box>
        );
      })
    );
  }, [loginErrors]);

  let formElement: React.JSX.Element = <></>;
  switch (currentStep) {
    case 1:
      formElement = (
        <>
          <form onSubmit={formikPreLogin.handleSubmit}>
            <Grid container direction="row">
              <Grid container direction="column" item xs={12} sm={6}>
                <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ padding: "0.5rem", paddingLeft: "2rem" }}
                    variant="h2"
                  >
                    Login
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Box>
              <Grid
                container
                direction="row"
                spacing={2}
                columns={{ xs: 3, sm: 6, md: 9 }}
                style={{ marginTop: "16px" }}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  variant="filled"
                  value={formikPreLogin.values.email}
                  onChange={formikPreLogin.handleChange}
                  error={
                    formikPreLogin.touched.email &&
                    Boolean(formikPreLogin.errors.email)
                  }
                  helperText={
                    formikPreLogin.touched.email && formikPreLogin.errors.email
                  }
                />
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                columns={{ xs: 3, sm: 6, md: 9 }}
                style={{ marginTop: "16px" }}
              ></Grid>
              <Stack direction="row" spacing={2} style={{ marginTop: "16px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                >
                  Send Login Code
                </Button>
              </Stack>
              {errorElems}
            </Box>
          </form>
        </>
      );
      break;
    case 2:
      formElement = (
        <>
          <form onSubmit={formikLogin.handleSubmit}>
            <Grid container direction="row">
              <Grid container direction="column" item xs={12} sm={6}>
                <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ padding: "0.5rem", paddingLeft: "2rem" }}
                    variant="h2"
                  >
                    Login
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Box>
              <Grid
                container
                direction="row"
                spacing={2}
                columns={{ xs: 3, sm: 6, md: 9 }}
                style={{ marginTop: "16px" }}
              >
                <Box sx={{ fontSize: "1.5em" }}>
                  Enter the code sent to <b>{formikLogin.values.email}</b> below
                </Box>
                <TextField
                  fullWidth
                  id="accessCode"
                  name="accessCode"
                  label="Access Code"
                  variant="filled"
                  value={formikLogin.values.accessCode}
                  onChange={formikLogin.handleChange}
                  error={
                    formikLogin.touched.accessCode &&
                    Boolean(formikLogin.errors.accessCode)
                  }
                  helperText={
                    formikLogin.touched.accessCode &&
                    formikLogin.errors.accessCode
                  }
                />
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                columns={{ xs: 3, sm: 6, md: 9 }}
                style={{ marginTop: "16px" }}
              ></Grid>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
                spacing={2}
                style={{ marginTop: "16px" }}
              >
                <Button
                  variant="text"
                  color="secondary"
                  disabled={isLoading}
                  type="button"
                  onClick={(e) => {
                    setCurrentStep(1);
                    setErrorElems([]);
                    setIsLoading(false);
                    formikLogin.resetForm();
                    formikPreLogin.resetForm();
                  }}
                >
                  <ArrowBackIcon />
                  Back to Email
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                >
                  Login
                </Button>
              </Stack>
              {errorElems}
            </Box>
          </form>
        </>
      );
      break;
  }

  return (
    <>
      <LinearProgress sx={{ visibility: isLoading ? "initial" : "hidden" }} />
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Box
          component="main"
          sx={{ p: { xs: 2, sm: 3 }, width: "50%", minWidth: "400px" }}
        >
          <Toolbar />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {formElement}
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
    </>
  );
}
