import { Dialog } from "@mui/material";
import React from "react";
import ClientDocumentForm from "./ClientDocumentForm";

interface ClientDocumentAddProps {
  clientId: number;
  isOpen: boolean;
  onClose: Function;
}

export default function ClientDocumentAdd({
  clientId,
  isOpen,
  onClose,
}: ClientDocumentAddProps) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxHeight: "90%",
          p: 0,
        },
      }}
      maxWidth="sm"
    >
      <ClientDocumentForm clientId={clientId} onClose={handleClose} />
    </Dialog>
  );
}
