import React from "react";
import { Box, Typography } from "@mui/material";

interface EmptyDataTableMessageProps {
  message: string;
}
export default function EmptyDataTableMessage({
  message,
}: EmptyDataTableMessageProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={100}
      maxHeight={200}
    >
      <Typography variant="h6" fontSize={16}>
        {message}
      </Typography>
    </Box>
  );
}
